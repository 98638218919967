import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  Button,
} from "reactstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import "moment-timezone";
import AnnotatedText from "./../AnnotatedText";

const ResultsSuccessfulPercentage = ({
  resolutionSuccessResults = [],
  handleSetHaveResults,
  downloadResolutionSuccess,
  formData,
}) => {
  const totalNumber = resolutionSuccessResults.total_appointments;
  const successNumber = resolutionSuccessResults.total_success;
  const failureNumber = resolutionSuccessResults.total_failure;

  return (
    <Card>
      <CardHeader tag="h4">
        Results
        <span>
          <Button close onClick={() => handleSetHaveResults(false)} />
        </span>
      </CardHeader>
      <CardBody>
        <AnnotatedText annotation="Start Date">
          {moment(formData.start_date).format("M/D/YYYY")}
        </AnnotatedText>
        <AnnotatedText annotation="End Date">
          {moment(formData.end_date).format("M/D/YYYY")}
        </AnnotatedText>
        <AnnotatedText annotation="Technician">
          {formData.technician_name}
        </AnnotatedText>
        <AnnotatedText annotation="Total Appointments">
          {totalNumber}
        </AnnotatedText>
        <AnnotatedText annotation="Total Successes">
          {Math.round((successNumber / totalNumber) * 100) || 0}%
        </AnnotatedText>
        <AnnotatedText annotation="Total Failed">
          {Math.round((failureNumber / totalNumber) * 100) || 0}%
        </AnnotatedText>
        <Button
          onClick={() => {
            const params = {
              start_date: moment(formData.start_date)
                .startOf("day")
                .format("D/M/YYYY HH:mm"),
              end_date: moment(formData.end_date)
                .endOf("day")
                .format("D/M/YYYY HH:mm"),
              technician_id:
                formData.technician_id === "" ? null : formData.technician_id,
            };

            downloadResolutionSuccess({ params });
          }}
        >
          Download
        </Button>
      </CardBody>
    </Card>
  );
};

const ResolutionSuccessfulPercentage = ({
  techList = [],
  handleGetResolutionSuccess,
  resolutionSuccessResults,
  haveResults = false,
  handleSetHaveResults,
  downloadResolutionSuccess,
}) => {
  const [formData, setFormData] = useState({
    start_date: "",
    end_date: "",
    //technician id can be null, if null then grabs all techs
    technician_id: "",
    technician_name: "All",
  });

  const newTechList = [{ value: "", label: "All" }, ...techList];

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader tag="h4">Resolutions</CardHeader>
          <CardBody>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <InputGroup>
                      <Label>Start Date</Label>
                      <DatePicker
                        className="form-control"
                        selected={formData.start_date}
                        value={formData.start_date}
                        onChange={(e) => {
                          setFormData((prevState) => ({
                            ...prevState,
                            start_date: e,
                          }));
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <InputGroup>
                      <Label>End Date</Label>
                      <DatePicker
                        className="form-control"
                        selected={formData.end_date}
                        value={formData.end_date}
                        onChange={(e) => {
                          setFormData((prevState) => ({
                            ...prevState,
                            end_date: e,
                          }));
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Technician</Label>
                    <Select
                      options={newTechList}
                      value={newTechList.find(
                        (tech) => tech.id === formData.technician_id
                      )}
                      name="technician_id"
                      onChange={(e) =>
                        setFormData((prevState) => ({
                          ...prevState,
                          technician_id: e.value,
                          technician_name: e.label,
                        }))
                      }
                      defaultValue={newTechList[newTechList.length - 1]}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button
                onClick={() => {
                  const params = {
                    start_date: moment(formData.start_date)
                      .startOf("day")
                      .format("D/M/YYYY HH:mm"),
                    end_date: moment(formData.end_date)
                      .endOf("day")
                      .format("D/M/YYYY HH:mm"),
                    technician_id:
                      formData.technician_id === ""
                        ? null
                        : formData.technician_id,
                  };

                  handleGetResolutionSuccess({ params });
                }}
              >
                Submit
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Col>
      {haveResults ? (
        <Col>
          <ResultsSuccessfulPercentage
            resolutionSuccessResults={resolutionSuccessResults}
            handleSetHaveResults={handleSetHaveResults}
            handleGetResolutionSuccess={handleGetResolutionSuccess}
            downloadResolutionSuccess={downloadResolutionSuccess}
            formData={formData}
          />
        </Col>
      ) : null}
    </Row>
  );
};

export default ResolutionSuccessfulPercentage;
