// Auth
export const AUTH_SET_TOKEN = "AUTH_SET_TOKEN";
export const AUTH_DEL_TOKEN = "AUTH_DEL_TOKEN";

// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_RIGHT_ENABLE = "SIDEBAR_RIGHT_ENABLE";
export const SIDEBAR_RIGHT_DISABLE = "SIDEBAR_RIGHT_DISABLE";
