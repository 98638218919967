import React, { useEffect, useState } from 'react';
import { Link, withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import 'moment-timezone';
import { toastr } from 'react-redux-toastr';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import debounce from '../../components/UseDebounce';

import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  FormGroup,
} from 'reactstrap';

import 'react-datepicker/dist/react-datepicker.css';

import Header from '../../components/Header';
import HeaderTitle from '../../components/HeaderTitle';
import API from '../../api/index';

import CustomerInfoForm from '../../components/Appointments/CustomerInfoForm';
import ProblemInfoForm from '../../components/Appointments/ProblemInfoForm';
import SolutionInfoForm from '../../components/Appointments/DateTimeInfoForm';
import CalendarComponent from '../../components/Calendar/index';
import CommentContainer from '../../components/Appointments/CommentContainer';
import SameBuildingAppointmentsTable from '../../components/Appointments/SameBuildingAppointmentsTable';
import BuildingNotesBanner from '../../components/BuildingNotesBanner';

//the parent container that holds api calls and holds all other components

const AppoitnmentCreateEditParentContainer = ({ ...props }) => {
  let { id } = useParams();
  const [isReadOnly] = useState(props.isReadOnly || false);
  const [techList, setTechList] = useState([]);

  const [injectionDate, setInjectionDate] = useState([]);
  const [appointmentInfo, setAppointmentInfo] = useState({
    ticket_number: '',
    first_name: '',
    last_name: '',
    email: '',
    mobile_sms: '',
    home_number: '',
    account_number: '',
    building: '',
    apt: '',
    purchase_speed: '',
    want_sms: true,
    user_id: '',
    comments: [],
    activation_key: '',
    accepted_repeater_deposit: false,
    accepted_repeater_deposit_type: '',
    accepted_repeater_deposit_price: 0,
    waived_repeater_deposit: false,
    waived_repeater_deposit_reason: '',
  });

  const [reservation, setReservation] = useState({
    solution_date: '',
    start_time: '',
    end_time: '',
    technician_id: '',
    force_confirm_do_not_notify_customer: false,
  });

  const [problemInfo, setProblemInfo] = useState({
    tech_level_needed: '',
    problem_id: '',
    problem_tech_array: [1, 2, 3],
    problem_name: '',
    problem_description: '',
  });

  const [repeaterTypes, setRepeaterTypes] = useState([]);

  const getRepeaterTypes = () => {
    API.get('/api/repeater_types')
      .then((res) => {
        const mapped = res.data.map((obj) => {
          obj.value = obj.id;
          obj.label = obj.name;
          return obj;
        });
        setRepeaterTypes(mapped);
      })
      .catch((err) => toastr.error(err.response.data.errors));
  };

  const getSingleAppointment = (id) => {
    API.get(`/appointments/${id}`)
      .then((res) => {
        let reservation;

        if (res.data.reservation || res.data.confirmed_reservation) {
          if (res.data.confirmed_reservation) {
            reservation = res.data.confirmed_reservation;
          } else {
            reservation = res.data.reservation;
          }

          setReservation((prevState) => ({
            ...prevState,
            solution_date: new Date(reservation.start_date),
            start_time: new Date(reservation.start_date),
            end_time: new Date(reservation.end_date),
            technician_id: reservation.technician.id,
          }));
        }
        setAppointmentInfo((prevState) => ({
          ...prevState,
          ticket_number: res.data.ticket_number,
          first_name: res.data.customer.first_name,
          last_name: res.data.customer.last_name,
          account_number: res.data.customer.account_number,
          email: res.data.customer.email,
          mobile_sms: parsePhoneNumberFromString(res.data.customer.mobile)
            .nationalNumber,
          building: res.data.customer.building.id,
          want_sms: res.data.confirm_via_sms,
          user_id: res.data.id,
          purchase_speed: res.data.customer.purchase_speed,
          apt: res.data.customer.apartment,
          comments: res.data.comments,
          activation_key: res.data.customer.activation_key,
          accepted_repeater_deposit: res.data.accepted_repeater_deposit,
          accepted_repeater_deposit_type:
            res.data.accepted_repeater_deposit_type,
          accepted_repeater_deposit_price:
            res.data.accepted_repeater_deposit_price,
          waived_repeater_deposit: res.data.waived_repeater_deposit,
          waived_repeater_deposit_reason:
            res.data.waived_repeater_deposit_reason,
        }));
        setProblemInfo((prevState) => ({
          ...prevState,
          problem_id: res.data.problem.id || '',
          problem_name: res.data.problem.name || '',
          problem_tech_array: res.data.problem.tech_levels || [],
          problem_description: res.data.problem.description || '',
        }));
      })
      .catch((err) => toastr.error('Problem getting appointment info'));
  };

  const putEditAppointment = (id, params) => {
    if (validationFunction()) {
      return;
    }

    const momentDate = moment(reservation.solution_date).format('YYYY-MM-DD');
    const momentStartTime = moment(reservation.start_time).format('HH:mm');
    const momentEndTime = moment(reservation.end_time).format('HH:mm');
    const start_date = moment(momentDate + ' ' + momentStartTime).format(
      'YYYY-MM-DD HH:mm'
    );
    const end_date = moment(momentDate + ' ' + momentEndTime).format(
      'YYYY-MM-DD HH:mm'
    );

    params.reservation.start_date = start_date;
    params.reservation.end_date = end_date;
    params.reservation.force_confirm_do_not_notify_customer = reservation.force_confirm_do_not_notify_customer
      ? true
      : 'false';

    API.put(`/appointments/${id}`, params)
      .then((res) => {
        props.history.push(`/appointments/view/${id}`);
      })
      .catch((err) => toastr.error('Could not edit this appointment'));
  };

  const [buildingNotes, setBuildingNotes] = useState({ notes: '' });

  useEffect(() => {
    getSingleAppointment(id);
    getRepeaterTypes();
  }, [id]);

  useEffect(() => {
    {
      if (appointmentInfo.building !== '')
        API.get(`/buildings/${appointmentInfo.building}`).then((res) => {
          if (
            res.data.building &&
            res.data.building.notes &&
            res.data.building.notes.body !== null
          ) {
            setBuildingNotes({ notes: res.data.building.notes.body });
          } else {
            setBuildingNotes({ notes: '' });
          }
        });
    }
  }, [appointmentInfo.building]);

  const [recommendedTimes, setRecommendedTimes] = useState([]);
  const [sameBuildingTechs, setSameBuildingTechs] = useState([]);

  const [isDescriptionNeeded, setIsDescriptionNeeded] = useState(
    props.isDescriptionNeeded || false
  );

  const [listOfCustomers, setListOfCustomers] = useState([]);
  const [listOfBuildings, setListOfBuildings] = useState([]);

  const [listOfProblems, setListOfProblems] = useState([]);

  const [isGetTechsComplete, setIsTechsComplete] = useState(false);
  const getTechs = (prevState) => {
    API.get('/users')
      .then((res) => {
        const mappedTechs = [];
        res.data.forEach((user) => {
          if (user.permission_level === 0) {
            user.title = user.name;
            user.id = user.user_id;
            user.label = `${user.name} (Level ${user.tech_level})`;
            user.value = user.user_id;

            if (
              prevState.problem_tech_array.includes(1) &&
              user.tech_level === 1 &&
              user.is_active
            ) {
              mappedTechs.push(user);
            }
            if (
              prevState.problem_tech_array.includes(2) &&
              user.tech_level === 2 &&
              user.is_active
            ) {
              mappedTechs.push(user);
            }
            if (
              prevState.problem_tech_array.includes(3) &&
              user.tech_level === 3 &&
              user.is_active
            ) {
              mappedTechs.push(user);
            }
          }
        });

        setTechList(mappedTechs);
        setIsTechsComplete(true);
      })
      .catch((err) => {
        toastr.error('Error loading technicians');
      });
  };

  const debouncedGetTechs = React.useCallback(debounce(getTechs, 500), []);

  const getProblems = () => {
    API.get('/settings')
      .then((res) => {
        const problems = res.data.appointment_problems.map((problem) => {
          problem.label = problem.name;
          problem.value = problem.id;
          return problem;
        });
        setListOfProblems(problems);
      })
      .catch((err) => toastr.error(`${err.response.data.errors}`));
  };

  const getRecommendations = (building, tech_levels) => {
    if (tech_levels.length > 0 && building !== '') {
      API.get('/appointments/recommendations', {
        params: {
          building: building,
          tech_levels: tech_levels,
        },
      }).then((res) => {
        setRecommendedTimes(res.data.recommendations);
        setSameBuildingTechs(res.data.techs);
      });
    } else {
      setRecommendedTimes([]);
    }
  };

  const debouncedGetRecommendations = React.useCallback(
    debounce(getRecommendations, 500),
    []
  );

  useEffect(() => {
    debouncedGetTechs();
    getBuildingList();
    getProblems();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    debouncedGetRecommendations(
      appointmentInfo.building,
      problemInfo.problem_tech_array
    );
    debouncedGetTechs(problemInfo);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [problemInfo, appointmentInfo]);

  const handleChooseRecommendation = (row) => {
    setReservation((prevState) => ({
      ...prevState,
      solution_date: new Date(row.start_date),
      start_time: new Date(row.start_date),
      end_time: new Date(row.end_date),
      technician_id: row.technician.id,
    }));
  };

  const handleCustomerSearch = (e) => {
    API.get('/customers/search', {
      params: {
        query: e,
      },
    })
      .then((res) => {
        const mappedUsers = res.data.map((user) => {
          user.value = user.id;
          user.label = user.search_text;
          return user;
        });
        setListOfCustomers(mappedUsers);
      })
      .catch((err) => {
        toastr.error('Error searching for customer');
      });
  };

  useEffect(() => {
    const reservation_array = [];
    if (
      reservation.solution_date !== '' &&
      reservation.start_time !== '' &&
      reservation.end_time !== '' &&
      reservation.technician_id !== ''
    ) {
      const momentDate = moment(reservation.solution_date).format('YYYY-MM-DD');
      const momentStartTime = moment(reservation.start_time).format('HH:mm');
      const momentEndTime = moment(reservation.end_time).format('HH:mm');
      const start_date = moment(momentDate + ' ' + momentStartTime).format(
        'YYYY-MM-DD HH:mm'
      );
      const end_date = moment(momentDate + ' ' + momentEndTime).format(
        'YYYY-MM-DD HH:mm'
      );

      const new_event = {
        start: new Date(start_date),
        end: new Date(end_date),
        resourceId: reservation.technician_id,
        title: 'This Appointment',
        groupId: reservation.technician_id,
        color: '#ffc76e',
        textColor: '#000000',
        classNames: ['bold'],
      };

      if (reservation_array.length > 0) {
        reservation_array.pop();
      }
      reservation_array.push(new_event);

      setInjectionDate(reservation_array);
    }
  }, [reservation]);

  const handleCustomerSelected = (e) => {
    API.get(`/customers/${e.id}`)
      .then((res) => {
        const customer = res.data;

        setAppointmentInfo((prevState) => ({
          ...prevState,
          ticket_number: customer.ticket_number,
          first_name: customer.first_name,
          last_name: customer.last_name,
          account_number: customer.account_number,
          email: customer.email,
          mobile_sms: customer.mobile,
          building: customer.building.id,
          apt: customer.apartment,
          purchase_speed: customer.speed,
          user_id: customer.id,
          accepted_repeater_deposit: false,
          accepted_repeater_deposit_type: '',
          accepted_repeater_deposit_price: 0,
        }));
      })
      .catch((err) => {
        toastr.error('Error loading customer info');
      });
  };

  const getBuildingList = () => {
    API.get('/buildings')
      .then((res) => {
        const mappedBuildings = res.data.map((building) => {
          building.value = building.id;
          building.label = building.name;
          return building;
        });

        setListOfBuildings(mappedBuildings);
      })
      .catch((err) => {
        toastr.error('Error loading buildings');
      });
  };

  const handleReservationChange = (value, name) => {
    setReservation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleProblemInfoChange = (value, name) => {
    setProblemInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const postNewAppointmentParams = {
    ticket_number: appointmentInfo.ticket_number,
    accepted_repeater_deposit:
      appointmentInfo.accepted_repeater_deposit == true ? true : 'false',
    accepted_repeater_deposit_type: `${appointmentInfo.accepted_repeater_deposit_type}`,
    accepted_repeater_deposit_price:
      appointmentInfo.accepted_repeater_deposit_price,
    waived_repeater_deposit:
      appointmentInfo.waived_repeater_deposit == true ? true : 'false',
    waived_repeater_deposit_reason:
      appointmentInfo.waived_repeater_deposit_reason,
    customer: {
      account_number: appointmentInfo.account_number,
      speed: appointmentInfo.purchase_speed,
      first_name: appointmentInfo.first_name,
      last_name: appointmentInfo.last_name,
      mobile: `+1${appointmentInfo.mobile_sms}`,
      email: appointmentInfo.email,
      activation_key: appointmentInfo.activation_key,
      building: {
        id: appointmentInfo.building,
      },
      apartment: appointmentInfo.apt,
    },
    problem: {
      id: problemInfo.problem_id,
      name: problemInfo.problem_name,
      description: problemInfo.problem_description,
      tech_levels: problemInfo.problem_tech_array,
    },
    confirm_via_sms: `${appointmentInfo.want_sms}`,
    reservation: {
      start_date: reservation.start_time,
      end_date: reservation.end_time,
      technician_id: reservation.technician_id,
    },
  };

  const validationFunction = () => {
    if (
      !appointmentInfo.ticket_number ||
      appointmentInfo.ticket_number === ''
    ) {
      return toastr.error('Please enter a valid ticket number');
    }
    if (appointmentInfo.activation_key === '') {
      return toastr.error('Please enter a valid activation key');
    }
    if (appointmentInfo.first_name === '') {
      return toastr.error('Please enter a first name');
    }
    if (appointmentInfo.last_name === '') {
      return toastr.error('Please enter a last name');
    }
    if (appointmentInfo.account_number === '') {
      return toastr.error('Please enter a valid account number');
    }
    if (appointmentInfo.want_sms && appointmentInfo.mobile_sms === '') {
      return toastr.error('Please enter a valid sms receiving number');
    }
    if (appointmentInfo.email === '') {
      return toastr.error('Please enter a valid email address');
    }
    if (appointmentInfo.building === '') {
      return toastr.error('Please enter a valid building');
    }
    if (appointmentInfo.apt === '') {
      return toastr.error('Please enter a valid apartment number');
    }
    if (
      (appointmentInfo.accepted_repeater_deposit &&
        (appointmentInfo.accepted_repeater_deposit_type === '' ||
          parseInt(appointmentInfo.accepted_repeater_deposit_price) <= 0 ||
          appointmentInfo.accepted_repeater_deposit_price === '' ||
          appointmentInfo.accepted_repeater_deposit_price == null)) ||
      (appointmentInfo.waived_repeater_deposit == true &&
        appointmentInfo.waived_repeater_deposit_reason == '')
    ) {
      return toastr.error('Please correctly fill about the repeater form');
    }
    if (reservation.solution_date === '') {
      return toastr.error('Please enter a valid reservation date');
    }
    if (reservation.start_time === '') {
      return toastr.error('Please enter a valid reservation start time');
    }
    if (reservation.end_time === '') {
      return toastr.error('Please enter a valid reservation end time');
    }
    if (reservation.technician_id === '') {
      return toastr.error('Please select a technician');
    }
    if (reservation.end_time <= reservation.start_time) {
      return toastr.error('End time needs to be after start time');
    }
    if (problemInfo.problem_name === '') {
      return toastr.error('Please select a problem');
    }
    if (
      problemInfo.problem_name === 'Other' &&
      problemInfo.problem_description === ''
    ) {
      return toastr.error('You must enter a problem description');
    }
    return null;
  };

  const handleSimpleAppointmentInfoChange = (
    value,
    name,
    value_two,
    name_two
  ) => {
    if (value_two && name_two) {
      setAppointmentInfo((prevState) => ({
        ...prevState,
        [name]: value,
        [name_two]: value_two,
      }));
    } else {
      setAppointmentInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleIsDescriptionNeeded = (val) => {
    setIsDescriptionNeeded(val);
  };

  const [showTechsAtBuilding, setShowTechsAtBuilding] = useState(false);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Edit Appointment</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/appointments">Appointments</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Edit Appointment</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      {buildingNotes !== null && buildingNotes.notes !== '' ? (
        <BuildingNotesBanner building={buildingNotes} />
      ) : null}
      <Row>
        <Col>
          <FormGroup
            style={{
              float: 'right',
              marginTop:
                buildingNotes !== null && buildingNotes.notes !== ''
                  ? '0px'
                  : '60px',
            }}
          >
            {isReadOnly ? null : (
              <Button
                color="primary"
                onClick={() => {
                  putEditAppointment(id, postNewAppointmentParams);
                }}
              >
                Apply Changes
              </Button>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-4">
          <CustomerInfoForm
            appointmentInfo={appointmentInfo}
            handleCustomerSearch={handleCustomerSearch}
            listOfCustomers={listOfCustomers}
            listOfBuildings={listOfBuildings}
            handleCustomerSelected={handleCustomerSelected}
            isReadOnly={isReadOnly}
            handleCustomerInfoChange={handleSimpleAppointmentInfoChange}
            hide={true}
            repeaterTypes={repeaterTypes}
          />
        </Col>
        <Col md={6}>
          <Row>
            <Col>
              <ProblemInfoForm
                listOfProblems={listOfProblems}
                problemInfo={problemInfo}
                isReadOnly={isReadOnly}
                isDescriptionNeeded={isDescriptionNeeded}
                recommendedTimes={recommendedTimes}
                handleProblemInfoChange={handleProblemInfoChange}
                handleIsDescriptionNeeded={handleIsDescriptionNeeded}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SolutionInfoForm
                reservation={reservation}
                techList={techList}
                handleSimpleAppointmentInfoChange={
                  handleSimpleAppointmentInfoChange
                }
                handleReservationChange={handleReservationChange}
                isEdit={true}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          {isGetTechsComplete ? (
            <RecommendedTimeTable
              handleChooseRecommendation={handleChooseRecommendation}
              techList={techList}
              recommendedTimes={recommendedTimes}
            />
          ) : null}
        </Col>
      </Row>
      <Button
        className="mb-4"
        onClick={() => {
          showTechsAtBuilding
            ? setShowTechsAtBuilding(false)
            : setShowTechsAtBuilding(true);
        }}
      >
        Toggle Techs at Building
      </Button>
      {showTechsAtBuilding ? (
        <Row>
          <Col>
            <SameBuildingAppointmentsTable
              techList={techList}
              recommendedTimes={sameBuildingTechs}
            />
          </Col>
        </Row>
      ) : null}

      <Row>
        <Col></Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <CalendarComponent
                inject={injectionDate}
                focusAppointment={id}
                showTechLevels={problemInfo.problem_tech_array}
                isCreatingAppointment={true}
                handleReservationChange={handleReservationChange}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <CommentContainer
            appointment={appointmentInfo}
            appointmentId={id}
            repullAppointment={getSingleAppointment}
          />
        </Col>
      </Row>
    </Container>
  );
};

//below are the components that will go into the parent container

const RecommendedTimeTable = ({
  handleChooseRecommendation,
  recommendedTimes,
  techList,
  ...props
}) => {
  const tableColumns = [
    {
      dataField: 'date',
      text: 'Date',
      headerStyle: {
        width: '12rem',
      },
      formatter: (cell, row) => {
        return moment(row.start_date).format('dddd, MMMM Do YYYY');
      },
    },
    {
      dataField: 'start_time',
      text: 'Start Time',
      headerStyle: {
        width: '8rem',
      },
      formatter: (cell, row) => {
        return moment(row.start_date).format('h:mm A');
      },
    },
    {
      dataField: 'end_time',
      text: 'End Time',
      headerStyle: {
        width: '8rem',
      },
      formatter: (cell, row) => {
        return moment(row.end_date).format('h:mm A');
      },
    },
    {
      dataField: 'technician_id',
      text: 'Technician',
      headerStyle: {
        width: '12rem',
      },
      formatter: (cell, row) => {
        const tech = techList.find(
          (tech) => tech.user_id === row.technician.id
        );

        return `${tech.name} (Level ${tech.tech_level})`;
      },
    },
    {
      dataField: 'reason',
      text: 'Reason',
      headerStyle: {
        width: '15rem',
      },
    },
    {
      dataField: 'choose',
      text: 'Click to Choose',
      headerStyle: {
        width: '8rem',
      },
      formatter: (cell, row) => {
        return (
          <Button onClick={() => handleChooseRecommendation(row)}>
            Choose
          </Button>
        );
      },
    },
  ];

  return (
    <Card>
      <CardHeader tag="h4">Recommended Times</CardHeader>
      <CardBody>
        <ToolkitProvider
          keyField="id"
          data={recommendedTimes}
          columns={tableColumns}
        >
          {(props) => (
            <BootstrapTable
              {...props.baseProps}
              bootstrap4
              bordered={false}
              pagination={paginationFactory({
                sizePerPage: 10,
                sizePerPageList: [5, 10, 25, 50],
              })}
            />
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  );
};

//functions that are to be used in the entire page
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    authToken: state.auth.authToken,
    refreshToken: state.auth.refreshToken,
  };
};

export default withRouter(
  connect(mapStateToProps)(AppoitnmentCreateEditParentContainer)
);
