import React, { useState, useEffect } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import ResolutionSuccessfulPercentage from "../../components/Reports/ResolutionSuccessfulPerecentage";
import AppointmentPerBuilding from "../../components/Reports/AppointmentPerBuilding";
import API from "../../api/index";
import { toastr } from "react-redux-toastr";
import QtyApInstalledContainer from "../../components/Reports/QtyApInstalled";

var FileSaver = require("file-saver");

const ReportsContainer = ({ history }) => {
  //start initial load state and functions
  const [techList, setTechList] = useState([]);
  const [listOfBuildings, setListOfBuildings] = useState([]);

  const getTechList = () => {
    API.get("/users").then((res) => {
      const techs = res.data.filter((tech) => tech.permission_level === 0);

      const mappedTechs = techs.map((tech) => {
        tech.label = tech.name;
        tech.value = tech.user_id;
        return tech;
      });

      setTechList(mappedTechs);
    });
  };
  const getBuildingList = () => {
    API.get("/buildings")
      .then((res) => {
        const mappedBuildings = res.data.map((building) => {
          building.value = building.id;
          building.label = building.name;
          return building;
        });

        setListOfBuildings(mappedBuildings);
      })
      .catch((err) => {
        toastr.error("Error loading buildings");
      });
  };
  //end initial

  //start resolution success rate report state and functions
  const [resolutionSuccessResults, setResolutionSuccessResults] = useState([]);
  const [haveRSPResults, setHaveRSPResults] = useState(false);
  const handleSetHaveResults = (bool) => {
    setHaveRSPResults(bool);
  };

  const handleGetResolutionSuccess = (data) => {
    if (
      data.params.start_date !== "Invalid date" &&
      data.params.end_date !== "Invalid date"
    ) {
      API.get("/reports/resolution_success", data)
        .then((res) => {
          setResolutionSuccessResults(res.data);
          setHaveRSPResults(true);
        })
        .catch((err) => {
          history.push(`/reports`);
          return toastr.error(`${err}`);
        });
    } else {
      return toastr.error("Must have a start and end date");
    }
  };

  const downloadResolutionSuccess = (data) => {
    //route that will respond with csv
    API.get("/reports/resolution_success_download", data)
      .then((res) => {
        const test = new Blob([res.data], { type: "text/csv" });

        FileSaver.saveAs(test, "resolution_success.csv");
      })
      .catch((err) => {
        history.push("/reports");
        return toastr.error(`${err}`);
      });
  };

  //end resolution success rate report

  //start  appointments per building report
  const [ApBResults, setApBResults] = useState([]);
  const [haveApBResults, setHaveApBResults] = useState(false);

  const handleSubmitReport = (data) => {
    if (
      data.params.start_date !== "Invalid date" &&
      data.params.end_date !== "Invalid date"
    ) {
      API.get("/reports/appointments_per_building", data)
        .then((res) => {
          setApBResults(res.data);
          setHaveApBResults(true);
        })
        .catch((err) => {
          history.push("/reports");
          return toastr.error(
            "Problem with getting the appointments per building"
          );
        });
    } else {
      return toastr.error("Must have a start and end date");
    }
  };

  const handleApBDownload = (data) => {
    if (
      data.params.start_date !== "Invalid date" &&
      data.params.end_date !== "Invalid date"
    ) {
      API.get("/reports/appointments_per_building_download", data)
        .then((res) => {
          const test = new Blob([res.data], { type: "text/csv" });

          FileSaver.saveAs(test, "appointments_per_building.csv");
        })
        .catch((err) => {
          history.push("/reports");
          return toastr.error(
            "Problem with getting the appointments per building"
          );
        });
    } else {
      return toastr.error("Must have a start and end date");
    }
  };

  const handleSetHaveApBResults = (bool) => {
    setHaveApBResults(bool);
  };

  //end appointments per building report

  //start qty of ap's installed
  const [numberOfApInstalled, setNumberOfApInstalled] = useState(0);
  const [numberOfRouterInstalled, setNumberOfRouterInstalled] = useState(0);
  const [haveQtyResults, setHaveQtyResults] = useState(false);

  const getQtyOfApInstalled = (data) => {
    if (
      data.params.start_date !== "Invalid date" &&
      data.params.end_date !== "Invalid date"
    ) {
      API.get("/reports/qty_ap_installed", data)
        .then((res) => {
          setNumberOfApInstalled(res.data.total_ap_installed);
          setNumberOfRouterInstalled(res.data.total_router_installed);
          setHaveQtyResults(true);
        })
        .catch((err) => {
          history.push("/reports");
          return toastr.error(`${err}`);
        });
    } else {
      return toastr.error("Must have a start and end date");
    }
  };

  const getEquipemntQtyInstalledDownload = (data) => {
    if (
      data.params.start_date !== "Invalid date" &&
      data.params.end_date !== "Invalid date"
    ) {
      API.get("/reports/qty_equipment_installed_download", data)
        .then((res) => {
          const test = new Blob([res.data], { type: "text/csv" });

          FileSaver.saveAs(test, "qty_equipment_installed.csv");
        })
        .catch((err) => {
          history.push("/reports");
          return toastr.error(`${err}`);
        });
    } else {
      return toastr.error("Must have a start and end date");
    }
  };

  //end qty of ap's installed

  //start speed adequate

  //end speed adequate

  useEffect(() => {
    getTechList();
    getBuildingList();
  }, []);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Reports Dashboard</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem active>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Row>
        <Col>
          <ResolutionSuccessfulPercentage
            techList={techList}
            handleGetResolutionSuccess={handleGetResolutionSuccess}
            resolutionSuccessResults={resolutionSuccessResults}
            haveResults={haveRSPResults}
            handleSetHaveResults={handleSetHaveResults}
            downloadResolutionSuccess={downloadResolutionSuccess}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <AppointmentPerBuilding
            buildingList={listOfBuildings}
            handleSubmitReport={handleSubmitReport}
            handleSetHaveApBResults={handleSetHaveApBResults}
            haveApBResults={haveApBResults}
            ApBResults={ApBResults}
            handleApBDownload={handleApBDownload}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <QtyApInstalledContainer
            numberOfApInstalled={numberOfApInstalled}
            haveQtyResults={haveQtyResults}
            getQtyOfApInstalled={getQtyOfApInstalled}
            numberOfRouterInstalled={numberOfRouterInstalled}
            getEquipemntQtyInstalledDownload={getEquipemntQtyInstalledDownload}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(ReportsContainer);
