import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import Geocode from 'react-geocode';
import { toastr } from 'react-redux-toastr';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { Link, withRouter } from 'react-router-dom';
import { getName } from '../../helpers/TokenDecode';
import Header from '../../components/Header';
import HeaderTitle from '../../components/HeaderTitle';
import Marker from '../../components/Marker';
import API from '../../api/index';
import BuildingNotesBanner from '../../components/BuildingNotesBanner';

import { ButtonStatusIndicator } from '../../components/AppointmentStatusIndicator';

Geocode.setApiKey('AIzaSyBSjEmncXZZ5ZcsTquICU_DmrdKmCa0Dic');

const EmbeddedMap = ({ zoom, lat, lon }) => {
  const [center] = useState({
    lat: parseFloat(lat),
    lng: parseFloat(lon),
  });

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyBSjEmncXZZ5ZcsTquICU_DmrdKmCa0Dic' }}
      center={center}
      defaultZoom={zoom}
      yesIWantToUseGoogleMapApiInternals
    >
      <Marker key={1} text="test" lat={center.lat} lng={center.lng} />
    </GoogleMapReact>
  );
};

const AnnotatedText = ({ annotation, children }) => (
  <div>
    <div style={{ marginTop: '30px', fontSize: '1em', color: '#919191' }}>
      {annotation}
    </div>
    <div style={{ fontWeight: 'bold', marginBottom: '30px' }}>{children}</div>
  </div>
);

const AppointmentCard = ({
  appointment,
  handleResolveClick,
  handleResolutionEditClick,
  createProxyTextSession,
  length,
  idx,
}) => {
  const start = appointment.confirmed_reservation.start_date;
  const end = appointment.confirmed_reservation.end_date;
  const mobile = parsePhoneNumberFromString(appointment.customer.mobile);

  const mapParams = {
    center: {
      lat: 40.7481,
      lng: -73.9393202,
    },
    zoom: 13,
  };

  const buildingAddress = `${appointment.customer.building.street1}, ${appointment.customer.building.street2}`;

  const [showResEdit, setShowResEdit] = useState(true);
  const [buildingNotes, setBuildingNotes] = useState({ notes: '' });

  useEffect(() => {
    const today = new Date().setHours(0, 0, 0, 0);
    let start_date = new Date().setHours(0, 0, 0, 0);
    if (appointment && appointment.confirmed_reservation) {
      start_date = new Date(
        appointment.confirmed_reservation.start_date
      ).setHours(0, 0, 0, 0);
    }

    if (appointment.status !== 'resolved' || start_date !== today) {
      setShowResEdit(false);
    } else {
      setShowResEdit(true);
    }
  }, [appointment]);

  useEffect(() => {
    {
      if (
        appointment &&
        appointment.customer &&
        appointment.customer.building &&
        appointment.customer.building.id !== ''
      )
        API.get(`/buildings/${appointment.customer.building.id}`).then(
          (res) => {
            if (
              res.data.building &&
              res.data.building.notes &&
              res.data.building.notes.body !== null
            ) {
              setBuildingNotes({ notes: res.data.building.notes.body });
            } else {
              setBuildingNotes({ notes: '' });
            }
          }
        );
    }
  }, [appointment]);

  return (
    <Row
      style={length - 1 === idx ? null : { pageBreakAfter: 'always' }}
      className="appointment-row h-100 justify-content-center row-eq-height"
    >
      <Col className="d-none d-md-block d-md-inline" md={1}>
        <div className="start-time">{moment(start).format('h:mm a')}</div>
        <div className="end-time">{moment(end).format('h:mm a')}</div>
      </Col>
      <Col md={10}>
        <Card>
          <CardHeader>
            <Row className="d-sm-inline d-md-none d-lg-none">
              <Col className="text-center">
                <h5>{moment(start).format('MMMM Do')}</h5>
                <h5>
                  {moment(start).format('h:mm a')} -{' '}
                  {moment(end).format('h:mm a')}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col style={{ float: 'left' }}>
                <h2>{appointment.customer.building.shortdesc}</h2>
              </Col>
              <Col>
                <ButtonStatusIndicator
                  appointment={appointment}
                  className="float-right"
                />
              </Col>
            </Row>
            {buildingNotes !== null && buildingNotes.notes !== '' ? (
              <BuildingNotesBanner building={buildingNotes} isTech={true} />
            ) : null}
          </CardHeader>
          <CardBody style={{ minHeight: '320px' }}>
            <Row className="h-100">
              <Col md={6} className="d-flex flex-column">
                <div>
                  <h2>
                    {appointment.customer.first_name}{' '}
                    {appointment.customer.last_name}
                  </h2>
                  <h2>Unit {appointment.customer.apartment}</h2>
                  {appointment &&
                  appointment.confirmed_reservation &&
                  appointment.confirmed_reservation.technician &&
                  (appointment.confirmed_reservation.technician
                    .mobile_number !== '' ||
                    appointment.confirmed_reservation.technician
                      .mobile_number !== null) ? (
                    <h5>
                      <a
                        href={`sms://${mobile.nationalNumber}&body=Hello this your natural wireless technician.`}
                      >
                        {mobile.formatNational()}
                      </a>

                      {/* {appointment &&
                      (!appointment.proxy_session ||
                        appointment.proxy_session.is_expired ||
                        appointment.proxy_session.sid == null ||
                        appointment.proxy_session.sid == '') ? (
                        <Button
                          onClick={() =>
                            createProxyTextSession(appointment, idx)
                          }
                        >{`Call or Text ${appointment.customer.first_name}`}</Button>
                      ) : (
                        <>
                          <h5 className="mt-3">
                            To contact, please call or text
                          </h5>
                          {parsePhoneNumberFromString(
                            appointment.proxy_session.tech_proxy_phone_number
                          ).format('NATIONAL')}{' '}
                        </>
                      )} */}
                    </h5>
                  ) : null}

                  <AnnotatedText annotation="Job">
                    {appointment.problem.name}
                  </AnnotatedText>
                  <AnnotatedText annotation="Additional Details">
                    {`${appointment.problem.description} \n (Created by: ${appointment.created_by.username})`}
                  </AnnotatedText>
                  <AnnotatedText annotation="Customer Purchase Speed">
                    {appointment.customer.purchase_speed} Mbps /{' '}
                    {appointment.customer.purchase_speed} Mbps
                  </AnnotatedText>
                  <AnnotatedText annotation="Activation Key">
                    {appointment.customer.activation_key}
                  </AnnotatedText>
                </div>
                <div className="mt-auto">
                  <h5>{appointment.customer.building.street1}</h5>
                  <h5>{appointment.customer.building.street2}</h5>
                </div>
              </Col>
              {appointment.customer.building.lat &&
              appointment.customer.building.lon ? (
                <Col md={6} className="d-none d-sm-inline">
                  <EmbeddedMap
                    address={buildingAddress}
                    zoom={mapParams.zoom}
                    lat={appointment.customer.building.lat}
                    lon={appointment.customer.building.lon}
                  />
                </Col>
              ) : null}
            </Row>
          </CardBody>
          {showResEdit ? (
            <Row>
              <Col>
                <Button
                  className="ml-3"
                  onClick={() => handleResolutionEditClick(appointment)}
                >
                  Edit
                </Button>
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col className="p-4">
              {appointment && appointment.status === 'resolved' ? (
                <Button disabled className="btn-block">
                  Resolved
                </Button>
              ) : (
                <Button
                  value={appointment.id}
                  onClick={() => handleResolveClick(appointment.id)}
                  className="btn-block"
                >
                  Resolve
                </Button>
              )}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

const NoAppointmentsCard = () => {
  return (
    <Row>
      <Col md={{ size: 10, offset: 1 }}>
        <Card>
          <CardHeader className="text-center" tag="h4">
            No Appointments to show for this time frame
          </CardHeader>
          <CardBody>
            <h6 className="text-center">
              Please change the date to see other confirmed appointments
            </h6>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const Dashboard = ({ authToken, history }) => {
  const [appointments, setAppointments] = useState([]);
  const [sortedAppointments, setSortedAppointments] = useState([]);
  const [date, setDate] = useState(moment(new Date())); // defaults to today
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleResolveClick = (id) => {
    history.push(`/appointments/resolve/${id}`);
  };

  const handleResolutionEditClick = (appointment) => {
    history.push(`/resolutions/edit/${appointment.resolution.id}`);
  };

  const createProxyTextSession = (appointment, idx) => {
    API.post(`/appointments/${appointment.id}/create_proxy_text_session`)
      .then((res) => {
        appointments[idx] = res.data;
        setAppointments([...appointments]);
        toastr.success('Text successfully sent');
      })
      .catch((err) => {
        toastr.error(
          err.response.data && err.response.data.response
            ? `${err.response.data.response}`
            : `${err.response.data.errors}`
        );
      });
  };

  useEffect(() => {
    const sortedArray = appointments.sort((a, b) => {
      const start_a = moment(a.confirmed_reservation.start_date);
      const start_b = moment(b.confirmed_reservation.start_date);
      return start_a.diff(start_b);
    });
    setSortedAppointments(sortedArray);
  }, [appointments]);

  useEffect(() => {
    loadAppointments();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const loadAppointments = () => {
    API.get('/appointments/confirmed', {
      params: { date: date.startOf('day').format('YYYY/MM/DD') },
    })
      .then((res) => {
        setAppointments(res.data);
      })
      .catch((err) => toastr.error('Problem getting appointments'));
  };

  return (
    <Container fluid>
      <Header style={{ paddingBottom: '70px' }}>
        <HeaderTitle>
          <div>Welcome, {getName(authToken)}</div>
          <div style={{ fontSize: '0.7em' }}>
            Here are your appointments for {date.format('dddd MMMM Do, YYYY')}
          </div>
        </HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem active>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row className="pb-5">
        <Col>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggle}
            direction="left"
            className="float-right"
          >
            <DropdownToggle>Change Date</DropdownToggle>
            <DropdownMenu left>
              <DropdownItem header disabled>
                Dates
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => setDate(moment())}>
                Today
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => setDate(moment().add(1, 'days'))}>
                Tomorrow
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => setDate(moment().add(-1, 'days'))}>
                Yesterday
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Col>
      </Row>
      {appointments.length === 0 ? <NoAppointmentsCard /> : null}

      {sortedAppointments.map((appointment, idx) => (
        <AppointmentCard
          key={appointment.id}
          length={sortedAppointments.length}
          idx={idx}
          appointment={appointment}
          handleResolveClick={handleResolveClick}
          handleResolutionEditClick={handleResolutionEditClick}
          createProxyTextSession={createProxyTextSession}
        />
      ))}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    authToken: state.auth.authToken,
  };
};

export default withRouter(connect(mapStateToProps)(Dashboard));
