import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { toastr } from "react-redux-toastr";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import debounce from "../../components/UseDebounce";

import moment from "moment";
import "moment-timezone";
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Container,
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import API from "../../api/index";
const { SearchBar } = Search;

const UnresolvedAppointmentsContainer = ({ history }) => {
  const [data, setData] = useState({
    entries: [],
    total_entries: 0,
  });
  const [tableState, setTableState] = useState({
    page: 1,
    searchText: "",
    sizePerPage: 5,
    sortField: null,
    sortOrder: null,
  });

  const fetchData = ({ tableState, data, setData }) => {
    const params = {
      page: tableState.page,
      sizePerPage: tableState.sizePerPage,
      searchText: tableState.searchText,
      status: "confirmed",
      sortField: tableState.sortField,
      sortOrder: tableState.sortOrder,
      // end_date: new Date("2020-5-15"),
    };
    API.get("/appointments/list_paginated", { params: params })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        toastr.error("Error loading appointments");
      });
  };
  const debouncedFetchData = React.useCallback(debounce(fetchData, 300), []);

  const handleTableChange = (type, newState) => {
    setTableState(newState);
  };

  const handleResolveClick = (id) => {
    history.push(`/appointments/resolve/${id}`);
  };

  useEffect(() => {
    debouncedFetchData({ tableState, data, setData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableState]);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Unresolved Appointments</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/appointments">Appointments</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Unresolved Appointments</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Card>
        <CardHeader tag="h4">Unresolved Appointments</CardHeader>
        <CardBody>
          <AppointmentsUnscheduledTable
            data={data}
            handleTableChange={handleTableChange}
            tableState={tableState}
            handleResolveClick={handleResolveClick}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

const AppointmentsUnscheduledTable = ({
  data,
  handleTableChange,
  tableState,
  handleResolveClick,
}) => {
  const defaultSorted = [
    {
      dataField: "start_date",
      order: "desc",
    },
  ];
  const tableColumns = [
    {
      dataField: "customer.first_name",
      text: "Customer",
      sort: false,
      formatter: (cell, row) =>
        `${row.customer.first_name} ${row.customer.last_name}`,
    },
    {
      dataField: "tech",
      text: "Technician",
      formatter: (cell, row) => {
        if (row.confirmed_reservation && row.confirmed_reservation.technician) {
          return row.confirmed_reservation.technician.name;
        }
      },
    },
    {
      dataField: "customer.building.shortdesc",
      text: "Building",
      sort: false,
      formatter: (cell, row) => `${row.customer.building.shortdesc}`,
    },
    {
      dataField: "customer.apartment",
      text: "Unit",
      sort: false,
      formatter: (cell, row) => `${row.customer.apartment}`,
    },
    {
      dataField: "start_date",
      text: "Date",
      sort: true,
      formatter: (cell, row) => {
        let reservation;
        if (row.confirmed_reservation) {
          reservation = row.confirmed_reservation;
        } else if (row.reservation) {
          reservation = row.reservation;
        } else {
          return null;
        }

        return moment(reservation.start_date).format("MM/DD/YYYY h:mm A");
      },
    },

    {
      dataField: "actions",
      formatter: (cell, row) => (
        <React.Fragment>
          <Button
            onClick={() => handleResolveClick(row.id)}
            color={"outline-primary"}
          >
            Resolve
          </Button>
        </React.Fragment>
      ),
      text: "Actions",
      sort: false,
    },
  ];

  return (
    <ToolkitProvider
      keyField="id"
      data={data.entries}
      columns={tableColumns}
      search
    >
      {(props) => (
        <React.Fragment>
          <div style={{ float: "right" }}>
            <SearchBar {...props.searchProps} />
          </div>

          <BootstrapTable
            {...props.baseProps}
            remote={{
              filter: true,
              pagination: true,
              sort: true,
              cellEdit: true,
            }}
            onTableChange={handleTableChange}
            bootstrap4
            bordered={false}
            defaultSorted={defaultSorted}
            pagination={paginationFactory({
              page: tableState.page,
              sizePerPage: tableState.sizePerPage,
              totalSize: data.total_entries,
              sizePerPageList: [5, 10, 25, 50],
            })}
          />
        </React.Fragment>
      )}
    </ToolkitProvider>
  );
};

export default UnresolvedAppointmentsContainer;
