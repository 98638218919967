import React, { useState, useEffect } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import API from '../../../api/index';
import Header from '../../../components/Header';
import HeaderTitle from '../../../components/HeaderTitle';
import { toastr } from 'react-redux-toastr';

import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Container,
} from 'reactstrap';
const { SearchBar } = Search;

const ListBuildings = (props) => {
  let history = useHistory();
  const [buildings, setBuildings] = useState([]);
  const getBuildings = () => {
    API.get('/buildings').then((res) => {
      const bldgs = [];
      res.data.forEach((x) => {
        if (x.building) {
          bldgs.push(x);
        }
      });
      setBuildings(bldgs);
    });
  };
  useEffect(() => {
    getBuildings();
  }, []);

  const tableColumns = [
    {
      dataField: 'name',
      headerStyle: { width: '30%' },
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'street1',
      text: 'Street 1',
      headerStyle: { width: '50%' },
    },

    {
      dataField: 'actions',
      headerStyle: { width: '20%' },
      formatter: (cell, row) => {
        return row.building ? (
          <>
            <Button
              color={'outline-primary'}
              onClick={() => history.push(`/admin/buildings/${row.id}`)}
            >
              View
            </Button>
            <Button
              style={{ marginLeft: '10px' }}
              color={'outline-primary'}
              onClick={() => history.push(`/admin/buildings/edit/${row.id}`)}
              value={row.id}
            >
              Edit Notes
            </Button>
          </>
        ) : null;
      },
      text: 'Actions',
      sort: false,
    },
  ];

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Buildings</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Buildings</BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Card>
        <CardHeader tag="h4">
          <div style={{ float: 'left' }}>
            Buildings
            <h6 className="card-subtitle text-muted pt-2">
              Listing of Buildings
            </h6>
          </div>
        </CardHeader>
        <Row>
          <Col>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                data={buildings}
                columns={tableColumns}
                search
              >
                {(props) => (
                  <React.Fragment>
                    <div style={{ float: 'right' }}>
                      <SearchBar {...props.searchProps} />
                    </div>

                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4
                      bordered={false}
                      pagination={paginationFactory({
                        sizePerPage: 25,
                        sizePerPageList: [5, 10, 25, 50],
                      })}
                    />
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default withRouter(ListBuildings);
