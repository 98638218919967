import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import moment from "moment";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import LogTable from "./Tables/Log";
import AnnotatedText from "../../../components/AnnotatedText";

const AppointmentLog = () => {
  let { id } = useParams();
  const [viewingLog, setViewingLog] = useState(null);

  const handleViewDetails = (log) => {
    setViewingLog(log);
  };

  const handleCloseDetails = () => {
    setViewingLog(null);
  };

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>View Appointment</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`/appointments/`}>Appointments</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            <Link to={`/appointments/view/${id}`}>View Appointment</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Logs</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col md={viewingLog ? 8 : 12}>
          <Card>
            <CardHeader tag="h4">Log</CardHeader>
            <CardBody>
              <LogTable appointmentId={id} onViewDetails={handleViewDetails} />
            </CardBody>
          </Card>
        </Col>
        {viewingLog && (
          <Col md={4}>
            <Card>
              <CardHeader>
                <div class="float-left">
                  <h4>Details</h4>
                </div>
                <div class="float-right">
                  <Button close onClick={handleCloseDetails} />
                </div>
              </CardHeader>
              <CardBody>
                <AnnotatedText annotation="User">
                  {viewingLog.user ? viewingLog.user.name : "N/A"}
                </AnnotatedText>
                <AnnotatedText annotation="Timestamp">
                  {viewingLog
                    ? moment(viewingLog.timestamp).format("MM/DD/YY h:mm a")
                    : "N/A"}
                </AnnotatedText>
                <AnnotatedText annotation="Event">
                  {viewingLog ? viewingLog.event : "N/A"}
                </AnnotatedText>
                <AnnotatedText annotation="Details">
                  {viewingLog ? viewingLog.message : "N/A"}
                </AnnotatedText>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default AppointmentLog;
