import React from "react";
import { connect } from "react-redux";

import { getPermissionLevel } from "../../../helpers/TokenDecode";

import AppointmentCalendarTech from "./Tech";
import AppointmentCalendarAgent from "./Agent";

const AppointmentCalendar = ({ sidebar, authToken, isAuthenticated }) => {
  const permissionLevel = getPermissionLevel(authToken);

  const component = () => {
    switch (permissionLevel) {
      case 0:
        return <AppointmentCalendarTech authToken={authToken} />;
      case 1:
        return <AppointmentCalendarAgent />;
      case 10:
        return <AppointmentCalendarAgent />;
      default:
        return null;
    }
  };
  return component();
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    authToken: state.auth.authToken,
  };
};

export default connect(mapStateToProps)(AppointmentCalendar);
