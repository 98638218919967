import React from "react";
import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
  Container,
} from "reactstrap";

import AppointmentsTable from "./Tables";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";

const AppointmentsList = () => {
  return (
    <Container fluid>
      <Header>
        <HeaderTitle>View All Appointments</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Appointments</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Card>
        <CardHeader tag="h4">Listing of Appointments</CardHeader>
        <CardBody>
          <AppointmentsTable />
        </CardBody>
      </Card>
    </Container>
  );
};

export default AppointmentsList;
