import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReduxToastr from "react-redux-toastr";
import "./assets/scss/_app.scss";

import Routes from "./routes/Routes";
import { authSetToken } from "./redux/actions/authActions";

class App extends React.Component {
  constructor(props) {
    super(props);
    if (localStorage.getItem("auth_token")) {
      this.props.authSetToken(localStorage.getItem("auth_token"));
    }

    /* admin */
    //this.props.authSetToken('eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo1LCJ1c2VybmFtZSI6ImV0aGFuIiwicGVybWlzc2lvbl9sZXZlbCI6MTAsInRlY2hfbGV2ZWwiOjB9.zO8eVlY-bbuwsGH-5wkhJ6ULTE8eTIsC4ParXclvkgA')

    /* level 2 tech */
    //this.props.authSetToken('eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6ImV0aGFuaGF5b24iLCJwZXJtaXNzaW9uX2xldmVsIjoxLCJ0ZWNoX2xldmVsIjoyfQ.ZaAR_mOi4I42KjLbqNWV1-hXFvRWzcH7CTy-0jfKPpM')
  }

  render() {
    return (
      <React.Fragment>
        <Routes />
        <ReduxToastr
          timeOut={5000}
          newestOnTop={true}
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    authToken: state.auth.authToken
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      authSetToken
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
