import React from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";

const CommentTable = ({ comments }) => {
  const tableColumns = [
    {
      dataField: "id",
      text: "Id",
      headerStyle: {
        width: "80px",
      },
    },
    {
      dataField: "user",
      text: "Created By",
      formatter: (cell, row) => row.created_by.name,
    },
    {
      dataField: "timestamp",
      text: "Timestamp",
      sort: true,
      formatter: (cell, row) => moment(cell).format("MM/DD/YY h:mm a"),
    },

    {
      dataField: "message",
      text: "Message",
      sort: true,
      formatter: (cell, row) => {
        return cell;
      },
    },
  ];

  return (
    <ToolkitProvider keyField="id" data={comments || []} columns={tableColumns}>
      {(props) => (
        <BootstrapTable
          {...props.baseProps}
          bootstrap4
          bordered={false}
          //   defaultSorted={defaultSorted}
          pagination={paginationFactory({
            sizePerPage: 10,
            sizePerPageList: [5, 10, 25, 50],
          })}
        />
      )}
    </ToolkitProvider>
  );
};

export default CommentTable;
