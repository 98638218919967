import jwt_decode from "jwt-decode";

export const getPermissionLevel = (token) => {
  //try catch cause token could not decode and the object could not have a permission level
  return jwt_decode(token)["permission_level"];
};

export const getName = (token) => {
  return jwt_decode(token)["name"];
};

export const getUserID = (token) => {
  return jwt_decode(token)["id"];
};

export const decodeToken = (token) => jwt_decode(token);
