import React, { useEffect, useState } from "react";
import Select from "react-select";
import _ from "lodash";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Label,
  FormGroup,
  Form,
  Input,
} from "reactstrap";

const ProblemInfoForm = ({
  listOfProblems,
  isReadOnly,
  handleIsDescriptionNeeded,
  isDescriptionNeeded,
  recommendedTimes,
  handleProblemInfoChange,
  problemInfo,
  ...props
}) => {
  const [isTechCheckboxDisabled, setIsTechCheckboxDisabled] = useState(true);

  const toggle = (array, item) => _.xor(array, [item]);

  useEffect(() => {
    if (problemInfo.problem_name === "Other") {
      setIsTechCheckboxDisabled(false);
      handleIsDescriptionNeeded(true);
    } else {
      setIsTechCheckboxDisabled(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [problemInfo.problem_id, problemInfo.problem_tech_array.length]);

  return (
    <Card>
      <CardHeader tag="h4">Problem Info</CardHeader>
      <CardBody>
        <Form>
          <Row>
            <Col>
              <FormGroup>
                <FormGroup>
                  <Label>Problem</Label>
                  <Select
                    options={listOfProblems}
                    value={listOfProblems.find(
                      (problem) => problem.value === problemInfo.problem_id
                    )}
                    onChange={(e) => {
                      handleProblemInfoChange(
                        e.tech_level,
                        "problem_tech_array"
                      );
                      handleProblemInfoChange(e.id, "problem_id");
                      handleProblemInfoChange(e.name, "problem_name");
                    }}
                    isDisabled={isReadOnly}
                  />
                </FormGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label>
                  Description
                  <Input
                    type="textarea"
                    cols="150"
                    value={problemInfo.problem_description}
                    onChange={(e) =>
                      handleProblemInfoChange(
                        e.target.value,
                        "problem_description"
                      )
                    }
                    disabled={isReadOnly}
                  />
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6>Level of Tech Needed</h6>
              <FormGroup check inline>
                <Label check>
                  <Input
                    disabled={isTechCheckboxDisabled}
                    checked={problemInfo.problem_tech_array.includes(1)}
                    type="checkbox"
                    name="tech_level_needed"
                    onChange={() => {
                      const new_tech_array = toggle(
                        problemInfo.problem_tech_array,
                        1
                      );
                      handleProblemInfoChange(
                        new_tech_array,
                        "problem_tech_array"
                      );
                    }}
                  />
                  1
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input
                    disabled={isTechCheckboxDisabled}
                    checked={problemInfo.problem_tech_array.includes(2)}
                    type="checkbox"
                    name="tech_level_needed"
                    onChange={() => {
                      const new_tech_array = toggle(
                        problemInfo.problem_tech_array,
                        2
                      );
                      handleProblemInfoChange(
                        new_tech_array,
                        "problem_tech_array"
                      );
                    }}
                  />
                  2
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input
                    disabled={isTechCheckboxDisabled}
                    checked={problemInfo.problem_tech_array.includes(3)}
                    type="checkbox"
                    name="tech_level_needed"
                    onChange={() => {
                      const new_tech_array = toggle(
                        problemInfo.problem_tech_array,
                        3
                      );
                      handleProblemInfoChange(
                        new_tech_array,
                        "problem_tech_array"
                      );
                    }}
                  />
                  3
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default ProblemInfoForm;
