import React from "react";
import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
  Container,
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";

import CalendarComponent from "../../../components/Calendar";

const AppointmentCalendarAgent = () => {
  return (
    <Container fluid>
      <Header>
        <HeaderTitle>View Appointment</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/appointments">Appointments</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Calendar</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Card>
        <CardHeader tag="h4">Agent Calendar</CardHeader>
        <CardBody>
          <CalendarComponent />
        </CardBody>
      </Card>
    </Container>
  );
};

export default AppointmentCalendarAgent;
