import React from "react";
import AsyncSelect from "react-select/async";
import { toastr } from "react-redux-toastr";
import debounce from "./UseDebounce";
import moment from "moment";
import { withRouter } from "react-router-dom";

import API from "../api";

const SearchBar = ({ history }) => {
  const normalizeResults = (data) => {
    // convert API response to something react-select understands
    let customers = data["customers"];
    let buildings = data["buildings"];
    let appointments = data["appointments"];

    customers = customers.map((customer) => ({
      label: `${customer.name} (${customer.building})`,
      value: customer.id,
      link: `/appointments/customer/${customer.id}`,
    }));

    buildings = buildings.map((building) => ({
      label: building.name,
      value: building.id,
      link: `/appointments/building/${building.id}`,
    }));

    appointments = appointments.map((appointment) => {
      let reservation =
        appointment.reservation || appointment.confirmed_reservation;
      const date = reservation
        ? moment(reservation.start_date).format("MM-DD-YY h:mm A")
        : "Unscheduled";
      return {
        label: `${appointment.customer.first_name} ${appointment.customer.last_name} (${date}) - Appt #${appointment.id}`,
        value: appointment.id,
        link: `/appointments/view/${appointment.id}`,
      };
    });

    return [
      {
        label: "Appointments",
        options: appointments,
      },
      {
        label: "Customers",
        options: customers,
      },
      {
        label: "Buildings",
        options: buildings,
      },
    ];
  };

  const loadOptions = (value, callback) => {
    API.post("/search", { q: value })
      .then((res) => {
        callback(normalizeResults(res.data));
      })
      .catch((err) => {
        toastr.error("Error performing search");
      });
  };

  const debouncedLoadOptions = React.useCallback(
    debounce(loadOptions, 250),
    []
  );

  //   const groupStyles = {
  //     display: "flex",
  //     alignItems: "center",
  //     justifyContent: "space-between",
  //   };
  //   const groupBadgeStyles = {
  //     backgroundColor: "#EBECF0",
  //     borderRadius: "2em",
  //     color: "#172B4D",
  //     display: "inline-block",
  //     fontSize: 12,
  //     fontWeight: "normal",
  //     lineHeight: "1",
  //     minWidth: 1,
  //     padding: "0.16666666666667em 0.5em",
  //     textAlign: "center",
  //   };
  //   const formatGroupLabel = (data) => (
  //     <div style={groupStyles}>
  //       <span>{data.label}</span>
  //       <span style={groupBadgeStyles}>{data.options.length}</span>
  //     </div>
  //   );

  const handleChange = (e) => {
    history.push(e.link);
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={debouncedLoadOptions}
      defaultOptions
      placeholder="Search..."
      onChange={handleChange}
    />
  );
};

export default withRouter(SearchBar);
