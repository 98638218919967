import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { toastr } from "react-redux-toastr";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Breadcrumb,
  BreadcrumbItem,
  Container,
} from "reactstrap";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import API from "../../../api";
import AppointmentsTable from "./Tables";

const BuildingAppointmentList = () => {
  let { id } = useParams();
  const [building, setBuilding] = useState({});

  const fetchCustomer = (id) => {
    API.get(`/buildings/${id}`)
      .then((res) => {
        setBuilding(res.data);
      })
      .catch((err) => {
        toastr.error("Error loading building");
      });
  };

  useEffect(() => {
    fetchCustomer(id);
  }, [id]);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>View All Appointments</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Appointments</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Card>
        <CardHeader>
          <CardTitle tag={"h3"}>
            {" "}
            Filtered by Building ({building.name})
          </CardTitle>
        </CardHeader>
        <CardBody>
          <AppointmentsTable building={id} />
        </CardBody>
      </Card>
    </Container>
  );
};

export default BuildingAppointmentList;
