import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import {
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Label,
  Input,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Link, withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import InputMask from 'react-input-mask';

import Header from '../../../components/Header';
import HeaderTitle from '../../../components/HeaderTitle';
import API from '../../../api/index';

const NewUserForm = ({ permissionLevels, handlePostNewUser, techLevels }) => {
  const [newUser, setNewUser] = useState({
    name: '',
    username: '',
    permission_level: '',
    tech_level: '',
    email: '',
    mobile_number: '',
  });

  const handleSetNewUser = (e) => {
    e.persist();
    setNewUser((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const validationForUserParams = (params) => {
    if (
      params.name === '' ||
      params.username === '' ||
      params.permission_level === '' ||
      params.email === ''
    ) {
      return toastr.error('Please enter all the fields for the new user');
    }
  };

  return (
    <Card>
      <CardHeader tag="h4">Create a New User</CardHeader>
      <CardBody>
        <AvForm>
          <FormGroup>
            <AvField
              label="Name"
              name="name"
              value={newUser.name}
              onChange={(e) => handleSetNewUser(e)}
            />
          </FormGroup>
          <FormGroup>
            <AvField
              label="Username"
              name="username"
              value={newUser.username}
              onChange={(e) => handleSetNewUser(e)}
            />
          </FormGroup>
          <FormGroup>
            <AvField
              label="Email"
              name="email"
              value={newUser.email}
              onChange={(e) => handleSetNewUser(e)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Permission Level</Label>
            <Select
              options={permissionLevels}
              onChange={(e) =>
                setNewUser((prevState) => ({
                  ...prevState,
                  permission_level: e.value,
                  tech_level: '',
                }))
              }
              value={permissionLevels.find(
                (permission) => permission.value === newUser.permission_level
              )}
            />
          </FormGroup>
          {newUser.permission_level === 0 ? (
            <>
              <FormGroup>
                <Label>Tech Level</Label>
                <Select
                  options={techLevels}
                  onChange={(e) =>
                    setNewUser((prevState) => ({
                      ...prevState,
                      tech_level: e.value,
                    }))
                  }
                  value={techLevels.find(
                    (techLevel) => techLevel.value === newUser.tech_level
                  )}
                />
              </FormGroup>
              <FormGroup>
                <AvField
                  type="text"
                  name="mobile_number"
                  label="Mobile Number"
                  alwaysShowMask={true}
                  mask="(999) 999-9999"
                  tag={[Input, InputMask]}
                  value={newUser.mobile_number}
                  onChange={(e) => {
                    let number = e.target.value;
                    let replacedNumber = number.replace(/\(|\)|_|-|\s/gi, '');
                    setNewUser({ ...newUser, mobile_number: replacedNumber });
                  }}
                />
              </FormGroup>
            </>
          ) : null}
        </AvForm>
        <Button
          onClick={() => {
            if (validationForUserParams(newUser)) {
              return;
            }
            handlePostNewUser(newUser);
          }}
        >
          Submit
        </Button>
      </CardBody>
    </Card>
  );
};

const FormContainer = ({ history }) => {
  const [permissionLevels, setPermissionLevels] = useState([]);
  const [techLevels, setTechLevels] = useState([]);
  useEffect(() => {
    API.get('/auth/permission_levels')
      .then((res) => {
        const permissionTypes = res.data.map((x) => ({
          value: x.level,
          label: x.permissions,
        }));
        setPermissionLevels(permissionTypes);
      })
      .catch(() => toastr.error('Could not get permission levels'));

    API.get('/auth/tech_levels')
      .then((res) => setTechLevels(res.data))
      .catch(() => toastr.error('Could not get tech levels'));
  }, []);

  const handlePostNewUser = (data) => {
    if (data.permission_level == 0 && data.mobile_number == '') {
      return toastr.error('Please enter a mobile number');
    }
    data.mobile_number = `+1${data.mobile_number}`;
    if (data.permission_level !== 0) {
      data.mobile_number = '';
    }

    API.post('/user', data)
      .then(() => {
        toastr.success('Succesfully created a new user');
        history.push('/admin/users');
      })
      .catch(() => toastr.error('There was an error creating this user'));
  };
  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Create a New User</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/admin/users">Users</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Create User</BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <NewUserForm
        permissionLevels={permissionLevels}
        handlePostNewUser={handlePostNewUser}
        history={history}
        techLevels={techLevels}
      />
    </Container>
  );
};

export default withRouter(FormContainer);
