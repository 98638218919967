import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import API from '../../api';

import { Button, Card, CardBody } from 'reactstrap';

const Confirm = (props) => {
  let { uuid } = useParams();

  const [appointment, setAppointment] = useState({});

  const fetchAppointment = (uuid) => {
    API.get(`/appointments/uuid/${uuid}`)
      .then((res) => {
        setAppointment(res.data);
      })
      .catch((err) => {
        toastr.error('Could not load appointment');
      });
  };

  const confirmAppointment = (uuid) => {
    API.put(`/appointments/uuid/${uuid}/confirm`)
      .then((res) => {
        setAppointment(res.data);
      })
      .catch((err) => {
        toastr.error('Could not confirm appointment');
      });
  };

  const rejectAppointment = (uuid) => {
    if (
      window.confirm('Are you sure you would like to give up this reservation?')
    ) {
      API.put(`/appointments/uuid/${uuid}/reject`)
        .then((res) => {
          setAppointment(res.data);
        })
        .catch((err) => {
          toastr.error('Could not reject appointment');
        });
    }
  };

  const createProxyTextSession = () => {
    if (
      window.confirm(
        'This will send a text message to the mobile number associated with this appointment. Please wait up to five minutes for the message.'
      )
    ) {
      API.post(`/appointments/${uuid}/create_proxy_text_session_as_customer`)
        .then(() => {
          fetchAppointment(uuid);
          toastr.success('Sent the text message');
        })
        .catch((err) => {
          toastr.error(
            err.response.data && err.response.data.response
              ? `${err.response.data.response}`
              : `${err.response.data.errors}`
          );
        });
    }
  };

  useEffect(() => {
    fetchAppointment(uuid);
  }, [uuid]);

  const content = () => {
    if (appointment.status === 'scheduled') {
      return (
        <div className="text-center">
          <h2 style={{ marginBottom: '10px' }}>
            {moment(appointment.reservation.start_date).format(
              'dddd, MMMM Do, YYYY'
            )}
          </h2>
          <h3>
            {moment(appointment.reservation.start_date).format('h:mm a')} -{' '}
            {moment(appointment.reservation.end_date).format('h:mm a')}
          </h3>

          <Button
            style={{ marginTop: '20px', marginBottom: '20px' }}
            color="primary"
            onClick={() => confirmAppointment(appointment.uuid)}
          >
            Confirm Reservation
          </Button>

          <h5>
            If you are no longer available at the above time, please click
            below.
          </h5>
          <h6>
            Note: Once you click this button, a Natural Wireless support agent
            will need to contact you to re-schedule.
          </h6>
          <Button
            id="cancelButton"
            style={{ marginTop: '20px', marginBottom: '20px' }}
            color="outline-danger"
            onClick={() => rejectAppointment(appointment.uuid)}
          >
            Cancel Reservation
          </Button>
          <h5>
            Please note, your reservation will only be held until{' '}
            {moment(appointment.reservation.expiration).format(
              'dddd, MMMM Do h:mm a'
            )}
          </h5>
        </div>
      );
    } else if (appointment.status === 'confirmed') {
      return (
        <div className="text-center">
          <h3>Your appointment has been confirmed for</h3>
          <h2 style={{ marginBottom: '10px', marginTop: '20px' }}>
            {moment(appointment.confirmed_reservation.start_date).format(
              'dddd, MMMM Do, YYYY'
            )}
          </h2>
          <h3 style={{ marginBottom: '20px' }}>
            {moment(appointment.confirmed_reservation.start_date).format(
              'h:mm a'
            )}{' '}
            -{' '}
            {moment(appointment.confirmed_reservation.end_date).format(
              'h:mm a'
            )}
          </h3>

          {/* {moment(appointment.confirmed_reservation.start_date).isSame(
            moment(),
            'day'
          ) ? (
            (appointment && !appointment.proxy_session) ||
            (appointment.proxy_session &&
              appointment.proxy_session.is_expired) ? (
              <Button
                style={{ marginBottom: '20px' }}
                color="primary"
                onClick={() => createProxyTextSession()}
              >
                Click here to Text or Call your Natural Wireless Technician
              </Button>
            ) : (
              <>
                <h4>In order to contact your technician please call or text</h4>
                <h4 className="mb-3 mt-2">
                  {parsePhoneNumberFromString(
                    appointment.proxy_session.customer_proxy_phone_number
                  ).format('NATIONAL')}
                </h4>
              </>
            )
          ) : (
            <h4>
              Must be the same day of your appointment to get in contact with
              your technician
            </h4>
          )} */}
          <h5>
            If you are no longer available at the above time, please click
            below.
          </h5>
          <h6>
            Note: Once you click this button, a Natural Wireless support agent
            will need to contact you to re-schedule.
          </h6>
          <Button
            style={{ marginTop: '20px', marginBottom: '20px' }}
            color="outline-danger"
            onClick={() => rejectAppointment(appointment.uuid)}
          >
            I am no longer avaialble
          </Button>
        </div>
      );
    } else if (appointment.status === 'unscheduled') {
      return (
        <div className="text-center">
          This appointment is not currently scheduled, a Natural Wireless agent
          will contact you shortly to reschedule.
        </div>
      );
    } else {
      return <div className="text-center">This link has expired</div>;
    }
  };

  return (
    <>
      <div className="text-center mt-4">
        <h3>Natural Wireless Appointment</h3>
        {appointment.status === 'scheduled' && (
          <p className="lead">Please confirm your appointment</p>
        )}
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">{content()}</div>
        </CardBody>
      </Card>

      <div className="text-center mt-4">
        <p>
          Any questions? Call us at (877)-777-7937 or email us at{' '}
          <a href="mailto: support@naturalwireless.com">
            support@naturalwireless.com
          </a>
        </p>
      </div>
    </>
  );
};

export default Confirm;
