import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
  Container,
} from "reactstrap";
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import CalendarComponent from "../../../components/Calendar";

const AppointmentCalendarTech = ({ authToken }) => {
  return (
    <Container fluid>
      <Header style={{ paddingBottom: "70px" }}>
        <HeaderTitle>Tech Calendar</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem active>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Card>
        <CardHeader tag="h4">Tech Calendar</CardHeader>
        <CardBody>
          <CalendarComponent isTech={true} authToken={authToken} />
        </CardBody>
      </Card>
    </Container>
  );
};

export default AppointmentCalendarTech;
