import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";

import API from "../../../api";

const LogTable = ({ appointmentId }) => {
  const [logEntries, setLogEntries] = useState([]);

  const fetchLogEntries = (id) => {
    API.get(`/appointments/${id}}/log`)
      .then((res) => {
        setLogEntries(res.data);
      })
      .catch((err) => {
        toastr.error("Error loading log data");
      });
  };

  useEffect(() => {
    if (appointmentId) fetchLogEntries(appointmentId);
  }, [appointmentId]);

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "desc",
    },
  ];

  const tableColumns = [
    {
      dataField: "id",
      text: "Id",
      headerStyle: {
        width: "80px",
      },
    },
    {
      dataField: "timestamp",
      text: "Timestamp",
      sort: true,
      formatter: (cell, row) => moment(cell).format("MM/DD/YY h:mm a"),
    },
    {
      dataField: "event",
      text: "Event",
      sort: true,
    },
  ];

  return (
    <ToolkitProvider keyField="id" data={logEntries} columns={tableColumns}>
      {(props) => (
        <BootstrapTable
          {...props.baseProps}
          bootstrap4
          bordered={false}
          defaultSorted={defaultSorted}
          pagination={paginationFactory({
            sizePerPage: 10,
            sizePerPageList: [5, 10, 25, 50],
          })}
        />
      )}
    </ToolkitProvider>
  );
};

export default LogTable;
