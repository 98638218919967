import React from "react";

import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";
import "moment-timezone";

import { Card, CardBody, CardHeader } from "reactstrap";

const SameDayAppointmentsTable = ({ recommendedTimes = [], techList = [] }) => {
  const tableColumns = [
    {
      dataField: "date",
      text: "Date",
      headerStyle: {
        width: "12rem",
      },
      formatter: (cell, row) => {
        return moment(row.start_date).format("dddd, MMMM Do YYYY");
      },
    },

    {
      dataField: "technician_id",
      text: "Technician",
      headerStyle: {
        width: "12rem",
      },
      formatter: (cell, row) => {
        const tech = techList.find((tech) => tech.user_id === row.tech_id);
        return `${tech.name} (Level ${tech.tech_level})`;
      },
    },
  ];
  return (
    <Card>
      <CardHeader tag="h4">Techs with Appointments at the Building</CardHeader>
      <CardBody>
        <ToolkitProvider
          keyField="id"
          data={recommendedTimes}
          columns={tableColumns}
        >
          {(props) => (
            <BootstrapTable
              {...props.baseProps}
              bootstrap4
              bordered={false}
              pagination={paginationFactory({
                sizePerPage: 10,
                sizePerPageList: [5, 10, 25, 50],
              })}
            />
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  );
};

export default SameDayAppointmentsTable;
