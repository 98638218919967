import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import 'moment-timezone';
import { Link, useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import 'moment-timezone';
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  UncontrolledAlert,
} from 'reactstrap';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'react-datepicker/dist/react-datepicker.css';

import API from '../../../api/index';
import AppointmentsCalendar from '../../../components/Calendar';
import Header from '../../../components/Header';
import HeaderTitle from '../../../components/HeaderTitle';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { ButtonStatusIndicator } from '../../../components/AppointmentStatusIndicator';
import LogTable from '../Tables/Log';
// import CommentTable from "../Tables/Comment";
import CommentContainer from '../../../components/Appointments/CommentContainer';
import { getPermissionLevel } from '../../../helpers/TokenDecode';
import BuildingNotesBanner from '../../../components/BuildingNotesBanner';

/* BEGIN
 * helper methods / components
 */
const AnnotatedText = ({ annotation, children }) => (
  <div>
    <div style={{ marginTop: '10px', fontSize: '1em', color: '#919191' }}>
      {annotation}
    </div>
    <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>{children}</div>
  </div>
);
/* END
 * helper methods / components
 */

/* BEGIN
 * Page cards
 */
const CustomerInfoCard = ({ appointment, repeaterTypes }) => {
  const cust = appointment.customer; // null

  const found = repeaterTypes.find(
    (x) => x.id == appointment.accepted_repeater_deposit_type
  );
  return (
    <Card>
      <CardHeader tag="h4">Customer Details</CardHeader>
      <CardBody>
        <AnnotatedText annotation="ID">
          {cust && `${cust.account_number}`}
        </AnnotatedText>
        <AnnotatedText annotation="Name">
          {cust && `${cust.first_name} ${cust.last_name}`}
        </AnnotatedText>
        <AnnotatedText annotation="Email">
          {cust && `${cust.email}`}
        </AnnotatedText>
        <AnnotatedText annotation="Phone">
          {cust &&
          parsePhoneNumberFromString(cust.mobile) &&
          parsePhoneNumberFromString(cust.mobile).formatNational()
            ? parsePhoneNumberFromString(cust.mobile).formatNational()
            : cust && cust.mobile}
        </AnnotatedText>
        <AnnotatedText annotation="Unit">
          {cust && `${cust.apartment}`}
        </AnnotatedText>

        <AnnotatedText annotation="Building">
          {cust && (
            <>
              <div>{cust && cust.building && `${cust.building.shortdesc}`}</div>
              <div>{cust && cust.building && `${cust.building.street1}`}</div>
              <div>{cust && cust.building && `${cust.building.street2}`}</div>
            </>
          )}
        </AnnotatedText>
        {appointment && appointment.accepted_repeater_deposit ? (
          <>
            <AnnotatedText annotation="Accepted Repeater Deposit">
              {appointment.accepted_repeater_deposit ? 'Yes' : 'No'}
            </AnnotatedText>
            <AnnotatedText annotation="Type">
              {found && found.name}
            </AnnotatedText>
            <AnnotatedText annotation="Price">
              {found && `$${appointment.accepted_repeater_deposit_price}`}
            </AnnotatedText>
            <AnnotatedText annotation="Deposit Waved">
              {found && appointment.waived_repeater_deposit
                ? `Yes, Approved By: ${appointment.waived_repeater_deposit_reason}`
                : 'No'}
            </AnnotatedText>
          </>
        ) : null}
      </CardBody>
    </Card>
  );
};

const BannerMessage = ({ appointment }) => {
  const color = () => {
    switch (appointment.status) {
      case 'unscheduled':
        return 'danger';
      case 'scheduled':
        return 'warning';
      case 'confirmed':
        return 'success';
      case 'resolved':
        return 'success';
      case 'cancelled':
        return 'danger';
      default:
        return 'danger';
    }
  };

  const message = () => {
    switch (appointment.status) {
      case 'unscheduled':
        return (
          <div className="alert-message">
            This appointment needs to be <strong>scheduled</strong>
          </div>
        );
      case 'scheduled':
        return (
          <div className="alert-message">
            This appointment has{' '}
            <strong>not yet been confirmed by the customer</strong>
          </div>
        );
      case 'confirmed':
        return (
          <div className="alert-message">
            This appointment was confirmed by the customer at{' '}
            {moment(appointment.confirmed_at).format('MM/DD/YY h:mm a')}
          </div>
        );
      case 'resolved':
        return (
          <div className="alert-message">
            This appointment has been resolved with a status of{' '}
            <strong>
              {appointment.resolution && appointment.resolution.status}
            </strong>
          </div>
        );
      case 'cancelled':
        return (
          <div className="alert-message">
            <strong>This appointment has been cancelled</strong>
          </div>
        );
      default:
        return (
          <div className="alert-message">
            <strong>
              There is something wrong with this appointment status
            </strong>
          </div>
        );
    }
  };

  return (
    <UncontrolledAlert color={color()} className="alert-outline-coloured">
      <div className="alert-icon">
        <FontAwesomeIcon icon={faBell} fixedWidth />
      </div>
      {message()}
    </UncontrolledAlert>
  );
};

const AppointmentDetails = ({ appointment }) => {
  const res = appointment.reservation || appointment.confirmed_reservation;
  const date = res && moment(res.start_date).format('dddd MMMM Do, YYYY');

  return (
    <Card>
      <CardHeader tag="h4">Appointment Details</CardHeader>
      <CardBody>
        <AnnotatedText annotation="Status">
          <ButtonStatusIndicator appointment={appointment} />
        </AnnotatedText>
        <AnnotatedText annotation="Ticket Number">
          {appointment.ticket_number || 'No ticket number found'}
        </AnnotatedText>
        <AnnotatedText annotation="Date">{date}</AnnotatedText>
        <AnnotatedText annotation="Time">
          {res &&
            `${moment(res.start_date).format('h:mm a')} - ${moment(
              res.end_date
            ).format('h:mm a')}`}
        </AnnotatedText>
        <AnnotatedText annotation="Technician">
          {res && `${res.technician.name}`}
        </AnnotatedText>
      </CardBody>
    </Card>
  );
};

const TechnicalDetails = ({ appointment }) => {
  const cust = appointment.customer;
  const problem = appointment.problem;

  return (
    <Card>
      <CardHeader tag="h4">Technical Details</CardHeader>
      <CardBody>
        <AnnotatedText annotation="Speed">
          {cust && `${cust.purchase_speed} Mbps /  ${cust.purchase_speed} Mbps`}
        </AnnotatedText>
        <AnnotatedText annotation="Problem">
          {problem && `${problem.name}`}
        </AnnotatedText>
        <AnnotatedText annotation="Activation Key">
          {cust ? cust.activation_key : null}
        </AnnotatedText>
        <AnnotatedText annotation="Description">
          {problem && problem.description ? `${problem.description}` : 'None'}
        </AnnotatedText>
      </CardBody>
    </Card>
  );
};

const ResolutionDetails = ({
  appointment,
  routerTypes,
  handleResolutionEditClick,
}) => {
  const resolution = appointment.resolution;

  const statusFunction = (status) => {
    if (status === 'success') {
      return (
        <Button color="outline-success" disabled>
          Success
        </Button>
      );
    } else {
      return (
        <Button color="outline-danger" disabled>
          Failed
        </Button>
      );
    }
  };

  const [showResEdit, setShowResEdit] = useState(true);

  useEffect(() => {
    const today = new Date().setHours(0, 0, 0, 0);
    let start_date = new Date().setHours(0, 0, 0, 0);
    if (appointment && appointment.confirmed_reservation) {
      start_date = new Date(
        appointment.confirmed_reservation.start_date
      ).setHours(0, 0, 0, 0);
    }

    if (start_date !== today) {
      setShowResEdit(false);
    }
  }, [appointment]);

  return (
    <Card>
      <Row>
        <Col md="8">
          <CardHeader tag="h4">Resolution Details</CardHeader>
        </Col>
        {showResEdit ? (
          <Col>
            <Button
              className="float-right mt-3 mr-3"
              onClick={() => handleResolutionEditClick()}
            >
              Edit
            </Button>
          </Col>
        ) : null}
      </Row>

      <CardBody>
        <AnnotatedText annotation="Checked in at">
          {resolution &&
            `${
              resolution.check_in === null
                ? 'Never'
                : moment(resolution.check_in).format('dddd, M/D h:mm A')
            }`}
        </AnnotatedText>

        <AnnotatedText annotation="Checked out at">
          {resolution &&
            `${
              resolution.check_out === null
                ? 'Never'
                : moment(resolution.check_out).format('dddd, M/D h:mm A')
            }`}
        </AnnotatedText>

        <AnnotatedText annotation="Customer Wired Connection">
          {resolution &&
            `${
              resolution.customer_speed_wired_up === null
                ? 'None'
                : resolution.customer_speed_wired_up
            }`}{' '}
          /{' '}
          {resolution &&
            `${
              resolution.customer_speed_wired_down === null
                ? 'None'
                : resolution.customer_speed_wired_down
            }`}{' '}
          Mbps
        </AnnotatedText>

        <AnnotatedText annotation="Customer Wireless Connection">
          {resolution &&
            `${
              resolution.customer_speed_wireless_up === null
                ? 'None'
                : resolution.customer_speed_wireless_up
            }`}{' '}
          /{' '}
          {resolution &&
            `${
              resolution.customer_speed_wireless_down === null
                ? 'None'
                : resolution.customer_speed_wireless_down
            }`}{' '}
          Mbps
        </AnnotatedText>

        <AnnotatedText annotation="Was new AP installed?">
          {resolution && resolution.was_new_ap_installed ? 'Yes' : 'No'}
        </AnnotatedText>
        {resolution && resolution.was_new_ap_installed ? (
          <>
            <AnnotatedText annotation="AP Mac Address">
              {resolution.ap_mac_address}
            </AnnotatedText>
            <AnnotatedText annotation="IP Address">
              {resolution.ip_address}
            </AnnotatedText>
            <AnnotatedText annotation="VLAN">{resolution.vlan}</AnnotatedText>{' '}
          </>
        ) : null}

        <AnnotatedText annotation="Was new router installed?">
          {resolution && resolution.was_router_installed ? 'Yes' : 'No'}
        </AnnotatedText>

        {resolution && resolution.was_router_installed ? (
          <>
            {' '}
            <AnnotatedText annotation="Router MAC Address">
              {resolution.router_mac_address}
            </AnnotatedText>
            <AnnotatedText annotation="Router SSID">
              {resolution.ssid}
            </AnnotatedText>
            <AnnotatedText annotation="Router WiFi Password">
              {resolution.wifi_password}
            </AnnotatedText>{' '}
            <AnnotatedText annotation="Router Type">
              {routerTypes.name}
            </AnnotatedText>
          </>
        ) : null}

        <AnnotatedText annotation="Status">
          {resolution && resolution.status
            ? statusFunction(resolution.status)
            : null}
          {resolution && resolution.is_speed_adequate ? (
            <Button className="ml-3" disabled color="outline-success">
              Speed Adequate
            </Button>
          ) : (
            <Button disabled color="outline-danger" className="ml-3">
              Speed Inadequate
            </Button>
          )}
        </AnnotatedText>

        <AnnotatedText annotation="Description">
          {resolution && `${resolution.description}`}
        </AnnotatedText>
      </CardBody>
    </Card>
  );
};

const Calendar = ({ appointment, authToken }) => {
  const permissionLevel = getPermissionLevel(authToken);
  let isTech;
  if (permissionLevel !== 10) {
    isTech = true;
  } else {
    isTech = false;
  }

  return (
    <Card>
      <CardHeader tag="h4">Calendar</CardHeader>
      <CardBody>
        <AppointmentsCalendar
          focusAppointment={appointment.id}
          isTech={isTech}
          authToken={authToken}
        />
      </CardBody>
    </Card>
  );
};

const Log = ({ appointment, history }) => {
  const handleViewAll = () => {
    history.push(`/appointments/view/${appointment.id}/logs`);
  };
  return (
    <Card>
      <CardHeader>
        <div className="float-left">
          <h4>Logs</h4>
        </div>
        <div className="float-right">
          <Button onClick={handleViewAll}>View All</Button>
        </div>
      </CardHeader>
      <CardBody>
        <LogTable appointmentId={appointment.id} />
      </CardBody>
    </Card>
  );
};

/* END
 * Page cards
 */

/* BEGIN
 * Main exported component
 */
const AppointmentView = ({ history, authToken }) => {
  let { id } = useParams();
  const [appointment, setAppointment] = useState({});
  const [routerTypes, setRouterTypes] = useState([]);
  const [buildingNotes, setBuildingNotes] = useState({ notes: '' });

  const handleEditButtonClick = (id) => {
    history.push(`/appointments/edit/${id}`);
  };

  const handleResolveButtonClick = (id) => {
    history.push(`/appointments/resolve/${id}`);
  };

  const handleCancelButtonClick = (id) => {
    if (window.confirm('Are you sure you want to cancel this appointment?')) {
      API.delete(`/appointments/${id}`)
        .then((res) => getSingleAppointment(id))
        .catch((err) => toastr.error('Could not delete this appointment'));
    }
  };

  const handleResolutionEditClick = () => {
    history.push(`/resolutions/edit/${appointment.resolution.id}`);
  };

  const handleConfirmButtonClick = (id) => {
    API.put(`/appointments/${id}/confirm`)
      .then((res) => {
        getSingleAppointment(id);
        toastr.success('Successfully confirmed appointment');
      })
      .catch((err) => {
        toastr.error(err.response.data.errors);
      });
  };

  const getSingleAppointment = (id) => {
    API.get(`/appointments/${id}`)
      .then((res) => {
        setAppointment(res.data);
      })
      .catch((err) => {
        toastr.error('Error loading appointment data');
      });
  };

  const getSpecificRouters = (data) => {
    API.get('/api/specific_router_types', {
      params: {
        router_type: data,
      },
    }).then((res) => {
      setRouterTypes(res.data[0]);
    });
  };

  const [repeaterTypes, setRepeaterTypes] = useState([]);
  const getRepeaterTypes = () => {
    API.get('/api/repeater_types')
      .then((res) => setRepeaterTypes(res.data))
      .catch((err) => toastr.error(err.response.data.errors));
  };

  useEffect(() => {
    getSingleAppointment(id);
    getRepeaterTypes();
  }, [id]);

  useEffect(() => {
    {
      if (
        appointment &&
        appointment.customer &&
        appointment.customer.building &&
        appointment.customer.building.id !== ''
      )
        API.get(`/buildings/${appointment.customer.building.id}`).then(
          (res) => {
            setBuildingNotes({ notes: res.data.building.notes.body });
          }
        );
    }
  }, [appointment]);

  useEffect(() => {
    if (appointment.resolution) {
      getSpecificRouters(appointment.resolution.router_type);
    }
  }, [appointment]);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>View Appointment</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/appointments">Appointments</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>View appointment</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row className="pt-5">
        <Col md={12} className="pt-4 pb-4">
          <Button
            onClick={() => handleResolveButtonClick(id)}
            className="float-right"
            color="primary"
            hidden={appointment.resolution}
          >
            Resolve
          </Button>
          <Button
            hidden={appointment.resolution}
            onClick={() => handleEditButtonClick(id)}
            color="primary"
            className="float-right mr-2"
          >
            Reschedule
          </Button>

          {appointment.status === 'resolved' ? null : (
            <Button
              onClick={() => handleEditButtonClick(id)}
              className="float-right mr-2"
              color="primary"
            >
              Edit
            </Button>
          )}

          {appointment.status == 'scheduled' && (
            <Button
              hidden={appointment.resolution}
              onClick={() => handleConfirmButtonClick(id)}
              color="outline-primary"
              className="float-right mr-2"
            >
              Manually Confirm
            </Button>
          )}
          {appointment.status === 'cancelled' ||
          appointment.status === 'resolved' ? null : (
            <Button
              onClick={() => handleCancelButtonClick(id)}
              color="outline-danger"
              className="float-right mr-2"
            >
              Cancel
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <BannerMessage appointment={appointment} />
        </Col>
      </Row>
      {buildingNotes.notes !== null && buildingNotes.notes !== '' ? (
        <BuildingNotesBanner building={buildingNotes} />
      ) : null}
      <Row>
        <Col>
          <CustomerInfoCard
            appointment={appointment}
            repeaterTypes={repeaterTypes}
          />
        </Col>
        <Col>
          <TechnicalDetails appointment={appointment} />
        </Col>
        <Col>
          <AppointmentDetails appointment={appointment} />
        </Col>
        {appointment.resolution ? (
          <Col>
            <ResolutionDetails
              appointment={appointment}
              routerTypes={routerTypes}
              handleResolutionEditClick={handleResolutionEditClick}
            />
          </Col>
        ) : null}
      </Row>

      <Row>
        <Col md={12}>
          <Calendar authToken={authToken} appointment={appointment} />
        </Col>
      </Row>
      <Row>
        <Col>
          <CommentContainer
            appointment={appointment}
            appointmentId={appointment.id}
            repullAppointment={getSingleAppointment}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Log appointment={appointment} history={history} />
        </Col>
      </Row>
    </Container>
  );
};
/* END
 * Main exported component
 */

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    authToken: state.auth.authToken,
    refreshToken: state.auth.refreshToken,
  };
};

export default withRouter(connect(mapStateToProps)(AppointmentView));
