import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { toastr } from "react-redux-toastr";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import debounce from "../../../../components/UseDebounce";
import { withRouter } from "react-router-dom";

import API from "../../../../api";

const { SearchBar } = Search;

const AppointmentsUnscheduledTable = ({ start_date, end_date, history }) => {
  const [data, setData] = useState({
    entries: [],
    total_entries: 0,
  });
  const [tableState, setTableState] = useState({
    page: 1,
    searchText: "",
    sizePerPage: 5,
    sortField: null,
    sortOrder: null,
  });

  const handleScheduleClick = (id) => {
    history.push(`/appointments/edit/${id}`);
  };

  const fetchData = ({ tableState, data, setData, start_date, end_date }) => {
    const params = {
      page: tableState.page,
      sizePerPage: tableState.sizePerPage,
      searchText: tableState.searchText,
      status: "unscheduled",
    };
    /*
    if (start_date) {
      params.start_date = moment(start_date)
        .startOf("day")
        .format("YYYY/M/D H:mm");
    }
    if (end_date) {
      params.end_date = moment(end_date)
        .endOf("day")
        .format("YYYY/M/D H:mm");
    }
*/
    API.get("/appointments/list_paginated", { params: params })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        toastr.error("Error loading appointments");
      });
  };
  const debouncedFetchData = React.useCallback(debounce(fetchData, 300), []);

  useEffect(() => {
    debouncedFetchData({ tableState, data, setData, start_date, end_date });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableState, start_date, end_date]);

  const tableColumns = [
    {
      dataField: "customer.first_name",
      text: "Customer",
      sort: false,
      formatter: (cell, row) =>
        `${row.customer.first_name} ${row.customer.last_name}`,
    },
    {
      dataField: "customer.building.shortdesc",
      text: "Building",
      sort: false,
      formatter: (cell, row) => `${row.customer.building.shortdesc}`,
    },
    {
      dataField: "customer.apartment",
      text: "Unit",
      sort: false,
      formatter: (cell, row) => `${row.customer.apartment}`,
    },
    {
      dataField: "actions",
      formatter: (cell, row) => (
        <React.Fragment>
          <Button
            onClick={() => handleScheduleClick(row.id)}
            color={"outline-primary"}
          >
            Schedule
          </Button>
        </React.Fragment>
      ),
      text: "Actions",
      sort: false,
    },
  ];

  const handleTableChange = (type, newState) => {
    setTableState(newState);
  };

  return (
    <ToolkitProvider
      keyField="id"
      data={data.entries}
      columns={tableColumns}
      search
    >
      {(props) => (
        <React.Fragment>
          <div style={{ float: "right" }}>
            <SearchBar {...props.searchProps} />
          </div>

          <BootstrapTable
            {...props.baseProps}
            remote={{
              filter: true,
              pagination: true,
              sort: true,
              cellEdit: true,
            }}
            onTableChange={handleTableChange}
            bootstrap4
            bordered={false}
            pagination={paginationFactory({
              page: tableState.page,
              sizePerPage: tableState.sizePerPage,
              totalSize: data.total_entries,
              sizePerPageList: [5, 10, 25, 50],
            })}
          />
        </React.Fragment>
      )}
    </ToolkitProvider>
  );
};

export default withRouter(AppointmentsUnscheduledTable);
