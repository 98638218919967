import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {
  getPermissionLevel,
  getUserID,
  getName,
  decodeToken,
} from "../helpers/TokenDecode";

import { toggleSidebar } from "../redux/actions/sidebarActions";
import { authLogout } from "../redux/actions/authActions";

import Search from "../components/Search";

import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";

const NavbarToggle = connect((store) => ({
  app: store.app,
}))(({ dispatch }) => {
  return (
    <span
      className="sidebar-toggle d-flex mr-2"
      onClick={() => {
        dispatch(toggleSidebar());
      }}
    >
      <i className="hamburger align-self-center" />
    </span>
  );
});

const NavbarDropdowns = connect((store) => ({
  sidebar: store.sidebar,
  authToken: store.authToken,
}))(({ sidebar, authToken, name, permissionLevel, ...props }) => {
  return (
    <Collapse navbar>
      <Nav className={!sidebar.isOnRight ? "ml-auto" : "mr-auto"} navbar>
        <UncontrolledDropdown nav inNavbar className="ml-lg-1">
          <DropdownToggle nav caret>
            <FontAwesomeIcon icon={faCog} className="align-middle" />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem onClick={props.handleLogout}>
              <FontAwesomeIcon
                icon={faArrowAltCircleRight}
                fixedWidth
                className="mr-2 align-middle"
              />
              Sign out
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                props.history.push(
                  `/auth/users/${props.user_id}/change_password`
                );
              }}
            >
              Change Password
            </DropdownItem>
            {permissionLevel === 0 ? (
              <DropdownItem
                onClick={() => {
                  props.history.push(
                    `/auth/users/${props.user_id}/change_phone_number`
                  );
                }}
              >
                Change Phone Number
              </DropdownItem>
            ) : null}
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Collapse>
  );
});

const NavbarComponent = ({ sidebar, authToken, ...props }) => {
  // const handleSearch = (query) => {
  //   if (query === "") props.history.push(`/assets/`);
  //   else props.history.push(`/assets/search/${query}`);
  // };

  const userlevel = () => {
    const user = decodeToken(authToken);

    switch (user.permission_level) {
      case 0:
        return `Tech Level ${user.tech_level}`;
      case 1:
        return "Support";
      case 10:
        return "Admin";
      default:
        return "None";
    }
  };

  return (
    <Navbar expand className="navbar-theme">
      {!sidebar.isOnRight ? (
        <React.Fragment>
          <NavbarToggle />
          {getPermissionLevel(authToken) !== 0 && (
            <Col md={6}>
              <Search />
            </Col>
          )}
          <Col md={getPermissionLevel(authToken) !== 0 ? 5 : 11}>
            <h4 className="float-right text-white">
              Welcome, {getName(authToken)} ({userlevel()})
            </h4>
          </Col>

          <NavbarDropdowns
            permissionLevel={getPermissionLevel(authToken)}
            user_id={getUserID(authToken)}
            name={getName(authToken)}
            {...props}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <NavbarDropdowns {...props} />
          <Col md={11}>
            <h4 className="float-right text-white">
              Welcome, {getName(authToken)}
            </h4>
          </Col>
          <NavbarToggle />
        </React.Fragment>
      )}
    </Navbar>
  );
};

const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
  authToken: state.auth.authToken,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleLogout: authLogout,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavbarComponent)
);
