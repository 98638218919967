import React from "react";

import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";
import "moment-timezone";

import { Card, CardBody, CardHeader, Button, Row, Col } from "reactstrap";

const statusButtonFormatter = (cell, row) => {
  switch (row.status) {
    case "pending":
      return (
        <Button disabled={true} color="outline-secondary">
          Pending
        </Button>
      );
    case "approved":
      return (
        <Button disabled={true} color="outline-success">
          Approved
        </Button>
      );
    case "rejected":
      return (
        <Button disabled={true} color="outline-danger">
          Rejected
        </Button>
      );
    default:
      return null;
  }
};

const AvailabilityExceptions = ({
  availabilityExceptions,
  handleAcceptClick,
  handleRejectionClick,
  ...props
}) => {
  const defaultSorted = [
    {
      dataField: "date",
      order: "desc",
    },
  ];

  const tableColumns = [
    {
      dataField: "status",
      text: "Status",
      formatter: statusButtonFormatter,
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      formatter: (cell, row) => {
        const exceptionDate = new Date(row.start_date);
        return exceptionDate.toDateString();
      },
      sortValue: (cell, row) => row.start_date,
    },
    {
      dataField: "start_date",
      text: "Start Time",
      sort: true,

      formatter: (cell, row) => {
        return moment(cell).format("h:mm A");
      },
    },
    {
      dataField: "end_date",
      text: "End Time",
      sort: true,
      formatter: (cell, row) => {
        return moment(cell).format("h:mm A");
      },
    },
    {
      dataField: "available",
      text: "Available?",
      formatter: (cell, row) => (row.is_available ? "Yes" : "No"),
    },
    {
      dataField: "reason",
      text: "Reason",
      formatter: (cell, row) => row.reason,
    },

    {
      dataField: "actions",
      text: "Actions",
      headerStyle: {
        width: "14rem",
      },
      // headerAlign: "center",
      formatter: (cell, row) => {
        return (
          <Row>
            {row.status === "approved" ? null : (
              <Col>
                <Button
                  onClick={() => handleAcceptClick(row.id)}
                  color="outline-success"
                >
                  Accept
                </Button>
              </Col>
            )}

            {row.status === "rejected" ? null : (
              <Col>
                <Button
                  onClick={() => handleRejectionClick(row.id)}
                  color="outline-danger"
                >
                  Reject
                </Button>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];
  return (
    <>
      <Card>
        <CardHeader tag="h4">
          <Row>
            <Col>Availability Exceptions View</Col>
            <Col>
              <Button className="float-right" onClick={props.openException}>
                New Exception
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <ToolkitProvider
            keyField="id"
            data={availabilityExceptions}
            columns={tableColumns}
          >
            {(props) => (
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                bordered={false}
                defaultSorted={defaultSorted}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
              />
            )}
          </ToolkitProvider>
        </CardBody>
      </Card>
    </>
  );
};

export default AvailabilityExceptions;
