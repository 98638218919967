import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { getName } from "../../../helpers/TokenDecode";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";

import API from "../../../api";

import UnscheduledTable from "./Tables/Unscheduled";
import UnconfirmedTable from "./Tables/Unconfirmed";
import TodayTable from "./Tables/Today";
import TableOfTechs from "../../../components/Availabilities/TableOfTechs";

import ReactApexChart from "react-apexcharts";
import moment from "moment";
import "moment-timezone";
import { toastr } from "react-redux-toastr";

const TodaysAppointments = ({ start_date, end_date }) => {
  return (
    <Card>
      <CardHeader tag="h4">All Scheduled Appointments</CardHeader>
      <CardBody>
        <TodayTable start_date={start_date} end_date={end_date} />
      </CardBody>
    </Card>
  );
};

const UnconfirmedAppointments = ({ start_date, end_date }) => {
  return (
    <Card>
      <CardHeader tag="h4">Unconfirmed Appointments</CardHeader>
      <CardBody>
        <UnconfirmedTable start_date={start_date} end_date={end_date} />
      </CardBody>
    </Card>
  );
};

const UnscheduledAppointments = ({ appointments, start_date, end_date }) => {
  return (
    <Card>
      <CardHeader tag="h4">Unscheduled Appointments</CardHeader>
      <CardBody>
        <UnscheduledTable start_date={start_date} end_date={end_date} />
      </CardBody>
    </Card>
  );
};

const PieChart = ({ pieState }) => {
  return (
    <Card>
      <CardHeader tag="h4">Appointments</CardHeader>
      <CardBody>
        <div id="chart">
          <ReactApexChart
            type="pie"
            options={pieState.options}
            series={pieState.series}
          />
        </div>
      </CardBody>
    </Card>
  );
};

const Dashboard = ({ isAuthenticated, authToken, history, ...props }) => {
  const [startDate, setStartDate] = useState(moment().startOf("week"));
  const [endDate, setEndDate] = useState(moment().endOf("week"));

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [pieState, setPieState] = useState({
    series: [],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Scheduled", "Confirmed", "Resolved"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const [listOfTechs, setListOfTechs] = useState([]);

  const handleCreateClick = () => {
    history.push("/appointments/create");
  };

  const handleViewClick = (id) => {
    history.push(`/availabilities/${id}`);
  };

  useEffect(() => {
    API.get("/users")
      .then((res) => {
        const mappedTechs = [];
        res.data.forEach((user) => {
          if (
            user.permission_level === 0 &&
            user.pending_exceptions !== 0 &&
            user.is_active
          ) {
            user.value = user.user_id;
            user.label = user.username;
            mappedTechs.push(user);
          }
        });
        setListOfTechs(mappedTechs);
      })
      .catch((err) => toastr.error("Problem getting users"));
  }, []);

  useEffect(() => {
    const params = {
      start_date: moment(startDate)
        .startOf("day")
        .format("DD/MM/YYYY H:mm"),
      end_date: moment(endDate)
        .endOf("day")
        .format("DD/MM/YYYY H:mm"),
    };
    API.get("/appointments/pie_chart_data", { params })
      .then((res) => {
        const apts = res.data;

        setPieState((prevState) => ({
          ...prevState,
          series: [apts.scheduled, apts.confirmed, apts.resolved],
        }));
      })
      .catch((err) => toastr.error("Error getting pie chart data"));
  }, [startDate, endDate]);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Welcome, {getName(authToken)}</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem active>
            <Link to="/dashboard">Dashboard</Link>
          </BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row className="pt-5">
        <Col className="pt-4 pb-4 fl" md="6">
          <h3 className="float-right">
            Data from {moment(startDate).format("dddd, M/D/YYYY")} to{" "}
            {moment(endDate).format("dddd, M/D/YYYY")}
          </h3>
        </Col>

        <Col className="pt-4 pb-4">
          <Button onClick={handleCreateClick} className="float-right ml-4">
            Create New Appointment
          </Button>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggle}
            direction="left"
            className="float-right"
          >
            <DropdownToggle>Change Date</DropdownToggle>
            <DropdownMenu left>
              <DropdownItem header disabled>
                Dates
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => {
                  setStartDate(
                    new Date(new Date().setDate(new Date().getDate()))
                  );
                  setEndDate(
                    new Date(new Date().setDate(new Date().getDate()))
                  );
                }}
              >
                Today
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setStartDate(moment().startOf("week"));
                  setEndDate(moment().endOf("week"));
                }}
              >
                This Week
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setStartDate(moment().startOf("month"));
                  setEndDate(moment().endOf("month"));
                }}
              >
                This Month
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => {
                  setStartDate(
                    moment()
                      .subtract(1, "weeks")
                      .startOf("week")
                  );

                  setEndDate(
                    moment()
                      .subtract(1, "weeks")
                      .endOf("week")
                  );
                }}
              >
                Last Week
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  const lastMonth = new Date().getMonth() - 1;
                  const currentYear = new Date().getFullYear();
                  const firstOfLastMonth = new Date(currentYear, lastMonth, 1);
                  const endOfLastMonth = new Date(
                    currentYear,
                    new Date().getMonth(),
                    1 - 1
                  );
                  setStartDate(firstOfLastMonth);
                  setEndDate(endOfLastMonth);
                }}
              >
                Last Month
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => {
                  setStartDate(
                    moment()
                      .add(1, "weeks")
                      .startOf("week")
                  );
                  setEndDate(
                    moment()
                      .add(1, "weeks")
                      .endOf("week")
                  );
                }}
              >
                Next Week
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  const nextMonth = new Date().getMonth() + 1;
                  const currentYear = new Date().getFullYear();
                  const firstOfNextMonth = new Date(currentYear, nextMonth, 1);
                  const endOfNextMonth = new Date(
                    currentYear,
                    new Date().getMonth() + 2,
                    1 - 1
                  );
                  setStartDate(firstOfNextMonth);
                  setEndDate(endOfNextMonth);
                }}
              >
                Next Month
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => {
                  setStartDate(moment().startOf("week"));
                  setEndDate(moment().endOf("week"));
                }}
              >
                Reset
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <UnscheduledAppointments start_date={startDate} end_date={endDate} />
        </Col>
        <Col md={6}>
          <TodaysAppointments start_date={startDate} end_date={endDate} />
        </Col>
        <Col md={6}>
          <Row>
            <Col md={12}>
              <UnconfirmedAppointments
                start_date={startDate}
                end_date={endDate}
              />
            </Col>

            {listOfTechs.length > 0 ? (
              <Col md={12}>
                <Card>
                  <CardHeader tag="h4">Pending Exceptions</CardHeader>
                  <CardBody>
                    <TableOfTechs
                      users={listOfTechs}
                      handleViewClick={handleViewClick}
                    />
                  </CardBody>
                </Card>
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col md={6}>
          <PieChart pieState={pieState} />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    authToken: state.auth.authToken,
  };
};

export default withRouter(connect(mapStateToProps)(Dashboard));
