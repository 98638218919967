import React from "react";
import { Button } from "reactstrap";

export const ButtonStatusIndicator = ({ appointment, ...props }) => {
  switch (appointment.status) {
    case "created":
      return (
        <Button
          style={{ width: "7rem" }}
          disabled={true}
          color="outline-primary"
          {...props}
        >
          Created
        </Button>
      );
    case "unscheduled":
      return (
        <Button
          style={{ width: "7rem" }}
          disabled={true}
          color="outline-danger"
          {...props}
        >
          Unscheduled
        </Button>
      );
    case "scheduled":
      return (
        <Button
          style={{ width: "7rem" }}
          disabled={true}
          color="outline-warning"
          {...props}
        >
          Scheduled
        </Button>
      );
    case "confirmed":
      return (
        <Button
          style={{ width: "7rem" }}
          disabled={true}
          color="outline-success"
          {...props}
        >
          Confirmed
        </Button>
      );
    case "resolved":
      if (
        appointment.resolution &&
        appointment.resolution.status === "success"
      ) {
        return (
          <Button
            style={{ width: "7rem" }}
            disabled={true}
            color="outline-info"
            {...props}
          >
            Resolved
          </Button>
        );
      } else {
        return (
          <Button
            style={{ width: "7rem" }}
            disabled={true}
            color="outline-danger"
            {...props}
          >
            Resolved
          </Button>
        );
      }
    case "cancelled":
      return (
        <Button
          style={{ width: "7rem" }}
          disabled={true}
          color="danger"
          {...props}
        >
          Cancelled
        </Button>
      );
    default:
      return null;
  }
};

export const calendarStatusColor = (appointment) => {
  switch (appointment.status) {
    case "scheduled":
      return "#48D1CC";
    case "confirmed":
      return "#EEE8AA";
    case "resolved":
      return "#9370DB";
    default:
      return "#b2b2b2";
  }
};
