import React, { useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Button,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";

import CommentTable from "../../pages/Appointments/Tables/Comment";
import API from "../../api/index";
import { toastr } from "react-redux-toastr";

const NewCommentCard = ({ handleNewCommentPost, handleSetIsCommenting }) => {
  const [message, setMessage] = useState("");

  return (
    <Card>
      <CardHeader tag="h4">
        <span>New Comment</span>
        <span className="float-right">
          <Button close onClick={() => handleSetIsCommenting(false)} />
        </span>
      </CardHeader>
      <CardBody>
        <Form>
          <FormGroup>
            <Label>New Comment</Label>
            <Input
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              type="textarea"
            />
          </FormGroup>
        </Form>
        <span>
          <Button
            onClick={() => {
              if (message !== "") {
                handleNewCommentPost(message);
                setMessage("");
              } else {
                return toastr.error("Please enter a valid comment");
              }
            }}
          >
            Submit Comment
          </Button>
        </span>
        <span className="float-right">
          <Button onClick={() => setMessage("")}>Clear</Button>
        </span>
      </CardBody>
    </Card>
  );
};

const CommentView = ({ appointment, handleSetIsCommenting }) => {
  return (
    <Card>
      <CardHeader tag="h4">
        <span>Comments</span>
        <Button
          onClick={() => {
            handleSetIsCommenting(true);
          }}
          className="float-right"
        >
          New Comment
        </Button>
      </CardHeader>
      <CardBody>
        <CommentTable comments={appointment.comments} />
      </CardBody>
    </Card>
  );
};

const CommentContainer = ({
  appointment,
  appointmentId,
  repullAppointment,
}) => {
  const [isCommenting, setIsCommenting] = useState(false);
  const handleSetIsCommenting = (bool) => {
    setIsCommenting(bool);
  };

  const handleNewCommentPost = (data) => {
    API.post(`/appointments/${appointmentId}/comment`, { message: data })
      .then((res) => {
        toastr.success("Comment posted successfully");
        setIsCommenting(false);
        repullAppointment(appointmentId);
      })
      .catch((err) => toastr.error("Could not create new comment"));
  };
  return (
    <Row>
      <Col>
        <CommentView
          handleSetIsCommenting={handleSetIsCommenting}
          appointment={appointment}
        />
      </Col>
      {isCommenting ? (
        <Col>
          <NewCommentCard
            handleSetIsCommenting={handleSetIsCommenting}
            handleNewCommentPost={handleNewCommentPost}
            appointment={appointment}
          />
        </Col>
      ) : null}
    </Row>
  );
};

export default CommentContainer;
