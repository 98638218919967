import React from "react"

const AnnotatedText = ({ annotation, children }) => (
    <div>
        <div style={{ marginTop: "10px", fontSize: "1em", color: "#919191" }}>
            {annotation}
        </div>
        <div style={{ fontWeight: "bold", marginBottom: "10px" }}>{children}</div>
    </div>
);

export default AnnotatedText