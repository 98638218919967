import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  Button,
} from "reactstrap";
import DatePicker from "react-datepicker";

import moment from "moment";
import "moment-timezone";
import AnnotatedText from "./../AnnotatedText";

const QtyApResults = ({
  numberOfApInstalled,
  formData,
  numberOfRouterInstalled,
  getEquipemntQtyInstalledDownload,
}) => {
  return (
    <Card>
      <CardHeader tag="h4">Results</CardHeader>
      <CardBody>
        <AnnotatedText annotation="Start Date">
          {moment(formData.start_date).format("M/D/YYYY")}
        </AnnotatedText>
        <AnnotatedText annotation="End Date">
          {moment(formData.end_date).format("M/D/YYYY")}
        </AnnotatedText>
        <AnnotatedText annotation="Number of Ap's Installed">
          {numberOfApInstalled}
        </AnnotatedText>
        <AnnotatedText annotation="Number of Routers Installed">
          {numberOfRouterInstalled}
        </AnnotatedText>
        <Button
          onClick={() => {
            const params = {
              start_date: moment(formData.start_date)
                .startOf("day")
                .format("D/M/YYYY HH:mm"),
              end_date: moment(formData.end_date)
                .endOf("day")
                .format("D/M/YYYY HH:mm"),
            };
            getEquipemntQtyInstalledDownload({ params });
          }}
        >
          Download
        </Button>
      </CardBody>
    </Card>
  );
};

const QtyApInstalledContainer = ({
  getQtyOfApInstalled,
  haveQtyResults,
  numberOfApInstalled,
  numberOfRouterInstalled,
  getEquipemntQtyInstalledDownload,
}) => {
  const [formData, setFormData] = useState({
    start_date: "",
    end_date: "",
  });
  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardHeader tag="h4">Quantity of Equipment Installed</CardHeader>

            <CardBody>
              <Form>
                <Row>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <Label>Start Date</Label>
                        <DatePicker
                          className="form-control"
                          selected={formData.start_date}
                          value={formData.start_date}
                          onChange={(e) => {
                            setFormData((prevState) => ({
                              ...prevState,
                              start_date: e,
                            }));
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <Label>End Date</Label>
                        <DatePicker
                          className="form-control"
                          selected={formData.end_date}
                          value={formData.end_date}
                          onChange={(e) => {
                            setFormData((prevState) => ({
                              ...prevState,
                              end_date: e,
                            }));
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Button
                  onClick={() => {
                    const params = {
                      start_date: moment(formData.start_date)
                        .startOf("day")
                        .format("D/M/YYYY HH:mm"),
                      end_date: moment(formData.end_date)
                        .endOf("day")
                        .format("D/M/YYYY HH:mm"),
                    };
                    getQtyOfApInstalled({ params });
                  }}
                >
                  Submit
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
        {haveQtyResults ? (
          <Col>
            <QtyApResults
              numberOfApInstalled={numberOfApInstalled}
              formData={formData}
              numberOfRouterInstalled={numberOfRouterInstalled}
              getEquipemntQtyInstalledDownload={
                getEquipemntQtyInstalledDownload
              }
            />
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default QtyApInstalledContainer;
