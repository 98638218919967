import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  Button,
} from "reactstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import "moment-timezone";
import AnnotatedText from "./../AnnotatedText";

const ResultsAppointmentsPerBuilding = ({
  building_name,
  ApBResults,
  handleSetHaveApBResults,
  handleApBDownload,
  formData,
}) => {
  return (
    <Card>
      <CardHeader tag="h4">
        Results
        <span>
          <Button close onClick={() => handleSetHaveApBResults(false)} />
        </span>
      </CardHeader>
      <CardBody>
        <AnnotatedText annotation="Start Date">
          {moment(formData.start_date).format("M/D/YYYY")}
        </AnnotatedText>
        <AnnotatedText annotation="End Date">
          {moment(formData.end_date).format("M/D/YYYY")}
        </AnnotatedText>
        <AnnotatedText annotation="Building">
          {building_name == "" ? "All buildings" : building_name}
        </AnnotatedText>
        <AnnotatedText annotation="Number of appointments">
          {ApBResults.appointments}
        </AnnotatedText>
        <Button
          onClick={() => {
            const params = {
              start_date: moment(formData.start_date)
                .startOf("day")
                .format("D/M/YYYY HH:mm"),
              end_date: moment(formData.end_date)
                .endOf("day")
                .format("D/M/YYYY HH:mm"),
              building_id:
                formData.building_id === "" ? null : formData.building_id,
            };
            handleApBDownload({ params });
          }}
        >
          Download
        </Button>
      </CardBody>
    </Card>
  );
};

const AppointmentsPerBuildingContainer = ({
  buildingList = [],
  handleSubmitReport,
  haveApBResults,
  ApBResults,
  handleSetHaveApBResults,
  handleApBDownload,
}) => {
  const [formData, setFormData] = useState({
    start_date: "",
    end_date: "",
    building_id: "",
    building_name: "",
  });

  const newBuildingList = [{ value: "", label: "All" }, ...buildingList];

  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardHeader tag="h4">Appointments Per Building</CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <Label>Start Date</Label>
                        <DatePicker
                          className="form-control"
                          selected={formData.start_date}
                          value={formData.start_date}
                          onChange={(e) => {
                            setFormData((prevState) => ({
                              ...prevState,
                              start_date: e,
                            }));
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <Label>End Date</Label>
                        <DatePicker
                          className="form-control"
                          selected={formData.end_date}
                          value={formData.end_date}
                          onChange={(e) => {
                            setFormData((prevState) => ({
                              ...prevState,
                              end_date: e,
                            }));
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>Building</Label>
                      <Select
                        options={newBuildingList}
                        value={newBuildingList.find(
                          (building) => building.id === formData.building_id
                        )}
                        name="building_id"
                        onChange={(e) =>
                          setFormData((prevState) => ({
                            ...prevState,
                            building_id: e.value,
                            building_name: e.label,
                          }))
                        }
                        defaultValue={
                          newBuildingList[newBuildingList.length - 1]
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button
                  onClick={() => {
                    const params = {
                      start_date: moment(formData.start_date)
                        .startOf("day")
                        .format("D/M/YYYY HH:mm"),
                      end_date: moment(formData.end_date)
                        .endOf("day")
                        .format("D/M/YYYY HH:mm"),
                      building_id:
                        formData.building_id === ""
                          ? null
                          : formData.building_id,
                    };
                    handleSubmitReport({ params });
                  }}
                >
                  Submit
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
        {haveApBResults ? (
          <Col>
            <ResultsAppointmentsPerBuilding
              ApBResults={ApBResults}
              building_name={formData.building_name}
              handleSetHaveApBResults={handleSetHaveApBResults}
              handleApBDownload={handleApBDownload}
              formData={formData}
            />
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default AppointmentsPerBuildingContainer;
