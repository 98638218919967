import React, { useState, useEffect } from 'react';
import { Link, withRouter, useParams, useHistory } from 'react-router-dom';
import API from '../../../api/index';
import Header from '../../../components/Header';
import HeaderTitle from '../../../components/HeaderTitle';
import { toastr } from 'react-redux-toastr';
import TextareaAutosize from 'react-textarea-autosize';

import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

const EditBuilding = () => {
  let history = useHistory();
  let { id } = useParams();
  const [building, setBuilding] = useState({
    notes: {
      body: '',
    },
  });

  const getBuilding = () => {
    API.get(`/buildings/${id}`).then((res) => {
      if (
        res.data.building &&
        res.data.building.notes &&
        res.data.building.notes.body !== null
      ) {
        setBuilding(res.data.building);
      }
    });
  };

  const updateBuilding = () => {
    API.put(`/buildings/${id}`, { notes: building.notes })
      .then(() => {
        toastr.success('Successfully updated building notes');
        history.push(`/admin/buildings/${id}`);
      })
      .catch((err) => toastr.error(`${err.response.data.errors}`));
  };

  useEffect(() => {
    getBuilding();
  }, []);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Edit Building Notes</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/admin/buildings">Buildings</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`/admin/buildings/${id}`}>View Building</Link>
          </BreadcrumbItem>

          <BreadcrumbItem active>Edit Notes</BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Card>
        <CardHeader tag="h4">
          <div style={{ float: 'left' }}>
            Edit Building Notes
            <h6 className="card-subtitle text-muted pt-2">Edit Building</h6>
          </div>
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Label>Notes</Label>
            <Row>
              <Col md="6">
                <TextareaAutosize
                  value={building.notes.body}
                  name="building_notes"
                  cols="70"
                  type="text"
                  onChange={(e) => {
                    e.persist();
                    setBuilding((prevState) => ({
                      ...prevState,

                      notes: e.target.value,
                    }));
                  }}
                />
              </Col>
            </Row>
          </FormGroup>
          <Row>
            <Col>
              <Button onClick={updateBuilding}>Submit Notes</Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default withRouter(EditBuilding);
