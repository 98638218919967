import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux"
import importedRoutes from "./index"

import ScrollToTop from "../components/ScrollToTop";
import { getPermissionLevel } from "../helpers/TokenDecode"


const generatedRoutes = (routes, isAuthenticated, authToken) => (
  routes.map(
    ({ children, path, component: Component, layout: Layout, defaultRoute = false, secure = true, permissionLevels = [] }, idx1) => (
      children ?
        children.map(
          ({ path, component: Component, layout: Layout, defaultRoute = false, secure = true, permissionLevels = [] }, idx2) => (
            [<Route
              key={(idx1 * 100) + idx2}
              path={path}
              exact
              render={props => (
                !secure || (secure && isAuthenticated && permissionLevels.includes(getPermissionLevel(authToken))) ?
                  <Layout>
                    <Component {...props} />
                  </Layout>
                  :
                  <Redirect to={'/auth/login'} />

              )} />,
            (defaultRoute) && (
              <Route
                path={'/'}
                exact
                render={() =>
                  (<Redirect to={path} />)
                } />
            )
            ]
          )) :
        [<Route
          key={idx1 * 100}
          path={path}
          exact
          render={props => (
            !secure || (secure && isAuthenticated && permissionLevels.includes(getPermissionLevel(authToken))) ?
              <Layout>
                <Component {...props} />
              </Layout>
              :
              <Redirect to={'/auth/login'} />

          )} />,
        (defaultRoute) && (
          <Route
            path={'/'}
            exact
            render={() =>
              (<Redirect to={path} />)
            } />
        )]

    ))
)


const Routes = ({ isAuthenticated, authToken }) => (
  <Router>
    <ScrollToTop>
      <Switch>
        {generatedRoutes(importedRoutes, isAuthenticated, authToken)}
      </Switch>
    </ScrollToTop>
  </Router>

);

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    authToken: state.auth.authToken,
  };
};

export default connect(mapStateToProps)(Routes);
