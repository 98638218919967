import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  BreadcrumbItem,
  Breadcrumb,
  Container,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import UserListTable from "../../components/Admin/Users/UserListTable";
import API from "../../api/index";

const Users = () => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    API.get("/users")
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => toastr.error("Error fetching users"));
  }, []);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Users</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Users</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Card>
        <CardHeader tag="h4">
          <div style={{ float: "left" }}>
            Users
            <h6 className="card-subtitle text-muted pt-2">Listing of users</h6>
          </div>

          <div style={{ float: "right" }}>
            <Link to="/admin/users/new">
              <Button>New</Button>
            </Link>
          </div>
        </CardHeader>
        <CardBody>
          <UserListTable users={users} />
        </CardBody>
      </Card>
    </Container>
  );
};

export default Users;
