import React from "react";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";

import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

const { SearchBar } = Search;

const UserListTable = ({ users }) => {
  let history = useHistory();

  const handleEditClick = (e) => {
    history.push(`/admin/users/edit/${e.target.value}`);
  };
  const tableColumns = [
    {
      dataField: "user_id",
      text: "ID",
      sort: true,
      headerStyle: { width: "5%" },
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (cell, row) => cell,
    },
    {
      dataField: "permission_level",
      text: "Permissions",
      sort: true,
      headerStyle: { width: "11%" },
      formatter: (cell, row) => {
        switch (row.permission_level) {
          case 0:
            return "Tech";
          case 1:
            return "Support";
          case 10:
            return "Admin";
          default:
            return "None";
        }
      },
    },
    {
      dataField: "tech_level",
      text: "Tech Level",
      sort: true,
      headerStyle: { width: "10%" },
      formatter: (cell, row) => {
        if (row.tech_level > 0) {
          return cell;
        }
      },
    },
    {
      dataField: "is_active",
      text: "User Status",
      headerStyle: { width: "10%" },
      formatter: (cell, row) => {
        if (row.is_active) {
          return (
            <Button disabled color="success">
              Active
            </Button>
          );
        } else {
          return (
            <Button disabled color="danger">
              Disabled
            </Button>
          );
        }
      },
    },
    {
      dataField: "actions",
      headerStyle: { width: "10%" },
      formatter: (cell, row) => (
        <>
          <Button
            style={{ marginLeft: "10px" }}
            color={"outline-primary"}
            onClick={handleEditClick}
            value={row.user_id}
          >
            Edit
          </Button>
        </>
      ),
      text: "Actions",
      sort: false,
    },
  ];

  return (
    <>
      <ToolkitProvider keyField="id" data={users} columns={tableColumns} search>
        {(props) => (
          <React.Fragment>
            <div style={{ float: "right" }}>
              <SearchBar {...props.searchProps} />
            </div>

            <BootstrapTable
              {...props.baseProps}
              bootstrap4
              bordered={false}
              pagination={paginationFactory({
                sizePerPage: 25,
                sizePerPageList: [5, 10, 25, 50],
              })}
            />
          </React.Fragment>
        )}
      </ToolkitProvider>
    </>
  );
};

export default UserListTable;
