import React from 'react';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Label,
  FormGroup,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

const CustomerInfoForm = ({
  appointmentInfo,
  handleCustomerSearch,
  listOfCustomers,
  listOfBuildings,
  handleCustomerSelected,
  selectedBuildingObject,
  isReadOnly,
  handleCustomerInfoChange,
  hide,
  repeaterTypes = [],
  ...props
}) => {
  const handleInputOnChange = (e, name) => {
    handleCustomerInfoChange(e.target.value, name);
  };

  return (
    <Card className="h-100">
      <CardHeader tag="h4">Customer Info</CardHeader>
      <CardBody>
        <AvForm>
          {hide ? null : (
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Customer Name</Label>
                  <Select
                    options={listOfCustomers}
                    onInputChange={handleCustomerSearch}
                    autoFocus="true"
                    onChange={handleCustomerSelected}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}

          <Row>
            <Col md="6">
              <FormGroup>
                <Label>Building</Label>
                <Select
                  options={listOfBuildings}
                  value={listOfBuildings.find(
                    (building) => building.id === appointmentInfo.building
                  )}
                  name="building"
                  onChange={(e) => handleCustomerInfoChange(e.id, 'building')}
                  isDisabled={isReadOnly}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label>Ticket Number</Label>
                <AvField
                  type="number"
                  name="ticket_number"
                  value={appointmentInfo.ticket_number}
                  onChange={(e) => handleInputOnChange(e, e.target.name)}
                  disabled={isReadOnly}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>Activation Key</Label>
                <AvField
                  type="text"
                  name="activation_key"
                  value={appointmentInfo.activation_key}
                  onChange={(e) => handleInputOnChange(e, e.target.name)}
                  disabled={isReadOnly}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>First Name</Label>

                <AvField
                  required={true}
                  type="text"
                  name="first_name"
                  value={appointmentInfo.first_name}
                  onChange={(e) => handleInputOnChange(e, e.target.name)}
                  disabled={isReadOnly}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Last Name</Label>
                <AvField
                  required={true}
                  type="text"
                  name="last_name"
                  value={appointmentInfo.last_name}
                  onChange={(e) => handleInputOnChange(e, e.target.name)}
                  disabled={isReadOnly}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Email</Label>
                <AvField
                  className="form-control"
                  requried="true"
                  type="email"
                  name="email"
                  value={appointmentInfo.email}
                  onChange={(e) => handleInputOnChange(e, e.target.name)}
                  disabled={isReadOnly}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Mobile SMS</Label>
                <AvField
                  required={appointmentInfo.want_sms}
                  type="text"
                  name="mobile_sms"
                  alwaysShowMask={true}
                  mask="(999) 999-9999"
                  tag={[Input, InputMask]}
                  value={appointmentInfo.mobile_sms}
                  onChange={(e) => {
                    let number = e.target.value;
                    let replacedNumber = number.replace(/\(|\)|_|-|\s/gi, '');
                    handleCustomerInfoChange(replacedNumber, 'mobile_sms');
                  }}
                  disabled={isReadOnly}
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label>Account Number</Label>
                <AvField
                  required={true}
                  type="number"
                  name="account_number"
                  value={appointmentInfo.account_number}
                  onChange={(e) => handleInputOnChange(e, e.target.name)}
                  disabled={isReadOnly}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>Apt Number</Label>
                <AvField
                  required={true}
                  type="text"
                  name="apt"
                  value={appointmentInfo.apt}
                  onChange={(e) => handleInputOnChange(e, e.target.name)}
                  disabled={isReadOnly}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label>Purchase Speed</Label>
                <InputGroup>
                  <Input
                    required={true}
                    type="number"
                    name="purchase_speed"
                    value={appointmentInfo.purchase_speed}
                    onChange={(e) => handleInputOnChange(e, e.target.name)}
                    disabled={isReadOnly}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>Mbps</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <h6>Want SMS messages?</h6>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      defaultChecked
                      checked={appointmentInfo.want_sms}
                      type="radio"
                      name="want_sms"
                      onClick={() => handleCustomerInfoChange(true, 'want_sms')}
                      disabled={isReadOnly}
                    />
                    Yes
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      checked={!appointmentInfo.want_sms}
                      type="radio"
                      name="want_sms"
                      onClick={() =>
                        handleCustomerInfoChange(false, 'want_sms')
                      }
                      disabled={isReadOnly}
                    />
                    No
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Accepted Repeater Deposit?</Label>

                <Row>
                  <Col>
                    <BootstrapSwitchButton
                      onlabel="Yes"
                      offlabel="No"
                      size="lg"
                      onstyle="primary"
                      checked={appointmentInfo.accepted_repeater_deposit}
                      onChange={(checked) => {
                        handleCustomerInfoChange(
                          checked,
                          'accepted_repeater_deposit'
                        );
                        handleCustomerInfoChange(
                          '',
                          'accepted_repeater_deposit_type'
                        );
                        handleCustomerInfoChange(
                          0,
                          'accepted_repeater_deposit_price'
                        );
                        handleCustomerInfoChange(
                          false,
                          'waived_repeater_deposit'
                        );
                        handleCustomerInfoChange(
                          '',
                          'waived_repeater_deposit_reason'
                        );
                      }}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>

          {appointmentInfo.accepted_repeater_deposit ? (
            <>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Type of Repeater</Label>
                    <Select
                      options={repeaterTypes}
                      autoFocus="true"
                      value={repeaterTypes.find(
                        (x) =>
                          x.value ==
                          appointmentInfo.accepted_repeater_deposit_type
                      )}
                      onChange={(e) => {
                        handleCustomerInfoChange(
                          e.value,
                          'accepted_repeater_deposit_type',
                          e.price,
                          'accepted_repeater_deposit_price'
                        );
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Price of Deposit</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>$</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="number"
                        value={appointmentInfo.accepted_repeater_deposit_price}
                        onChange={(e) => {
                          handleCustomerInfoChange(
                            e.target.value,
                            'accepted_repeater_deposit_price'
                          );
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        checked={appointmentInfo.waived_repeater_deposit}
                        type="checkbox"
                        name="waived_repeater_deposit"
                        onChange={(e) => {
                          if (appointmentInfo.waived_repeater_deposit) {
                            handleCustomerInfoChange(false, e.target.name);
                            handleCustomerInfoChange(
                              '',
                              'waived_repeater_deposit_reason'
                            );
                          } else {
                            handleCustomerInfoChange(true, e.target.name);
                          }
                        }}
                      />
                      Waived Deposit Fee
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              {appointmentInfo.accepted_repeater_deposit &&
              appointmentInfo.waived_repeater_deposit ? (
                <Row>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Approved By:</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          value={appointmentInfo.waived_repeater_deposit_reason}
                          type="text"
                          name="waived_repeater_deposit_reason"
                          onChange={(e) =>
                            handleCustomerInfoChange(
                              e.target.value,
                              e.target.name
                            )
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              ) : null}
            </>
          ) : null}
        </AvForm>
      </CardBody>
    </Card>
  );
};

export default CustomerInfoForm;
