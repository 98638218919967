import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getPermissionLevel } from "../../helpers/TokenDecode"

import DashboardTech from "./Tech"
import DashboardAgent from "./Agent"

const Dashboard = ({ sidebar, authToken, isAuthenticated }) => {
  const permissionLevel = getPermissionLevel(authToken)

  const component = () => {
    switch (permissionLevel) {
      case 0:
        return <DashboardTech />
      case 1:
        return <DashboardAgent />
      case 10:
        return <DashboardAgent />
      default:
        return null
    }
  }
  return component()
}

export default withRouter(
  connect(store => ({
    sidebar: store.sidebar,
    authToken: store.auth.authToken,
    isAuthenticated: store.auth.isAuthenticated,
  }))(Dashboard)
);