import React, { useState, useEffect } from 'react';
import { Link, withRouter, useParams, useHistory } from 'react-router-dom';
import API from '../../../api/index';
import Header from '../../../components/Header';
import HeaderTitle from '../../../components/HeaderTitle';
import { toastr } from 'react-redux-toastr';
import AnnotatedText from '../../../components/AnnotatedText';

import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

const ViewBuilding = () => {
  let history = useHistory();
  let { id } = useParams();
  const [building, setBuilding] = useState({
    id: '',
    name: '',
    building: {
      notes: '',
    },
    nomadix: {},
    location: {},
  });

  const getBuilding = () => {
    API.get(`/buildings/${id}`).then((res) => {
      setBuilding(res.data);
    });
  };

  useEffect(() => {
    getBuilding();
  }, []);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>View Building</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/admin/buildings">Buildings</Link>
          </BreadcrumbItem>

          <BreadcrumbItem active>View Building</BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Card>
        <CardHeader tag="h4">
          <div style={{ float: 'left' }}>
            View Building
            <h6 className="card-subtitle text-muted pt-2">View Building</h6>
          </div>
          <Button
            className="btn btn-primary float-right"
            onClick={() => history.push(`/admin/buildings/edit/${id}`)}
          >
            Edit Notes
          </Button>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <AnnotatedText annotation="Building">
                {building.name}
              </AnnotatedText>
            </Col>
          </Row>
          <Row>
            <Col>
              <AnnotatedText annotation="Description">
                {building.location.longdesc ? building.location.longdesc : null}
              </AnnotatedText>
            </Col>
          </Row>
          <Row>
            <Col>
              <AnnotatedText annotation="Nomadix IP 1">
                {building.nomadix.nomadix_ip_1
                  ? `${building.nomadix.nomadix_ip_1}`
                  : 'None'}
              </AnnotatedText>
            </Col>
          </Row>
          <Row>
            <Col>
              <AnnotatedText annotation="Building Notes">
                <pre>
                  {building.building.notes.body
                    ? building.building.notes.body
                    : null}
                </pre>
              </AnnotatedText>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default withRouter(ViewBuilding);
