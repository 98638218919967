import React, { useState, useEffect } from "react";
import {
  Card,
  Input,
  CardBody,
  CardHeader,
  Button,
  BreadcrumbItem,
  Breadcrumb,
  Container,
  Col,
  Label,
  Row,
  FormGroup,
} from "reactstrap";
import { Link, withRouter, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import InputMask from "react-input-mask";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import API from "../../api/index";
import { AvForm, AvField } from "availity-reactstrap-validation";

const ChangePhoneNumberContainer = ({ history }) => {
  let { id } = useParams();

  const [phoneNumber, setPhoneNumber] = useState({
    phone_number: "",
    confirm_phone_number: "",
  });

  const getUserInfo = () => {
    API.get(`/users/${id}`).then((res) => {
      setPhoneNumber((prevState) => ({
        ...prevState,
        phone_number: parsePhoneNumberFromString(res.data.mobile_number)
          .nationalNumber,
        confirm_phone_number: parsePhoneNumberFromString(res.data.mobile_number)
          .nationalNumber,
      }));
    });
  };

  const handleSubmitPhoneNumber = (params) => {
    API.put(`/users/${id}/change_phone_number`, params)
      .then((res) => {
        toastr.success("Successfully changed phone number");
        history.push("/");
      })
      .catch((err) => toastr.error(`${err.response.data.errors}`));
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Change Phone Number</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/admin/users">Users</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`/admin/users/edit/${id}`}>Edit</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Change Phone Number</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <Card>
            <CardHeader tag="h4">Change your phone number</CardHeader>
            <CardBody>
              <AvForm>
                <FormGroup>
                  <Label>New Phone Number</Label>
                  <AvField
                    mask="(999) 999-9999"
                    tag={[Input, InputMask]}
                    alwaysShowMask={true}
                    value={phoneNumber.phone_number}
                    onChange={(e) => {
                      let number = e.target.value;
                      let replacedNumber = number.replace(/\(|\)|_|-|\s/gi, "");
                      setPhoneNumber({
                        ...phoneNumber,
                        phone_number: replacedNumber,
                      });
                    }}
                    name="phone_number"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Confirm Phone Number</Label>
                  <AvField
                    mask="(999) 999-9999"
                    tag={[Input, InputMask]}
                    alwaysShowMask={true}
                    value={phoneNumber.confirm_phone_number}
                    onChange={(e) => {
                      let number = e.target.value;
                      let replacedNumber = number.replace(/\(|\)|_|-|\s/gi, "");
                      setPhoneNumber({
                        ...phoneNumber,
                        confirm_phone_number: replacedNumber,
                      });
                    }}
                    name="confirm_phone_number"
                  />
                </FormGroup>
              </AvForm>
              <Button
                onClick={() =>
                  handleSubmitPhoneNumber({
                    phone_number: `+1${phoneNumber.phone_number}`,
                    confirm_phone_number: `+1${phoneNumber.confirm_phone_number}`,
                  })
                }
              >
                Submit
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    authToken: state.auth.authToken,
    refreshToken: state.auth.refreshToken,
  };
};

export default withRouter(connect(mapStateToProps)(ChangePhoneNumberContainer));
