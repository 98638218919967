import React from "react";

import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { Button } from "reactstrap";

const TableOfTechs = ({ handleViewClick, users, ...props }) => {
  const tableColumns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,

      formatter: (cell, row) => cell,
    },
    {
      dataField: "email",
      text: "Email",
      formater: (cell, row) => cell,
    },
    {
      dataField: "pending_exceptions",
      text: "Pending Exceptions",
      sort: true,
      formatter: (cell, row) => {
        let buttonColor;
        if (cell > 0) {
          buttonColor = "danger";
        } else {
          buttonColor = "success";
        }
        return <Button color={buttonColor}>{cell}</Button>;
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: (cell, row) => {
        return <Button onClick={() => handleViewClick(row.value)}>View</Button>;
      },
    },
  ];
  return (
    <ToolkitProvider keyField="id" data={users} columns={tableColumns}>
      {(props) => (
        <BootstrapTable
          {...props.baseProps}
          bootstrap4
          bordered={false}
          pagination={paginationFactory({
            sizePerPage: 10,
            sizePerPageList: [5, 10, 25, 50],
          })}
        />
      )}
    </ToolkitProvider>
  );
};

export default TableOfTechs;
