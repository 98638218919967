import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import debounce from "../../../../components/UseDebounce";
import moment from "moment";
import { ButtonStatusIndicator } from "../../../../components/AppointmentStatusIndicator";

import API from "../../../../api";

const { SearchBar } = Search;

const AppointmentsUnconfirmedTable = ({ history, start_date, end_date }) => {
  const [data, setData] = useState({
    entries: [],
    total_entries: 0,
  });
  const [tableState, setTableState] = useState({
    page: 1,
    searchText: "",
    sizePerPage: 5,
    sortField: null,
    sortOrder: null,
  });

  const fetchData = ({ tableState, data, setData, start_date, end_date }) => {
    const params = {
      page: tableState.page,
      sizePerPage: tableState.sizePerPage,
      searchText: tableState.searchText,
      sortField: "start_date",
      sortOrder: tableState.sortOrder,
    };
    if (start_date) {
      params.start_date = moment(start_date)
        .startOf("day")
        .format("YYYY/M/D H:mm");
    }
    if (end_date) {
      params.end_date = moment(end_date)
        .endOf("day")
        .format("YYYY/M/D H:mm");
    }
    API.get("/appointments/list_paginated", { params: params })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        toastr.error("Error loading appointments");
      });
  };
  const debouncedFetchData = React.useCallback(debounce(fetchData, 300), []);

  useEffect(() => {
    debouncedFetchData({ tableState, data, setData, start_date, end_date });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableState, start_date, end_date]);

  const defaultSorted = [
    {
      dataField: "date",
      order: "desc",
    },
  ];

  const tableColumns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,

      formatter: (cell, row) => {
        if (row.confirmed_reservation) {
          return moment(row.confirmed_reservation.start_date).format(
            "M/D/YYYY"
          );
        }
        if (row.reservation) {
          return moment(row.reservation.start_date).format("M/D/YYYY");
        }
      },
    },
    {
      dataField: "customer.first_name",
      text: "Customer",

      headerStyle: { width: "6rem" },
      formatter: (cell, row) =>
        `${row.customer.first_name} ${row.customer.last_name}`,
    },
    {
      dataField: "customer.building.shortdesc",
      text: "Building",

      formatter: (cell, row) => `${row.customer.building.shortdesc}`,
    },
    {
      dataField: "customer.apartment",
      text: "Unit",

      formatter: (cell, row) => `${row.customer.apartment}`,
      headerStyle: { width: "4rem" },
    },
    {
      dataField: "status",
      text: "Status",
      // headerStyle: { width: "4rem" },
      formatter: (cell, row) => {
        return <ButtonStatusIndicator appointment={row} />;
      },
    },
    {
      dataField: "actions",
      headerStyle: { width: "5rem" },
      formatter: (cell, row) => (
        <React.Fragment>
          <Button
            color={"outline-primary"}
            onClick={() => history.push(`/appointments/view/${row.id}`)}
          >
            View
          </Button>
        </React.Fragment>
      ),
      text: "Actions",
    },
  ];

  const handleTableChange = (type, newState) => {
    setTableState(newState);
  };

  return (
    <ToolkitProvider
      keyField="id"
      data={data.entries}
      columns={tableColumns}
      search={{ searchFormatted: true }}
    >
      {(props) => (
        <React.Fragment>
          <div style={{ float: "right" }}>
            <SearchBar {...props.searchProps} />
          </div>

          <BootstrapTable
            {...props.baseProps}
            remote={{
              filter: true,
              pagination: true,
              sort: true,
              cellEdit: true,
            }}
            onTableChange={handleTableChange}
            bootstrap4
            defaultSorted={defaultSorted}
            bordered={false}
            pagination={paginationFactory({
              page: tableState.page,
              sizePerPage: tableState.sizePerPage,
              totalSize: data.total_entries,
              sizePerPageList: [5, 10, 25, 50],
            })}
          />
        </React.Fragment>
      )}
    </ToolkitProvider>
  );
};

export default withRouter(AppointmentsUnconfirmedTable);
