import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";

import { Link, withRouter } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
  Container,
} from "reactstrap";

import API from "../../api/index";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import TableOfTechs from "../../components/Availabilities/TableOfTechs";
import { toastr } from "react-redux-toastr";

const ViewAllContainer = ({ history, ...props }) => {
  const [listOfTechs, setListOfTechs] = useState([]);

  const handleViewClick = (id) => {
    history.push(`/availabilities/${id}`);
  };

  useEffect(() => {
    API.get("/users")
      .then((res) => {
        const mappedTechs = [];
        res.data.forEach((user) => {
          if (user.permission_level === 0 && user.is_active) {
            user.value = user.user_id;
            user.label = user.username;
            mappedTechs.push(user);
          }
        });

        setListOfTechs(mappedTechs);
      })
      .catch((err) => toastr.error("Problem getting users"));
  }, []);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Availability</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Availabilities</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Card>
        <CardHeader tag="h4">Users List</CardHeader>
        <CardBody>
          <TableOfTechs users={listOfTechs} handleViewClick={handleViewClick} />
        </CardBody>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    authToken: state.auth.authToken,
    refreshToken: state.auth.refreshToken,
  };
};

export default withRouter(connect(mapStateToProps)(ViewAllContainer));
