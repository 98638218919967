import React, { useEffect, useState } from "react";
import { Button, Row, Col, Label } from "reactstrap";
import { withRouter } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import debounce from "../../../../components/UseDebounce";
import moment from "moment";
import { ButtonStatusIndicator } from "../../../../components/AppointmentStatusIndicator";

import Select from "react-select";
import API from "../../../../api";

const { SearchBar } = Search;

const AppointmentsTable = ({ history, customer, building }) => {
  const [data, setData] = useState({
    initial_entries: [],
    entries: [],
    total_entries: 0,
  });
  const [tableState, setTableState] = useState({
    page: 1,
    searchText: "",
    sizePerPage: 25,
    sortField: null,
    sortOrder: null,
  });

  const [filterName, setFilterName] = useState([
    "confirmed",
    "unscheduled",
    "scheduled",
    "resolved",
    "created",
  ]);

  const [label, setLabel] = useState(null);

  const fetchData = ({
    tableState,
    data,
    setData,
    status,
    resolution_status,
  }) => {
    const params = {
      page: tableState.page,
      sizePerPage: tableState.sizePerPage,
      searchText: tableState.searchText,
      sortField: tableState.sortField,
      sortOrder: tableState.sortOrder,
      customer: customer,
      building: building,
      status: status,
      resolution_status: resolution_status,
    };
    API.get("/appointments/list_paginated", { params: params })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        toastr.error("Error loading appointments");
      });
  };

  const debouncedFetchData = React.useCallback(debounce(fetchData, 300), []);

  useEffect(() => {
    debouncedFetchData({
      tableState,
      data,
      setData,
      status: ["confirmed", "unscheduled", "scheduled", "resolved", "created"],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableState]);

  useEffect(() => {
    fetchData({
      tableState,
      data,
      setData,
      status: [
        "confirmed",
        "unscheduled",
        "scheduled",
        "resolved",
        "created",
        "cancelled",
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, building]);

  useEffect(() => {
    if (filterName[0] === "resolved") {
      if (label.toLowerCase().includes("success")) {
        fetchData({
          tableState,
          data,
          setData,
          status: filterName,
          resolution_status: "success",
        });
      } else if (label.toLowerCase().includes("failed")) {
        fetchData({
          tableState,
          data,
          setData,
          status: filterName,
          resolution_status: "failure",
        });
      }
    } else {
      fetchData({
        tableState,
        data,
        setData,
        status: filterName,
      });
    }
  }, [filterName]);

  const defaultSorted = [
    {
      dataField: "start_date",
      order: "desc",
    },
  ];

  const tableColumns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      headerStyle: { width: "80px" },
    },
    {
      dataField: "ticket_number",
      text: "Ticket Number",
      sort: false,
      formatter: (cell, row) => cell,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cell, row) => <ButtonStatusIndicator appointment={row} />,
    },
    {
      dataField: "speed",
      text: "Speed Acceptable",
      formatter: (cell, row) => {
        if (row.status === "resolved") {
          return row.resolution.is_speed_adequate ? (
            <Button disabled color="outline-success">
              Speed Adequate
            </Button>
          ) : (
            <Button disabled color="outline-danger">
              Speed Inadequate
            </Button>
          );
        }
      },
    },
    {
      dataField: "customer.first_name",
      text: "Customer",
      sort: false,
      formatter: (cell, row) =>
        `${row.customer.first_name} ${row.customer.last_name}`,
    },
    {
      dataField: "customer.building.shortdesc",
      text: "Building",
      sort: false,
      formatter: (cell, row) => `${row.customer.building.shortdesc}`,
    },
    {
      dataField: "customer.apartment",
      text: "Unit",
      sort: false,
      formatter: (cell, row) => `${row.customer.apartment}`,
      headerStyle: { width: "4rem" },
    },
    {
      dataField: "technician",
      text: "Technician",
      sort: false,
      formatter: (cell, row) => {
        if (row.reservation) return row.reservation.technician.name;
        else if (row.confirmed_reservation)
          return row.confirmed_reservation.technician.name;
        else return "Unscheduled";
      },
    },
    {
      dataField: "start_date",
      text: "Date",
      sort: true,
      formatter: (cell, row) => {
        if (row.reservation)
          return moment(row.reservation.start_date).format("MM/DD/YY h:mm a");
        else if (row.confirmed_reservation)
          return moment(row.confirmed_reservation.start_date).format(
            "MM/DD/YY h:mm a"
          );
        else return "Unscheduled";
      },
    },
    {
      dataField: "actions",
      formatter: (cell, row) => (
        <React.Fragment>
          <Button
            color={"outline-primary"}
            onClick={() => history.push(`/appointments/view/${row.id}`)}
          >
            View
          </Button>
        </React.Fragment>
      ),
      text: "Actions",
      sort: false,
    },
  ];

  const handleTableChange = (type, newState) => {
    setTableState(newState);
  };

  const options = [
    {
      label: "All",
      value: [
        "confirmed",
        "unscheduled",
        "scheduled",
        "resolved",
        "created",
        "cancelled",
      ],
    },
    {
      label: "Confirmed",
      value: ["confirmed"],
    },
    {
      label: "Scheduled",
      value: ["scheduled"],
    },
    {
      label: "Resolved Failed",
      value: ["resolved"],
    },
    {
      label: "Resolved Success",
      value: ["resolved"],
    },
    {
      label: "Cancelled",
      value: ["cancelled"],
    },
    {
      label: "Unscheduled",
      value: ["unscheduled"],
    },
    {
      label: "Created",
      value: ["created"],
    },
    {
      label: "Reset",
      value: ["confirmed", "unscheduled", "scheduled", "resolved", "created"],
    },
  ];

  return (
    <Row>
      <Col>
        <ToolkitProvider
          keyField="id"
          data={data.entries}
          columns={tableColumns}
          search
        >
          {(props) => (
            <React.Fragment>
              <Row>
                <Col md="4">
                  <Label>Quick Filter</Label>
                  <Select
                    options={options}
                    onChange={(e) => {
                      setFilterName(e.value);
                      setLabel(e.label);
                    }}
                  />
                </Col>
              </Row>

              <span style={{ float: "right" }}>
                <SearchBar {...props.searchProps} />
              </span>

              <BootstrapTable
                {...props.baseProps}
                remote={{
                  filter: true,
                  pagination: true,
                  sort: true,
                  cellEdit: true,
                }}
                onTableChange={handleTableChange}
                bootstrap4
                bordered={false}
                defaultSorted={defaultSorted}
                pagination={paginationFactory({
                  page: tableState.page,
                  sizePerPage: tableState.sizePerPage,
                  totalSize: data.total_entries,
                  sizePerPageList: [5, 10, 25, 50],
                })}
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
      </Col>
    </Row>
  );
};

export default withRouter(AppointmentsTable);
