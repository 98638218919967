import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Row,
  Col,
  Container,
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import API from "../../api/index";

const AppointmentViewContainer = (props) => {
  let { id } = useParams();
  const [appointmentInfo, setAppointmentInfo] = useState({
    customer_name: "",
    problem_name: "",
    problem_description: "",
    building: "",
    street_one: "",
    street_two: "",
    apt: "",
    mobile: "",
    purchase_speed: "",
    start_time: "",
    end_time: "",
  });

  const getSingleAppointment = (id) => {
    API.get(`/appointments/${id}`)
      .then((res) => {
        let reservation;
        if (res.data.confirmed_reservation || res.data.reservation)
          if (res.data.confirmed_reservation) {
            reservation = res.data.confirmed_reservation;
          } else {
            reservation = res.data.reservation;
          }
        if (reservation) {
          {
            setAppointmentInfo((prevState) => ({
              ...prevState,
              start_time: reservation.start_date,
              end_time: reservation.end_time,
            }));
          }
        }

        setAppointmentInfo((prevState) => ({
          ...prevState,
          customer_name: `${res.data.customer.first_name} ${res.data.customer.last_name}`,
          problem_name: res.data.problem.name,
          problem_description: res.data.problem.problem_description,
          building: res.data.customer.building.shortdesc,
          street_one: res.data.customer.building.street1,
          street_two: res.data.customer.building.street2,
        }));
      })
      .catch((err) => toastr.error("Problem getting appointment info"));
  };

  useEffect(() => {
    getSingleAppointment(id);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Your Appointment</HeaderTitle>
      </Header>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              Your appointment with {appointmentInfo.customer_name}
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs="12">Problem: {appointmentInfo.problem_name}</Col>
                <Col xs="12">
                  {appointmentInfo.problem_description
                    ? `Description: ${appointmentInfo.problem_description}`
                    : ""}
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                  <Button className="btn-block">Resolve</Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AppointmentViewContainer;
