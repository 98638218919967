import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";

import { toastr } from "react-redux-toastr";
import {
  Card,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
  Container,
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import API from "../../api/index";

const TechLegacyCalendar = () => {
  const [legacyData, setLegacyData] = useState([]);
  const getLegacyData = () => {
    API.get("/appointments/tech_legacy_data")
      .then((res) => {
        const mappedLegacy = res.data.entries.map((appt) => {
          appt.id = appt.appointment_id;
          appt.title = appt.building.shortdesc;
          appt.start = appt.confirmed_reservation.start_date;
          appt.end = appt.confirmed_reservation.end_date;
          return appt;
        });
        setLegacyData(mappedLegacy);
      })
      .catch((err) => toastr.error("Could not get data"));
  };

  useEffect(() => {
    getLegacyData();
  }, []);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Past Appointments</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Appointments Calendar</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Card>
        <CardBody>
          <FullCalendar
            header={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
            }}
            events={legacyData}
            height={600}
            defaultView="timeGridWeek"
            plugins={[timeGridPlugin, dayGridPlugin]}
            //   slotDuration="01:00:00"
            scrollTime="06:00:00"
          />
        </CardBody>
      </Card>
    </Container>
  );
};

export default TechLegacyCalendar;
