import React from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Label,
  FormGroup,
  Form,
  InputGroup,
} from "reactstrap";

const SolutionInfoForm = ({
  techList,
  isReadOnly,
  handleReservationChange,
  reservation,
  isEdit = false,
  ...props
}) => {
  return (
    <Card>
      <CardHeader tag="h4">Date/time Reservation</CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <InputGroup>
                      <Label>Date</Label>
                      <DatePicker
                        className="form-control"
                        selected={reservation.solution_date}
                        value={reservation.solution_date}
                        onChange={(e) => {
                          handleReservationChange(e, "solution_date");
                        }}
                        disabled={isReadOnly}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <InputGroup>
                      <Label>Start Time</Label>
                      <DatePicker
                        className="form-control"
                        timeIntervals={15}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="h:mm aa"
                        selected={reservation.start_time}
                        onChange={(e) => {
                          handleReservationChange(e, "start_time");
                          handleReservationChange(
                            new Date(e.getTime() + 7200000),
                            "end_time"
                          );
                        }}
                        disabled={isReadOnly}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <InputGroup>
                      <Label>End Time</Label>
                      <DatePicker
                        className="form-control"
                        timeIntervals={15}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="h:mm aa"
                        selected={reservation.end_time}
                        onChange={(e) => handleReservationChange(e, "end_time")}
                        disabled={isReadOnly}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Technician</Label>
                    <Select
                      options={techList}
                      value={techList.find(
                        (tech) => tech.value == reservation.technician_id
                      )}
                      onChange={(e) =>
                        handleReservationChange(e.value, "technician_id")
                      }
                      isDisabled={isReadOnly}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {isEdit ? (
                <Row>
                  <Col>
                    <Label>Notify customer?</Label>
                    <FormGroup>
                      <BootstrapSwitchButton
                        onlabel="No"
                        offlabel="Yes"
                        size="lg"
                        name="force_confirm_do_not_notify_customer"
                        onChange={(checked) => {
                          handleReservationChange(
                            checked,
                            "force_confirm_do_not_notify_customer"
                          );
                        }}
                        onstyle="danger"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              ) : null}
            </Form>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default SolutionInfoForm;
