import { combineReducers } from "redux";

import { reducer as toastr } from "react-redux-toastr";
import authReducers from "./authReducers";
import sidebarReducers from "./sidebarReducers";

export default combineReducers({
  auth: authReducers,
  sidebar: sidebarReducers,
  toastr
});
