import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AvForm, AvRadio } from "availity-reactstrap-validation";
import { Link, withRouter, useParams } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import moment from "moment";
import "moment-timezone";
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Collapse,
  Label,
  FormGroup,
  Form,
  InputGroup,
  Input,
} from "reactstrap";

import API from "../../api/index";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import AvRadioGroup from "availity-reactstrap-validation/lib/AvRadioGroup";
import AvailabilityExceptions from "./Tables/Exceptions";

const AvailabilityViewCalendar = ({ availabilities, techName, ...props }) => {
  const mappedAvailabilities = availabilities.map((availability, idx) => {
    return {
      id: idx,
      title: "Available",
      start: availability.start_date,
      end: availability.end_date,
    };
  });

  return (
    <Card>
      <CardHeader tag="h4">
        Availability Calendar {techName ? `for ${techName}` : null}
      </CardHeader>
      <CardBody>
        <FullCalendar
          header={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
          }}
          events={mappedAvailabilities}
          height={600}
          defaultView="timeGridWeek"
          plugins={[timeGridPlugin, dayGridPlugin]}
          //   slotDuration="01:00:00"
          scrollTime="06:00:00"
        />
      </CardBody>
    </Card>
  );
};

// const { SearchBar } = Search;
const setTimeForTable = (cell, row) => {
  const availabilityDate = moment(cell, ["HH:mm:ss"]).format("h:mm A");
  return availabilityDate;
};

const AvailabilityViewTable = ({
  availabilities,
  openEditAvail,
  setEditId,
  openAvail,
  handleDestroyAvailability,
  ...props
}) => {
  const weekday = ["Sun", "Mon", "Tues", "Weds", "Thurs", "Fri", "Sat"];

  const handleEditClick = (e) => {
    openEditAvail();
    setEditId(e.target.value);
  };

  const tableColumns = [
    {
      dataField: "day_of_week",
      text: "Day",
      sort: true,
      formatter: (cell, row) => {
        return weekday[cell];
      },
    },
    {
      dataField: "start_time",
      text: "Start Time",
      sort: true,
      formatter: setTimeForTable,
    },
    {
      dataField: "end_time",
      text: "End Time",
      sort: true,

      formatter: setTimeForTable,
    },

    {
      dataField: "edit",
      text: "Actions",
      formatter: (cell, row) => (
        <>
          <Button onClick={handleEditClick} value={row.id}>
            Edit
          </Button>
          <Button
            className="ml-3"
            color="danger"
            onClick={() => handleDestroyAvailability(row.id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];
  return (
    <>
      <Card>
        <CardHeader tag="h4">
          <Row>
            <Col>Availability View</Col>
            <Col>
              <Button className="float-right" onClick={openAvail}>
                New Availability
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <ToolkitProvider
            keyField="id"
            data={availabilities}
            columns={tableColumns}
          >
            {(props) => (
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                bordered={false}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
              />
            )}
          </ToolkitProvider>
        </CardBody>
      </Card>
    </>
  );
};

const selectDoW = [
  {
    value: 0,
    label: "Sunday",
  },
  {
    value: 1,
    label: "Monday",
  },
  {
    value: 2,
    label: "Tuesday",
  },
  {
    value: 3,
    label: "Wednesday",
  },
  {
    value: 4,
    label: "Thursday",
  },
  {
    value: 5,
    label: "Friday",
  },
  {
    value: 6,
    label: "Saturday",
  },
];

const NewAvailability = ({
  setCollapseClose,
  repullAvails,
  handleNewAvailabilitySubmit,
  ...props
}) => {
  const today = new Date();
  const [showEffectiveTo, setShowEffectiveTo] = useState(false);
  const [showEffectiveFrom, setShowEffectiveFrom] = useState(false);
  const [availability, setAvailability] = useState({
    from: "",
    to: "",
    effectiveFrom: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ),
    effectiveTo: null,
    dow: "",
  });

  const handleSubmit = () => {
    if (
      availability.from === "" ||
      availability.to === "" ||
      availability.effectiveFrom === ""
    ) {
      return toastr.error("Please fill out all fields");
    }

    const postData = {
      start_time: moment(availability.from).format("HH:mm:ss Z"),
      end_time: moment(availability.to).format("HH:mm:ss Z"),
      recur_start: availability.effectiveFrom,
      recur_end: null,
      day_of_week: availability.dow,
    };

    if (postData.end_date < postData.start_date) {
      return toastr.error("End date must be after start date");
    }

    if (postData.recur_end !== null) {
      if (postData.recur_end < postData.recur_start) {
        return toastr.error("Effective End must be after Effective Start");
      }
    }

    handleNewAvailabilitySubmit(postData)
      .then((res) => {
        toastr.success("New Availability Created");
        setAvailability((prevState) => ({
          ...prevState,
          from: "",
          to: "",
          dow: "",
        }));
        repullAvails();
        setCollapseClose();
      })
      .catch((err) => {
        toastr.error("Something went wrong, please try again");
      });
  };

  return (
    <>
      <Card>
        <CardHeader tag="h4">New Availability</CardHeader>
        <CardBody>
          <Form>
            <FormGroup>
              <Label>Day of Week</Label>
              <Select
                options={selectDoW}
                onChange={(e) => {
                  setAvailability({ ...availability, dow: e.value });
                }}
              />
            </FormGroup>

            <FormGroup>
              <InputGroup>
                <Label>Time Start</Label>

                <DatePicker
                  className="form-control"
                  timeIntervals={15}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="h:mm aa"
                  onChange={(e) =>
                    setAvailability({ ...availability, from: e })
                  }
                  selected={availability.from}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <Label>Time End</Label>

                <DatePicker
                  className="form-control"
                  timeIntervals={15}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="h:mm aa"
                  onChange={(e) => setAvailability({ ...availability, to: e })}
                  selected={availability.to}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <h5>Effective From</h5>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    name="showEffectiveFrom"
                    defaultChecked
                    onClick={(e) => {
                      setShowEffectiveFrom(false);
                    }}
                  />
                  Today
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    name="showEffectiveFrom"
                    onClick={() => setShowEffectiveFrom(true)}
                  />
                  Other
                </Label>
              </FormGroup>
              <FormGroup>
                {showEffectiveFrom ? (
                  <FormGroup>
                    <InputGroup>
                      <DatePicker
                        className="form-control"
                        onChange={(e) =>
                          setAvailability({ ...availability, effectiveFrom: e })
                        }
                        selected={availability.effectiveFrom}
                      />
                    </InputGroup>
                  </FormGroup>
                ) : null}
              </FormGroup>
              <FormGroup>
                <h5>Is this forever?</h5>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="showEffectiveTo"
                      defaultChecked
                      onClick={() => setShowEffectiveTo(false)}
                    />
                    Yes
                  </Label>
                </FormGroup>

                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="showEffectiveTo"
                      onClick={() => {
                        setShowEffectiveTo(true);
                        setAvailability({ ...availability, effectiveTo: null });
                      }}
                    />
                    No
                  </Label>
                </FormGroup>
              </FormGroup>

              {showEffectiveTo ? (
                <FormGroup>
                  <InputGroup>
                    <Label>Effective To</Label>

                    <DatePicker
                      className="form-control"
                      onChange={(e) =>
                        setAvailability({ ...availability, effectiveTo: e })
                      }
                      selected={availability.effectiveTo}
                    />
                  </InputGroup>
                </FormGroup>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Button onClick={handleSubmit}>Submit</Button>
              <Button className="float-right" onClick={setCollapseClose}>
                Close
              </Button>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

const EditAvailability = ({
  availabilities,
  editId,
  setCollapseClose,
  repullAvails,
  handleEditAvailabilitySubmit,
  ...props
}) => {
  const [showEffectiveTo, setShowEffectiveTo] = useState(false);
  const [availability, setAvailability] = useState({
    start_time: "",
    end_time: "",
    effectiveFrom: "",
    effectiveTo: null,
    day_of_week: "",
  });

  useEffect(() => {
    const editableAvail = availabilities.find((ele) => ele.id == editId);

    //TODO == revisit, do i need this
    let recurEnd;
    if (editableAvail) {
      if (editableAvail.recur_end != null) {
        recurEnd = new Date(editableAvail.recur_end);
      }
      recurEnd = null;

      const recurStart = new Date(editableAvail.recur_start);

      setAvailability({
        ...availability,
        start_time: new Date(
          moment(editableAvail.start_time, ["HH:mm:ss"]).format()
        ),
        end_time: new Date(
          moment(editableAvail.end_time, ["HH:mm:ss"]).format()
        ),
        effectiveFrom: recurStart,
        effectiveTo: recurEnd,
        day_of_week: editableAvail.day_of_week,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId]);

  const handleSubmit = () => {
    if (
      availability.start_time === "" ||
      availability.end_time === "" ||
      availability.effectiveFrom === ""
    ) {
      return toastr.error("Please fill out all fields");
    }

    const postData = {
      start_time: moment(availability.start_time).format("HH:mm"),
      end_time: moment(availability.end_time).format("HH:mm"),
      recur_start: new Date(availability.effectiveFrom),
      recur_end: null,
      day_of_week: availability.day_of_week,
    };

    if (postData.end_date < postData.start_date) {
      return toastr.error("End date must come after start date");
    }
    if (postData.recur_end) {
      if (postData.recur_end < postData.recur_start) {
        return toastr.error("Effective end must be after effective from");
      }
    }

    handleEditAvailabilitySubmit(postData)
      .then((res) => {
        toastr.success("Edited Availability");
        repullAvails();
        setCollapseClose();
      })
      .catch((err) => {
        toastr.error("Something went wrong, please try again");
      });
  };

  return (
    <>
      <Card>
        <CardHeader tag="h4">Edit Availability</CardHeader>
        <CardBody>
          <AvForm>
            <FormGroup>
              <Label>Day of Week</Label>
              <Select
                value={selectDoW.filter(
                  (option) => option.value === availability.day_of_week
                )}
                options={selectDoW}
                onChange={(e) => {
                  setAvailability({ ...availability, dow: e.value });
                }}
              />
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <Label>Time Start</Label>

                <DatePicker
                  timeIntervals={15}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="h:mm aa"
                  onChange={(e) =>
                    setAvailability({ ...availability, start_time: e })
                  }
                  selected={availability.start_time}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <Label>Time End</Label>

                <DatePicker
                  timeIntervals={15}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="h:mm aa"
                  onChange={(e) =>
                    setAvailability({ ...availability, end_time: e })
                  }
                  selected={availability.end_time}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <Label>Effective From</Label>

                <DatePicker
                  className="form-control"
                  onChange={(e) =>
                    setAvailability({ ...availability, effectiveFrom: e })
                  }
                  selected={availability.effectiveFrom}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <h5>Is this forever?</h5>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    name="showEffectiveTo"
                    defaultChecked
                    onClick={() => setShowEffectiveTo(false)}
                  />
                  Yes
                </Label>
              </FormGroup>

              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    name="showEffectiveTo"
                    onClick={() => {
                      setShowEffectiveTo(true);
                    }}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
            {showEffectiveTo ? (
              <FormGroup>
                <InputGroup>
                  <Label>Effective To</Label>

                  <DatePicker
                    className="form-control"
                    onChange={(e) =>
                      setAvailability({ ...availability, effectiveTo: e })
                    }
                    selected={availability.effectiveTo}
                  />
                </InputGroup>
              </FormGroup>
            ) : null}

            <FormGroup>
              <Button onClick={handleSubmit}>Submit</Button>
              <Button className="float-right" onClick={setCollapseClose}>
                Close
              </Button>
            </FormGroup>
          </AvForm>
        </CardBody>
      </Card>
    </>
  );
};

const NewException = ({
  setCollapseClose,
  repullAvails,
  handleNewExceptionSubmit,
  ...props
}) => {
  const [data, setData] = useState({
    dateStart: "",
    dateEnd: "",
    start_time: "",
    end_time: "",
    is_available: "",
    reason: "",
  });

  const resetData = () => {
    setData({
      dateStart: "",
      dateEnd: "",
      start_time: "",
      end_time: "",
      is_available: "",
      reason: "",
    });
  };

  const handleSubmit = () => {
    if (
      data.dateStart === "" ||
      data.dateEnd === "" ||
      data.start_time === "" ||
      data.end_time === "" ||
      data.is_available === "" ||
      data.reason === ""
    ) {
      return toastr.error("Please fill out all fields");
    }

    const postData = {
      start_date: new Date(
        data.dateStart.getFullYear(),
        data.dateStart.getMonth(),
        data.dateStart.getDate(),
        data.start_time.getHours(),
        data.start_time.getMinutes()
      ),
      end_date: new Date(
        data.dateEnd.getFullYear(),
        data.dateEnd.getMonth(),
        data.dateEnd.getDate(),
        data.end_time.getHours(),
        data.end_time.getMinutes()
      ),
      is_available: data.is_available,
      status: "approved",
      reason: data.reason,
    };

    if (postData.end_date < postData.start_date) {
      return toastr.error("End date must be after start date");
    }

    handleNewExceptionSubmit(postData)
      .then((res) => {
        toastr.success("Exception Created");
        resetData();
        setCollapseClose();
        repullAvails();
      })
      .catch((err) => {
        toastr.error("Something went wrong, please try again");
      });
  };

  return (
    <>
      <Card>
        <CardHeader tag="h4">New Exception</CardHeader>
        <CardBody>
          <AvForm>
            <Row>
              <Col>
                <h5>Start</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <InputGroup>
                    <Label>Date</Label>

                    <DatePicker
                      className="form-control"
                      onChange={(e) => setData({ ...data, dateStart: e })}
                      selected={data.dateStart}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <InputGroup>
                    <Label>Time</Label>

                    <DatePicker
                      className="form-control"
                      timeIntervals={15}
                      showTimeSelect
                      showTimeSelectOnly
                      dateFormat="h:mm aa"
                      onChange={(e) => setData({ ...data, start_time: e })}
                      selected={data.start_time}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <h5>End</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <InputGroup>
                    <Label>Date</Label>

                    <DatePicker
                      className="form-control"
                      onChange={(e) => setData({ ...data, dateEnd: e })}
                      selected={data.dateEnd}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <InputGroup>
                    <Label>Time</Label>

                    <DatePicker
                      className="form-control"
                      timeIntervals={15}
                      showTimeSelect
                      showTimeSelectOnly
                      dateFormat="h:mm aa"
                      onChange={(e) => setData({ ...data, end_time: e })}
                      selected={data.end_time}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label>Reason</Label>
              <Input
                type="textarea"
                onChange={(e) =>
                  setData((prevState) => ({
                    ...prevState,
                    reason: e.target.value,
                  }))
                }
              />
            </FormGroup>

            <AvRadioGroup
              inline
              name="is_available_radioButtons"
              label="Are you Availabile?"
            >
              <AvRadio
                customInput
                label="Yes"
                value="true"
                onClick={(e) =>
                  setData({ ...data, is_available: e.target.value })
                }
              />
              <AvRadio
                customInput
                label="No"
                value="false"
                onClick={(e) =>
                  setData({ ...data, is_available: e.target.value })
                }
              />
            </AvRadioGroup>

            <FormGroup>
              <Button onClick={handleSubmit}>Submit</Button>

              <Button className="float-right" onClick={setCollapseClose}>
                Close
              </Button>
            </FormGroup>
          </AvForm>
        </CardBody>
      </Card>
    </>
  );
};

const CreateEditAvailability = () => {
  let { id } = useParams();
  const [availIsOpen, setAvailIsOpen] = useState(false);
  const [editAvailIsOpen, setEditAvailIsOpen] = useState(false);
  const [exceptionIsOpen, setExceptionIsOpen] = useState(false);
  const [availabilities, setAvailabilities] = useState([]);
  const [availabilityExceptions, setAvailabilityExceptions] = useState([]);

  const [tech, setTech] = useState({ name: "" });
  const [freeAvailabilities, setFreeAvailabilities] = useState([]);

  const getAvailFree = () => {
    API.get(`/availability/free/${id}`)
      .then((res) => {
        setTech(res.data);
        setFreeAvailabilities(res.data.free);
      })
      .catch((err) => toastr.error("Error fetching availabilities"));
  };

  const getAvailFunc = () => {
    API.get(`/availabilities`, {
      params: {
        technician_id: id,
      },
    })
      .then((res) => {
        setAvailabilities(res.data);
      })
      .catch((err) => {
        toastr.error("Please reload the page");
      });
  };

  const getAvailExceptionFunc = (id) => {
    API.get(`/availability/exceptions`)
      .then((res) => {
        const mappedExceptions = [];
        res.data.forEach((excep) => {
          if (excep.technician_id == id) {
            mappedExceptions.push(excep);
          }
        });

        setAvailabilityExceptions(mappedExceptions);
      })
      .catch((err) => {
        toastr.error("Please reload the page");
      });
  };

  const repullAvails = () => {
    getAvailFunc();
    getAvailFree(id);
    getAvailExceptionFunc(id);
  };

  useEffect(() => {
    repullAvails();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewAvailabilitySubmit = ({
    day_of_week,
    start_time,
    end_time,
    recur_start,
    recur_end,
  }) => {
    return API.post(`/availabilities`, null, {
      params: {
        technician_id: id,
        end_time,
        start_time,
        recur_start,
        recur_end,
        day_of_week,
      },
    });
  };

  const handleDestroyAvailability = (avail) => {
    if (
      window.confirm("Are you sure that you want to delete this availability?")
    ) {
      API.delete(`/availabilities/${avail}`)
        .then((res) => {
          getAvailExceptionFunc(id);
          repullAvails();
          return toastr.success("Successfully deleted availability");
        })
        .catch((err) => toastr.error(`${err}`));
    }
  };

  const [editId, setEditId] = useState("");

  const handleEditAvailabilitySubmit = ({
    start_time,
    end_time,
    recur_start,
    recur_end,
    day_of_week,
  }) => {
    return API.put(`/availabilities/${editId}`, null, {
      params: {
        technician_id: id,
        start_time,
        end_time,
        recur_start,
        recur_end,
        day_of_week,
      },
    });
  };

  const handleNewExceptionSubmit = ({
    start_date,
    end_date,
    is_available,
    status,
    reason,
  }) => {
    return API.post(`/availability/exceptions`, null, {
      params: {
        technician_id: id,
        start_date,
        end_date,
        is_available,
        status,
        reason,
      },
    });
  };

  const handleAcceptClick = (id) => {
    API.put(`availability/exceptions/${id}/approve`)
      .then((res) => {
        toastr.success("Accepted exception");
        getAvailExceptionFunc(id);
        repullAvails();
      })
      .catch((err) => toastr.error("Something went wrong"));
  };

  const handleRejectionClick = (id) => {
    API.put(`availability/exceptions/${id}/reject`)
      .then((res) => {
        toastr.success("Rejected exception");
        getAvailExceptionFunc(id);
        repullAvails();
      })
      .catch((err) => toastr.error("Something went wrong"));
  };

  const openAvail = () => {
    setAvailIsOpen(true);
    setEditAvailIsOpen(false);
    setExceptionIsOpen(false);
  };
  const setCollapseClose = () => {
    setAvailIsOpen(false);
    setEditAvailIsOpen(false);
    setExceptionIsOpen(false);
  };
  const openEditAvail = () => {
    setEditAvailIsOpen(true);
    setAvailIsOpen(false);
    setExceptionIsOpen(false);
  };

  const openException = () => {
    setExceptionIsOpen(true);
    setAvailIsOpen(false);
    setEditAvailIsOpen(false);
  };

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Availability</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Dashboard</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/availabilities/sup">Availabilities</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Availability {tech.name}</BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Row>
        <Col>
          <Row>
            <Col>
              <AvailabilityViewCalendar
                availabilities={freeAvailabilities}
                techName={tech.name}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <AvailabilityViewTable
                openAvail={openAvail}
                openEditAvail={openEditAvail}
                availabilities={availabilities}
                setEditId={setEditId}
                handleDestroyAvailability={handleDestroyAvailability}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <AvailabilityExceptions
                openException={openException}
                availabilityExceptions={availabilityExceptions}
                handleAcceptClick={handleAcceptClick}
                handleRejectionClick={handleRejectionClick}
              />
            </Col>
          </Row>
        </Col>

        <Collapse isOpen={availIsOpen}>
          <Col>
            <NewAvailability
              openAvail={openAvail}
              handleNewAvailabilitySubmit={handleNewAvailabilitySubmit}
              setCollapseClose={setCollapseClose}
              repullAvails={repullAvails}
            />
          </Col>
        </Collapse>
        <Collapse isOpen={editAvailIsOpen}>
          <Col>
            <EditAvailability
              openEditAvail={openEditAvail}
              availabilities={availabilities}
              editId={editId}
              handleEditAvailabilitySubmit={handleEditAvailabilitySubmit}
              setCollapseClose={setCollapseClose}
              repullAvails={repullAvails}
            />
          </Col>
        </Collapse>
        <Collapse isOpen={exceptionIsOpen}>
          <Col>
            <NewException
              handleNewExceptionSubmit={handleNewExceptionSubmit}
              openException={openException}
              setCollapseClose={setCollapseClose}
              repullAvails={repullAvails}
            />
          </Col>
        </Collapse>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    authToken: state.auth.authToken,
    refreshToken: state.auth.refreshToken,
  };
};

export default withRouter(connect(mapStateToProps)(CreateEditAvailability));
