import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  BreadcrumbItem,
  Breadcrumb,
  Container,
  Col,
  Label,
  Row,
  FormGroup,
} from "reactstrap";
import { Link, withRouter, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { getPermissionLevel, getUserID } from "../../helpers/TokenDecode";

import API from "../../api/index";
import { AvForm, AvField } from "availity-reactstrap-validation";

const ChangePasswordContainer = (props) => {
  let { id } = useParams();

  const [passwords, setPasswords] = useState({
    new_password: "",
    confirm_new_password: "",
  });

  const handlePasswordChange = (e) => {
    setPasswords((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (params) => {
    if (
      params.new_password !== "" &&
      params.confirm_new_password !== "" &&
      params.new_password === params.confirm_new_password
    ) {
      API.put(`/users/${id}/change_password`, params)
        .then((res) => {
          toastr.success("Successfully changed password");
          props.history.push(`/admin/users/edit/${id}`);
        })
        .catch((err) => toastr.error("Could not submit"));
    } else {
      toastr.error("Passwords must match and not be empty");
    }
  };

  // useEffect(() => {
  //   if (getUserID(props.authToken) !== id) {
  //     props.history.push(`/`);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Change Password</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/admin/users">Users</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`/admin/users/edit/${id}`}>Edit</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Change Password</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <Card>
            <CardHeader tag="h4">Change your password</CardHeader>
            <CardBody>
              <AvForm>
                <FormGroup>
                  <Label>New Password</Label>
                  <AvField
                    onChange={(e) => handlePasswordChange(e)}
                    type="password"
                    name="new_password"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Confirm Password</Label>
                  <AvField
                    onChange={(e) => handlePasswordChange(e)}
                    type="password"
                    name="confirm_new_password"
                  />
                </FormGroup>
              </AvForm>
              <Button onClick={() => handleSubmit({ ...passwords })}>
                Submit
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    authToken: state.auth.authToken,
    refreshToken: state.auth.refreshToken,
  };
};

export default withRouter(connect(mapStateToProps)(ChangePasswordContainer));
