import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Select from 'react-select';
import InputMask from 'react-input-mask';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
  Button,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  Breadcrumb,
  BreadcrumbItem,
  Container,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import API from '../../api/index';
import Header from '../../components/Header';
import HeaderTitle from '../../components/HeaderTitle';
import { toastr } from 'react-redux-toastr';

const UserView = ({ history, ...props }) => {
  const [user, setUser] = useState({
    id: '',
    username: '',
    permission_level: '',
    tech_level: '',
    is_active: '',
    email: '',
    mobile_number: '',
  });

  const [originalUser, setOriginalUser] = useState({
    id: '',
    username: '',
    permission_level: '',
    tech_level: '',
    is_active: '',
    mobile_number: '',
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [permissionLevels, setPermissionLevels] = useState([]);
  const [techLevels, setTechLevels] = useState([]);
  let { id } = useParams();

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    API.get('/auth/permission_levels')
      .then((res) => {
        const permissionTypes = res.data.map((x) => ({
          value: x.level,
          label: x.permissions,
        }));
        setPermissionLevels(permissionTypes);
      })
      .catch((err) => toastr.error('Could not get permission levels'));
    API.get('/auth/tech_levels')
      .then((res) => setTechLevels(res.data))
      .catch(() => toastr.error('Could not get permission levels'));
  }, []);

  useEffect(() => {
    //make api call to get all of the information of the id
    API.get(`/users/${id}`)
      .then((res) => {
        if (res.data.mobile_number != '') {
          res.data.mobile_number = parsePhoneNumberFromString(
            res.data.mobile_number
          ).nationalNumber;
        }
        setUser(res.data);
        setOriginalUser(res.data);
      })
      .catch((err) => toastr.error('Could not get user information'));
  }, [id]);

  const handleSaveUser = () => {
    if (user.username !== '' && user.email !== '') {
      if (user.permission_level == 0 && user.mobile_number == '') {
        return toastr.error('Mobile number cannot be blank');
      }
      user.mobile_number = `+1${user.mobile_number}`;
      if (user.permission_level !== 0) {
        user.mobile_number = '';
      }
      API.put(`/users/${user.id}`, user)
        .then((res) => {
          setUser(res.data);
          toastr.success('Successfully edited the user');
          history.push('/admin/users');
        })
        .catch((err) => toastr.error('There was a problem editing the user'));
    } else toastr.error('Username or email cannot be empty');
  };

  const handleOnChange = (e, name) => {
    setUser({ ...user, [name]: e.target.value });
  };

  const handleDisableClick = () => {
    API.put(`/users/${user.id}/disable`)
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => toastr.error(`${err.response.data.errors}`));
  };

  const handleEnableClick = () => {
    API.put(`/users/${user.id}/enable`)
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => toastr.error(`${err.response.data.errors}`));
  };

  const handleResetPasswordClick = (id) => {
    API.put(`/users/${id}/reset`)
      .then((res) => toastr.success('Password Reset'))
      .catch((err) => toastr.error('Could not reset password'));
  };

  const handleChangePasswordClick = (id) => {
    history.push(`/auth/users/${id}/change_password`);
  };

  const techLevelArray = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
  ];

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Editing {user.name}</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/admin/users">Users</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Edit</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Col md={{ size: 6, offset: 3 }}>
        <Card>
          <CardHeader>
            <Row>
              <Col>
                <CardTitle tag="h4">Editing {user.name}</CardTitle>
              </Col>

              <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="left">
                <DropdownToggle>. . .</DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem header disabled>
                    User
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => handleResetPasswordClick(id)}>
                    Reset Password
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangePasswordClick(id)}>
                    Change Password
                  </DropdownItem>
                  {user.is_active ? (
                    <DropdownItem onClick={handleDisableClick}>
                      Disable User
                    </DropdownItem>
                  ) : (
                    <DropdownItem onClick={handleEnableClick}>
                      Enable User
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </Row>
          </CardHeader>
          <CardBody>
            <AvForm onSubmit={handleSaveUser}>
              <FormGroup>
                <AvField
                  label="Username"
                  name="username"
                  value={user.username}
                  onChange={(e) => handleOnChange(e, 'username')}
                />
              </FormGroup>
              <FormGroup>
                <AvField
                  label="Email"
                  name="email"
                  value={user.email}
                  onChange={(e) => handleOnChange(e, 'email')}
                />
              </FormGroup>
              <FormGroup>
                <Label>Permission Level</Label>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="permission_level"
                  options={permissionLevels}
                  value={permissionLevels.find(
                    (x) => x.value === user.permission_level
                  )}
                  isSearchable
                  onChange={(val) =>
                    setUser({
                      ...user,
                      permission_level: val.value,
                      tech_level: '',
                    })
                  }
                />
              </FormGroup>
              {user.permission_level === 0 ? (
                <>
                  <FormGroup>
                    <Label>Tech Level</Label>
                    <Select
                      name="tech_level"
                      options={techLevels}
                      value={techLevels.find(
                        (x) => x.value === user.tech_level
                      )}
                      onChange={(e) =>
                        setUser((prevState) => ({
                          ...prevState,
                          tech_level: e.value,
                        }))
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <AvField
                      label="Mobile Number"
                      name="mobile_number"
                      value={user.mobile_number}
                      alwaysShowMask={true}
                      mask="(999) 999-9999"
                      tag={[Input, InputMask]}
                      onChange={(e) => {
                        let number = e.target.value;
                        let replacedNumber = number.replace(
                          /\(|\)|_|-|\s/gi,
                          ''
                        );
                        setUser({ ...user, mobile_number: replacedNumber });
                      }}
                    />
                  </FormGroup>
                </>
              ) : null}

              <FormGroup>
                <Label>Account Status</Label>
                <Row>
                  <Col>
                    {user.is_active ? (
                      <Button disabled color="success">
                        Active
                      </Button>
                    ) : (
                      <Button disabled color="danger">
                        Disabled
                      </Button>
                    )}
                  </Col>
                </Row>
              </FormGroup>
              <Row className="pt-4">
                <Col>
                  <FormGroup>
                    {/* TODO:::: only show save button if there is a change in original state*/}
                    <Button className="float-right" onClick={handleSaveUser}>
                      Save
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default UserView;
