import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, UncontrolledAlert, Button } from 'reactstrap';

import { faBell } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BuildingNotesBanner = ({ building, isTech = false }) => {
  const [seeMore, setSeeMore] = useState(false);

  let inputEl = useRef(null);
  const [isOverflow, setIsOverflow] = useState();

  function check(el) {
    var curOverf = el.style.overflow;

    if (!curOverf || curOverf === 'visible') el.style.overflow = 'hidden';

    var isOverflowing =
      el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

    el.style.overflow = curOverf;

    return isOverflowing;
  }

  useEffect(() => {
    setIsOverflow(check(inputEl));
  }, [seeMore, isOverflow]);

  return (
    <Row>
      <Col>
        <UncontrolledAlert color="warning" className="alert-outline-coloured">
          <div className="alert-icon">
            <FontAwesomeIcon icon={faBell} fixedWidth />
          </div>
          <div className="alert-message">
            <Row>
              <Col md="3">
                <strong>Building Notes:</strong>
              </Col>
              <Col md="8">
                <pre
                  ref={(ref) => (inputEl = ref)}
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'pre-wrap',
                    height: seeMore ? 'auto' : '5vh',
                  }}
                >
                  {building.notes}
                </pre>
              </Col>
            </Row>
            <Row>
              <Col>
                <hr />
                <div className="btn-list">
                  {inputEl && !isOverflow ? (
                    <Button
                      color="outline-primary"
                      className="mr-1"
                      onClick={() => setSeeMore(false)}
                    >
                      See Less
                    </Button>
                  ) : (
                    <Button
                      color="outline-primary"
                      className="mr-1"
                      onClick={() => setSeeMore(true)}
                    >
                      See More
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </UncontrolledAlert>
      </Col>
    </Row>
  );
};

export default BuildingNotesBanner;
