import React, { useEffect, useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import API from "../../api/index.js";

import FormContainer from "./Resolution";

const EditResolution = ({ ...props }) => {
  let { id } = useParams();
  const [data, setData] = useState({});
  const getResolution = () => {
    API.get(`/resolutions/${id}`)
      .then((res) => setData(res.data))
      .catch((err) => {
        props.history.push("/dashboard");

        return toastr.error(`${err.response.data.message}`);
      });
  };

  useEffect(() => {
    getResolution();
  }, []);

  return <FormContainer res={data} isEdit={true} />;
};

export default withRouter(EditResolution);
