import { faHome } from '@fortawesome/free-solid-svg-icons';

// layouts
import DashboardLayout from '../layouts/Dashboard';
import AuthLayout from '../layouts/Auth';
import ConfirmLayout from '../layouts/Confirm';

// dashboard pages
import DashboardPage from '../pages/Dashboard';

// admin pages
import UsersPage from '../pages/Admin/Users';
import SettingsPage from '../pages/Admin/Settings';
import UserEditPage from '../pages/Admin/UserEdit';
import UserNewPage from '../pages/Admin/UserNew';
import ChangePasswordPage from '../pages/Admin/ChangePassword';
import ChangePhoneNumberPage from '../pages/Admin/ChangePhoneNumber';
import ListBuildings from '../pages/Admin/Buildings/List.js';
import EditBuilding from '../pages/Admin/Buildings/Edit';
import ViewBuilding from '../pages/Admin/Buildings/View';

// appointment pages

import AppointmentCreatePage from '../pages/Appointments/AppointmentCreate';
import AppointmentView from '../pages/Appointments/View';
import AppointmentEdit from '../pages/Appointments/AppointmentEdit';
import AppointmentCalendarPage from '../pages/Appointments/Calendar';
import AppointmentConfirmPage from '../pages/Appointments/Confirm';
import AppointmentResolve from '../pages/Appointments/Resolution';
import { default as AppointmentViewTech } from '../pages/Appointments/AppointmentViewTech';
import UnresolvedAppointmentsContainer from '../pages/Appointments/UnresolvedTableTech';
import AppointmentsList from '../pages/Appointments/List';
import AppointmentsListCustomer from '../pages/Appointments/List/Customer';
import AppointmentsListBuilding from '../pages/Appointments/List/Building';
import AppointmentLogs from '../pages/Appointments/Log';

// auth pages
import LoginPage from '../pages/Auth/Login';

//availability pages
import AvailabilityCreatePageTech from '../pages/Availability/CreateEditAvailabilityTech';
import AvailabilityCreatePageSup from '../pages/Availability/CreateEditAvailabilitySup';
import ViewAllAvailabilities from '../pages/Availability/AvailabilityViewAll';
import TechLegacyCalendar from '../pages/Appointments/TechLegacyCalendar';

//reports pages
import ReportsContainerPage from '../pages/Reports/ReportsContainer';
import EditResolution from '../pages/Appointments/EditResolution';

export const routes = [
  {
    name: 'Dashboard',
    icon: faHome,
    path: '/dashboard',
    showInSidebar: true,
    permissionLevels: [0, 1, 10],
    component: DashboardPage,
    layout: DashboardLayout,
    defaultRoute: true,
  },
  {
    name: 'Appointments',
    showInSidebar: true,
    path: '/appointments',
    permissionLevels: [0, 1, 10],
    children: [
      {
        name: 'Calendar',
        path: '/appointments/calendar',
        component: AppointmentCalendarPage,
        layout: DashboardLayout,
        showInSidebar: true,
        permissionLevels: [1, 10],
      },
      {
        name: 'View All',
        path: '/appointments',
        component: AppointmentsList,
        layout: DashboardLayout,
        showInSidebar: true,
        permissionLevels: [1, 10],
      },
      {
        name: 'View By Customer',
        path: '/appointments/customer/:id',
        component: AppointmentsListCustomer,
        layout: DashboardLayout,
        showInSidebar: false,
        permissionLevels: [1, 10],
      },
      {
        name: 'View By Building',
        path: '/appointments/building/:id',
        component: AppointmentsListBuilding,
        layout: DashboardLayout,
        showInSidebar: false,
        permissionLevels: [1, 10],
      },
      {
        name: 'Create Appointment',
        path: '/appointments/create',
        component: AppointmentCreatePage,
        layout: DashboardLayout,
        showInSidebar: true,
        permissionLevels: [1, 10],
      },
      {
        name: 'View Appointment',
        path: '/appointments/view/:id',
        component: AppointmentView,
        layout: DashboardLayout,
        showInSidebar: false,
        permissionLevels: [1, 10],
      },
      {
        name: 'View Appointment Logs',
        path: '/appointments/view/:id/logs',
        component: AppointmentLogs,
        layout: DashboardLayout,
        showInSidebar: false,
        permissionLevels: [0, 1, 10],
      },
      {
        name: 'View Appointment [Tech]',
        path: '/appointments/tech/view/:id',
        component: AppointmentViewTech,
        layout: DashboardLayout,
        showInSidebar: false,
        permissionLevels: [0],
      },
      {
        name: 'View Unresolved Appointments',
        path: '/appointments/tech/unresolved/',
        component: UnresolvedAppointmentsContainer,
        layout: DashboardLayout,
        showInSidebar: true,
        permissionLevels: [0, 1, 10],
      },
      {
        name: 'Edit Appointment',
        path: '/appointments/edit/:id',
        component: AppointmentEdit,
        layout: DashboardLayout,
        showInSidebar: false,
        permissionLevels: [0, 1, 10],
      },
      {
        name: 'Confirm Appointment',
        path: '/appointments/confirm/:uuid',
        component: AppointmentConfirmPage,
        layout: ConfirmLayout,
        showInSidebar: false,
        secure: false,
        permissionLevels: [],
      },
      {
        name: 'Resolve Appointment',
        path: '/appointments/resolve/:id',
        component: AppointmentResolve,
        layout: DashboardLayout,
        showInSidebar: false,
        permissionLevels: [0, 1, 10],
      },
      {
        name: 'Edit Resolution',
        path: '/resolutions/edit/:id',
        component: EditResolution,
        layout: DashboardLayout,
        showInSidebar: false,
        permissionLevels: [0, 1, 10],
      },
      {
        name: 'View Calendar Hours',
        path: '/appointments/legacy_appointments',
        component: TechLegacyCalendar,
        layout: DashboardLayout,
        showInSidebar: true,
        permissionLevels: [0],
      },
    ],
  },
  {
    name: 'Availabilities',
    showInSidebar: true,
    path: '/availabilities',

    permissionLevels: [0, 1, 10],
    children: [
      {
        name: 'View',
        path: '/availabilities/tech',
        component: AvailabilityCreatePageTech,
        layout: DashboardLayout,
        showInSidebar: true,
        permissionLevels: [0],
      },
      {
        name: 'View',
        path: '/availabilities/sup',
        component: ViewAllAvailabilities,
        layout: DashboardLayout,
        showInSidebar: true,
        permissionLevels: [1, 10],
      },
      {
        name: 'View Specific Availability',
        path: '/availabilities/:id',
        component: AvailabilityCreatePageSup,
        layout: DashboardLayout,
        showInSidebar: false,
        permissionLevels: [1, 10],
      },
    ],
  },

  {
    name: 'Reports',
    showInSidebar: true,
    permissionLevels: [1, 10],
    layout: DashboardLayout,
    path: '/reports',
    component: ReportsContainerPage,
  },

  {
    name: 'Admin',
    path: '/admin',
    showInSidebar: true,
    permissionLevels: [10],

    children: [
      {
        name: 'Users',
        path: '/admin/users',
        component: UsersPage,
        layout: DashboardLayout,
        showInSidebar: true,
        permissionLevels: [10], // 10 = admin
      },
      {
        name: 'Users',
        path: '/admin/users/edit/:id',
        component: UserEditPage,
        layout: DashboardLayout,
        showInSidebar: false,
        permissionLevels: [10], // 10 = admin
      },
      {
        name: 'Users',
        path: '/admin/users/new',
        component: UserNewPage,
        layout: DashboardLayout,
        showInSidebar: false,
        permissionLevels: [10], // 10 = admin
      },
      {
        name: 'Buildings',
        layout: DashboardLayout,
        path: '/admin/buildings',
        component: ListBuildings,
        showInSidebar: true,
        permissionLevels: [10],
      },
      {
        name: 'View Building',
        layout: DashboardLayout,
        path: '/admin/buildings/:id',
        component: ViewBuilding,
        showInSidebar: false,
        permissionLevels: [10],
      },
      {
        name: 'Buildings',
        layout: DashboardLayout,
        path: '/admin/buildings/edit/:id',
        component: EditBuilding,
        showInSidebar: false,
        permissionLevels: [10],
      },

      {
        name: 'Settings',
        path: '/admin/settings',
        component: SettingsPage,
        layout: DashboardLayout,
        showInSidebar: true,
        permissionLevels: [10], // 10 = admin
      },
    ],
  },
  {
    name: 'Authentication',
    path: '/auth',
    showInSidebar: false,
    children: [
      {
        name: 'Login',
        path: '/auth/login',
        component: LoginPage,
        layout: AuthLayout,
        showInSidebar: false,
        secure: false,
      },
      {
        name: 'Change password',
        path: '/auth/users/:id/change_password',
        showInSidebar: false,
        component: ChangePasswordPage,
        layout: DashboardLayout,
        permissionLevels: [0, 1, 10],
        secure: true,
      },
      {
        name: 'Change phone number',
        path: '/auth/users/:id/change_phone_number',
        showInSidebar: false,
        component: ChangePhoneNumberPage,
        layout: DashboardLayout,
        permissionLevels: [0],
        secure: true,
      },
    ],
  },
];

export default routes;
