import React, { useState, useEffect } from "react";
import { Link, Prompt, withRouter } from "react-router-dom";
import API from "../../api/index";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { toastr } from "react-redux-toastr";
import Select from "react-select";

import {
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Button,
  Row,
  Col,
  Label,
  Input,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
} from "reactstrap";

const Settings = ({ history }) => {
  const [isDataComplete, setIsDataComplete] = useState(false);
  const [techLevels, setTechLevels] = useState([]);
  const [settings, setSettings] = useState({
    SMS_CONFIRMATION_1_DELAY: "",
    SMS_CONFIRMATION_2_DELAY: "",
    appointment_reservation_expiration_duration: "",
    RECOMMENDATION_SAME_BUILDING_DURATION: "",
    RECOMMENDATION_NEARBY_BUILDING_DURATION: "",
    RECOMMENDATION_NEARBY_DISTANCE: "",
    speed_adequate_acceptable_margin: "",
    appointment_problems: [],
  });
  const getSettings = () => {
    API.get("/settings")
      .then((res) => setSettings(res.data))
      .catch((err) => toastr.error(`${err.response.data.errors}`));
  };

  const handleSettingsChange = (name, value) => {
    setSettings((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAppointmentProblemsChange = (e, name, idx) => {
    settings.appointment_problems[idx][name] = e.target.value;
    setSettings((prevState) => ({
      ...prevState,
    }));
  };

  const handleSubmitSettings = (params) => {
    if (settingsValidation(params)) {
      return;
    }
    if (window.confirm("Are you sure you want to submit?")) {
      setIsDataComplete(true);
      API.put("/settings", params)
        .then((res) => {
          setSettings(res.data);
          toastr.success("Successfully changed settings");
          history.push("/dashboard");
        })
        .catch((err) => toastr.error(`${err.response.data.errors}`));
    }
  };

  const settingsValidation = (data) => {
    const problemNumbers = [];
    settings.appointment_problems.forEach((pblm, idx) => {
      if (pblm.name === "" || pblm.tech_level.length === 0) {
        problemNumbers.push(idx + 1);
      }
    });
    if (
      data.SMS_CONFIRMATION_1_DELAY === "" ||
      data.SMS_CONFIRMATION_1_DELAY.length > 5
    ) {
      return toastr.error(
        "Text message confirmation 1 cannot be more than 5 digits or empty"
      );
    }
    if (
      data.SMS_CONFIRMATION_2_DELAY === "" ||
      data.SMS_CONFIRMATION_2_DELAY.length > 5
    ) {
      return toastr.error(
        "Text message confirmation 2 cannot be more than 5 digits or empty"
      );
    }
    if (
      data.appointment_reservation_expiration_duration === "" ||
      data.appointment_reservation_expiration_duration.length > 5
    ) {
      return toastr.error(
        "Appointment reservation expiration duration cannot be more than 5 digits or empty"
      );
    }
    if (
      data.RECOMMENDATION_SAME_BUILDING_DURATION === "" ||
      data.RECOMMENDATION_SAME_BUILDING_DURATION.length > 5
    ) {
      return toastr.error(
        "Recommendation same building duration cannot be more than 5 digits or empty"
      );
    }
    if (
      data.RECOMMENDATION_NEARBY_BUILDING_DURATION === "" ||
      data.RECOMMENDATION_NEARBY_BUILDING_DURATION.length > 5
    ) {
      return toastr.error(
        "Recommendation nearby building duration cannot be more than 5 digits or empty"
      );
    }
    if (
      data.RECOMMENDATION_NEARBY_DISTANCE === "" ||
      data.RECOMMENDATION_NEARBY_DISTANCE.length > 5
    ) {
      return toastr.error(
        "Recommended nearby distance cannot be more than 5 digits or empty"
      );
    }
    if (
      data.speed_adequate_acceptable_margin === 0 ||
      data.speed_adequate_acceptable_margin > 5
    ) {
      return toastr.error(
        "Speed adequate acceptable margin cannot be more than 500% or be 0%"
      );
    }
    if (problemNumbers.length > 0) {
      return toastr.error(`Please fix problem numbers ${problemNumbers}`);
    }
  };

  useEffect(() => {
    getSettings();
    API.get("/auth/tech_levels")
      .then((res) => setTechLevels(res.data))
      .catch(() => toastr.error("Could not get tech levels"));
  }, []);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Edit Settings</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Settings</BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Card>
        <CardHeader tag="h4">Edit Settings</CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Text Message Confirmation Delay 1</Label>
                  <Row>
                    <Col md="6">
                      <InputGroup>
                        <Input
                          name="SMS_CONFIRMATION_1_DELAY"
                          type="number"
                          value={settings.SMS_CONFIRMATION_1_DELAY}
                          onChange={(e) =>
                            handleSettingsChange(e.target.name, e.target.value)
                          }
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>Minutes</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>Text Message Confirmation Delay 2</Label>
                  <Row>
                    <Col md="6">
                      <InputGroup>
                        <Input
                          name="SMS_CONFIRMATION_2_DELAY"
                          type="number"
                          value={settings.SMS_CONFIRMATION_2_DELAY}
                          onChange={(e) =>
                            handleSettingsChange(e.target.name, e.target.value)
                          }
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>Minutes</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>Appointment Reservation Expiration Duration</Label>
                  <Row>
                    <Col md="6">
                      <InputGroup>
                        <Input
                          name="appointment_reservation_expiration_duration"
                          type="number"
                          value={
                            settings.appointment_reservation_expiration_duration
                          }
                          onChange={(e) =>
                            handleSettingsChange(e.target.name, e.target.value)
                          }
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>Minutes</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>Recommendation Same Building Duration</Label>
                  <Row>
                    <Col md="6">
                      <InputGroup>
                        <Input
                          name="RECOMMENDATION_SAME_BUILDING_DURATION"
                          type="number"
                          value={settings.RECOMMENDATION_SAME_BUILDING_DURATION}
                          onChange={(e) =>
                            handleSettingsChange(e.target.name, e.target.value)
                          }
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>Minutes</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>Recommendation Nearby Building Duration</Label>
                  <Row>
                    <Col md="6">
                      <InputGroup>
                        <Input
                          name="RECOMMENDATION_NEARBY_BUILDING_DURATION"
                          type="number"
                          value={
                            settings.RECOMMENDATION_NEARBY_BUILDING_DURATION
                          }
                          onChange={(e) =>
                            handleSettingsChange(e.target.name, e.target.value)
                          }
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>Minutes</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>Recommendation Nearby Distance</Label>
                  <Row>
                    <Col md="6">
                      <InputGroup>
                        <Input
                          name="RECOMMENDATION_NEARBY_DISTANCE"
                          type="number"
                          value={settings.RECOMMENDATION_NEARBY_DISTANCE}
                          onChange={(e) =>
                            handleSettingsChange(e.target.name, e.target.value)
                          }
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>Miles</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>Speed Adequate Acceptable Margin</Label>
                  <Row>
                    <Col md="6">
                      <InputGroup>
                        <Input
                          name="speed_adequate_acceptable_margin"
                          type="number"
                          value={
                            settings.speed_adequate_acceptable_margin * 100
                          }
                          onChange={(e) =>
                            handleSettingsChange(
                              e.target.name,
                              e.target.value / 100
                            )
                          }
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>%</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Label>Appointment Problems</Label>
                <Table>
                  <thead>
                    <tr>
                      <th>Number</th>
                      <th>Problem Name</th>
                      <th>Tech Level Required</th>
                      <th>Add / Del</th>
                    </tr>
                  </thead>
                  <tbody>
                    {settings.appointment_problems.map((problem, idx) => {
                      return (
                        <tr key={idx}>
                          <th>{idx + 1}</th>
                          <th>
                            <Input
                              value={problem.name}
                              onChange={(e) =>
                                handleAppointmentProblemsChange(e, "name", idx)
                              }
                            />
                          </th>
                          <th>
                            <Select
                              options={techLevels}
                              isMulti
                              value={problem.tech_level.map((lvl) => {
                                const obj = {
                                  label: lvl,
                                  value: lvl,
                                };
                                return obj;
                              })}
                              onChange={(e) => {
                                const techs = e.map((x) => {
                                  return x.value;
                                });

                                problem.tech_level = techs;
                                setSettings((prevState) => ({
                                  ...prevState,
                                }));
                              }}
                            />
                          </th>
                          <th>
                            <Button
                              color="outline-danger"
                              onClick={() => {
                                if (problem.id == 99) {
                                  return toastr.error(
                                    "Not allowed to delete this problem"
                                  );
                                }
                                let array = settings.appointment_problems;
                                array.splice(idx, 1);
                                setSettings((prevState) => ({
                                  ...prevState,
                                  appointment_problems: [...array],
                                }));
                              }}
                            >
                              −
                            </Button>

                            {idx + 1 ===
                            settings.appointment_problems.length ? (
                              <Button
                                className="ml-3"
                                color="outline-success"
                                onClick={() =>
                                  setSettings((prevState) => ({
                                    ...prevState,
                                    appointment_problems: [
                                      ...settings.appointment_problems,
                                      {
                                        name: "",
                                        tech_level: [],
                                        id: Math.floor(
                                          Math.random() * 100000000
                                        ),
                                      },
                                    ],
                                  }))
                                }
                              >
                                +
                              </Button>
                            ) : null}
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button onClick={() => handleSubmitSettings(settings)}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      <Prompt
        when={!isDataComplete}
        message="You have unsaved changes, are you sure you want to leave this page?"
      />
    </Container>
  );
};

export default withRouter(Settings);
