import React, { useEffect, useState } from "react";
import { Link, withRouter, useParams, Prompt } from "react-router-dom";
import {
  AvForm,
  AvFeedback,
  AvInput,
  AvGroup,
  AvField,
} from "availity-reactstrap-validation";
import InputMask from "react-input-mask";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Select from "react-select";

import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Label,
  FormGroup,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  ButtonGroup,
} from "reactstrap";
import moment from "moment";
import "moment-timezone";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import API from "../../api/index";
import { toastr } from "react-redux-toastr";
import AnnotatedText from "../../components/AnnotatedText";
import { decodeToken } from "../../helpers/TokenDecode";

const ResolutionForm = ({
  handlePutResolution,
  appointment,
  handleCheckInClick,
  handleCheckOutClick,
  authToken,
  routerTypes,
  handleCustNoShow,
  custNoShow,
  res,
  isEdit = false,
  handleEditResolution,
}) => {
  const [formData, setFormData] = useState({
    ap_mac_address: "",
    status: "",
    description: "",
    customer_speed_wireless_down: "",
    customer_speed_wireless_up: "",
    customer_speed_wired_up: "",
    customer_speed_wired_down: "",
    resolved_by: decodeToken(authToken).id,
    was_new_ap_installed: false,
    ip_address: "",
    vlan: "",
    was_router_installed: false,
    ssid: "",
    wifi_password: "",
    router_mac_address: "",
    router_type: "",
  });

  const handleFormDataChange = (e) => {
    e.persist();
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (isEdit) {
      if (res && res.status) {
        res.status === "success" ? (res.status = true) : (res.status = false);
      }
      setFormData(res);
    }
  }, [res]);

  const resolution = appointment.resolution;

  return (
    <Card>
      <CardHeader tag="h4">Resolve Your Appointment</CardHeader>
      <CardBody>
        {isEdit ? null : (
          <Row>
            <Col>
              <FormGroup className="pb-4">
                <Label tag="h5">
                  Were you able to gain access to the unit?
                </Label>
                <Row>
                  <Col>
                    <BootstrapSwitchButton
                      onlabel="No"
                      offlabel="Yes"
                      size="lg"
                      onstyle="danger"
                      onChange={(checked) => handleCustNoShow(checked)}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
        )}

        {custNoShow || isEdit ? null : (
          <Row className="mb-3">
            {resolution && resolution.check_in ? (
              <Col>
                <AnnotatedText annotation="Checked in at">
                  {moment(resolution.check_in).format("M/D h:mm A")}
                </AnnotatedText>
              </Col>
            ) : (
              <Col>
                <Button onClick={handleCheckInClick}>Check in</Button>
              </Col>
            )}
            {resolution && resolution.check_out ? (
              <Col>
                <AnnotatedText annotation="Checked out at">
                  {moment(resolution.check_out).format("M/D h:mm A")}
                </AnnotatedText>
              </Col>
            ) : (
              <Col>
                <Button onClick={handleCheckOutClick}>Check Out</Button>
              </Col>
            )}
          </Row>
        )}

        <AvForm>
          {custNoShow ? null : (
            <>
              <Row>
                <Col xs="12" md="6">
                  <FormGroup>
                    <Label tag="h5">Customer Speed Wired</Label>
                    <Row>
                      <Col xs="12">
                        <AvGroup>
                          <Row className="mb-3">
                            <Col xs="3">Up</Col>
                            <Col>
                              <InputGroup>
                                <AvInput
                                  value={formData.customer_speed_wired_up}
                                  maxLength={4}
                                  inputMode="decimal"
                                  type="number"
                                  pattern="[0-9]*"
                                  name="customer_speed_wired_up"
                                  onChange={(e) => {
                                    setFormData((prevState) => ({
                                      ...prevState,
                                      customer_speed_wired_up: e.target.value,
                                    }));
                                  }}
                                />

                                <InputGroupAddon addonType="append">
                                  <InputGroupText>Mbps</InputGroupText>
                                </InputGroupAddon>
                                <AvFeedback>
                                  Can only have 4 characters
                                </AvFeedback>
                              </InputGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="3">Down</Col>
                            <Col>
                              <InputGroup>
                                <AvInput
                                  value={formData.customer_speed_wired_down}
                                  maxLength={4}
                                  inputMode="decimal"
                                  type="number"
                                  pattern="[0-9]*"
                                  name="customer_speed_wired_down"
                                  onChange={(e) => {
                                    setFormData((prevState) => ({
                                      ...prevState,
                                      customer_speed_wired_down: e.target.value,
                                    }));
                                  }}
                                />

                                <InputGroupAddon addonType="append">
                                  <InputGroupText>Mbps</InputGroupText>
                                </InputGroupAddon>
                                <AvFeedback>
                                  Can only have 4 characters
                                </AvFeedback>
                              </InputGroup>
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>

                <Col xs="12" md="6">
                  <FormGroup>
                    <Label tag="h5">Customer Speed Wireless</Label>
                    <AvGroup>
                      <Row className="mb-3">
                        <Col xs="3">Up</Col>
                        <Col>
                          <InputGroup>
                            <AvInput
                              value={formData.customer_speed_wireless_up}
                              maxLength={4}
                              inputMode="decimal"
                              type="number"
                              pattern="[0-9]*"
                              name="customer_speed_wireless_up"
                              onChange={(e) => {
                                setFormData((prevState) => ({
                                  ...prevState,
                                  customer_speed_wireless_up: e.target.value,
                                }));
                              }}
                            />

                            <InputGroupAddon addonType="append">
                              <InputGroupText>Mbps</InputGroupText>
                            </InputGroupAddon>
                            <AvFeedback>Can only have 4 characters</AvFeedback>
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="3">Down</Col>
                        <Col>
                          <InputGroup>
                            <AvInput
                              value={formData.customer_speed_wireless_down}
                              maxLength={4}
                              inputMode="decimal"
                              type="number"
                              pattern="[0-9]*"
                              name="customer_speed_wireless_down"
                              onChange={(e) => {
                                setFormData((prevState) => ({
                                  ...prevState,
                                  customer_speed_wireless_down: e.target.value,
                                }));
                              }}
                            />

                            <InputGroupAddon addonType="append">
                              <InputGroupText>Mbps</InputGroupText>
                            </InputGroupAddon>
                            <AvFeedback>Can only have 4 characters</AvFeedback>
                          </InputGroup>
                        </Col>
                      </Row>
                    </AvGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label tag="h5">Did you install a new system AP?</Label>
                    <Row>
                      <Col>
                        <BootstrapSwitchButton
                          onlabel="Yes"
                          offlabel="No"
                          checked={formData.was_new_ap_installed}
                          value={formData.was_new_ap_installed}
                          onChange={(checked) => {
                            setFormData((prevState) => ({
                              ...prevState,
                              was_new_ap_installed: checked,
                              ap_mac_address: "",
                              ip_address: "",
                              vlan: "",
                            }));
                          }}
                          size="lg"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
              {formData.was_new_ap_installed ? (
                <>
                  <Row>
                    <Col xs="12" md="4">
                      <FormGroup>
                        <Label for="ap_mac_address">AP Mac Address</Label>
                        <AvField
                          name="ap_mac_address"
                          required={formData.was_new_ap_installed}
                          mask="**:**:**:**:**:**"
                          alwaysShowMask={true}
                          value={formData.ap_mac_address}
                          tag={[Input, InputMask]}
                          onChange={(e) => {
                            let number = e.target.value;
                            let replacedNumber = number.replace(
                              /:|_|-|\./gi,
                              ""
                            );
                            setFormData((prevState) => ({
                              ...prevState,
                              ap_mac_address: replacedNumber,
                            }));
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="4">
                      <FormGroup>
                        <Label>IP Address</Label>
                        <AvField
                          name="ip_address"
                          value={formData.ip_address}
                          required={formData.was_new_ap_installed}
                          tag={[Input, InputMask]}
                          alwaysShowMask={true}
                          validate={{
                            pattern: {
                              value: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
                            },
                          }}
                          placeholder="192.168.1.1"
                          onChange={(e) =>
                            setFormData((prevState) => ({
                              ...prevState,
                              ip_address: e.target.value,
                            }))
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="4">
                      <FormGroup>
                        <Label>VLAN</Label>
                        <AvField
                          name="vlan"
                          value={formData.vlan}
                          required={formData.was_new_ap_installed}
                          validate={{
                            pattern: {
                              value: /^[0-9]{1,4}$/,
                            },
                            maxLength: { value: 4 },
                          }}
                          onChange={(e) =>
                            setFormData((prevState) => ({
                              ...prevState,
                              vlan: e.target.value,
                            }))
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              ) : null}

              <Row>
                <Col>
                  <FormGroup className="pb-4">
                    <Label tag="h5">Was router installed?</Label>
                    <Row>
                      <Col>
                        <BootstrapSwitchButton
                          onlabel="Yes"
                          offlabel="No"
                          checked={formData.was_router_installed}
                          value={formData.was_router_installed}
                          onChange={(checked) => {
                            setFormData((prevState) => ({
                              ...prevState,
                              was_router_installed: checked,
                              router_mac_address: "",
                              ssid: "",
                              wifi_password: "",
                              router_type: "",
                            }));
                          }}
                          size="lg"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
              {formData.was_router_installed ? (
                <>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Router Type</Label>
                        <Select
                          options={routerTypes}
                          onChange={(e) =>
                            setFormData({ ...formData, router_type: e.value })
                          }
                          value={routerTypes.find(
                            (type) => type.value == formData.router_type
                          )}
                          name="router_type"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="4">
                      <FormGroup>
                        <Label for="router_mac_address">
                          Router Mac Address
                        </Label>
                        <AvField
                          name="router_mac_address"
                          required={formData.was_router_installed}
                          mask="**:**:**:**:**:**"
                          alwaysShowMask={true}
                          value={formData.router_mac_address}
                          tag={[Input, InputMask]}
                          onChange={(e) => {
                            let number = e.target.value;
                            let replacedNumber = number.replace(
                              /:|_|-|\./gi,
                              ""
                            );
                            setFormData((prevState) => ({
                              ...prevState,
                              router_mac_address: replacedNumber,
                            }));
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="4">
                      <FormGroup>
                        <Label>SSID</Label>
                        <AvField
                          name="ssid"
                          required={formData.was_router_installed}
                          value={formData.ssid}
                          onChange={(e) =>
                            setFormData((prevState) => ({
                              ...prevState,
                              ssid: e.target.value,
                            }))
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="4">
                      <FormGroup>
                        <Label>WiFi Password</Label>
                        <AvField
                          name="wifi_password"
                          value={formData.wifi_password}
                          required={formData.was_router_installed}
                          onChange={(e) =>
                            setFormData((prevState) => ({
                              ...prevState,
                              wifi_password: e.target.value,
                            }))
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              ) : null}
            </>
          )}

          <Row>
            <Col xs="12">
              <FormGroup>
                <Label tag="h5">Description</Label>
                <Input
                  type="textarea"
                  value={formData.description}
                  onChange={(e) => handleFormDataChange(e)}
                  name="description"
                />
              </FormGroup>
            </Col>
          </Row>

          {custNoShow ? null : (
            <Row>
              <Col>
                <FormGroup className="pb-4">
                  <Label>Was problem solved?</Label>
                  <Row>
                    <Col>
                      <ButtonGroup size="lg">
                        <Button
                          outline={!(formData.status === true)}
                          color="success"
                          onClick={() =>
                            setFormData((prevState) => ({
                              ...prevState,
                              status: true,
                            }))
                          }
                          value={true}
                        >
                          Yes
                        </Button>

                        <Button outline={!(formData.status === "")} disabled>
                          N/A
                        </Button>
                        <Button
                          outline={!(formData.status === false)}
                          color="danger"
                          onClick={(e) =>
                            setFormData((prevState) => ({
                              ...prevState,
                              status: false,
                            }))
                          }
                          value={false}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row>
            <Col
              xs="12"
              className={window.innerWidth <= 760 ? "fixed-bottom" : null}
            >
              <FormGroup>
                <Button
                  className="btn-block"
                  onClick={
                    isEdit
                      ? () => handleEditResolution(formData)
                      : () => handlePutResolution(formData)
                  }
                >
                  Save
                </Button>
              </FormGroup>
            </Col>
          </Row>
          <Row className="pb-4"></Row>
        </AvForm>
      </CardBody>
    </Card>
  );
};

const FormContainer = ({ authToken, res, isEdit, ...props }) => {
  let { id } = useParams();
  const [appointment, setAppointment] = useState({});
  const [routerTypes, setRouterTypes] = useState([]);

  const getSingleAppointment = () => {
    API.get(`/appointments/${id}`)
      .then((res) => {
        setAppointment(res.data);
      })
      .catch((err) => toastr.error("Error getting the appointment"));
  };

  const getRouterTypes = () => {
    API.get("/api/router_types")
      .then((res) => {
        const mappedRouterTypes = res.data.map((route) => {
          route.label = route.name;
          route.value = route.id;
          return route;
        });
        setRouterTypes(mappedRouterTypes);
      })
      .catch((err) => toastr.error("Error getting router types"));
  };

  const resolutionValidation = (data) => {
    if (
      data.customer_speed_wired_up === null ||
      data.customer_speed_wired_up === "" ||
      data.customer_speed_wired_up == " " ||
      (data.customer_speed_wired_up && data.customer_speed_wired_up.length > 4)
    ) {
      return toastr.error("Please enter a valid customer wired up speed");
    }
    if (
      data.customer_speed_wired_down === null ||
      data.customer_speed_wired_down === "" ||
      data.customer_speed_wired_down === " " ||
      (data.customer_speed_wired_down &&
        data.customer_speed_wired_down.length > 4)
    ) {
      return toastr.error("Please enter a valid customer wired down speed");
    }
    if (
      data.customer_speed_wireless_up === null ||
      data.customer_speed_wireless_up === "" ||
      data.customer_speed_wireless_up === " " ||
      (data.customer_speed_wireless_up &&
        data.customer_speed_wireless_up.length > 4)
    ) {
      return toastr.error("Please enter a valid customer wireless up speed");
    }
    if (
      data.customer_speed_wireless_down === null ||
      data.customer_speed_wireless_down === "" ||
      data.customer_speed_wireless_down === " " ||
      (data.customer_speed_wireless_down &&
        data.customer_speed_wireless_down.length > 4)
    ) {
      return toastr.error("Please enter a valid customer wireless down speed");
    }
    if (data.was_new_ap_installed && data.ap_mac_address === "") {
      return toastr.error("Please enter a valid AP Mac address");
    }
    if (data.was_new_ap_installed && data.ip_address === "") {
      return toastr.error("Please enter a valid IP Address");
    }
    if (data.was_new_ap_installed && data.vlan === "") {
      return toastr.error("Please enter a valid VLAN");
    }
    if (data.was_router_installed && data.router_mac_address === "") {
      return toastr.error("Please enter a valid mac address");
    }
    if (data.was_router_installed && data.ssid === "") {
      return toastr.error("Please enter a valid SSID");
    }
    if (data.was_router_installed && data.wifi_password === "") {
      return toastr.error("Please enter a valid WiFi Password");
    }
    if (data.was_router_installed && data.router_type === "") {
      return toastr.error("Please select the type of router installed");
    }
    if (data.status === "") {
      return toastr.error("Did you resolve the issue?");
    }
    if (data.description === "") {
      return toastr.error("Please enter a description");
    }
  };

  const [custNoShow, setCustNoShow] = useState(false);

  const handleCustNoShow = (checked) => {
    setCustNoShow(checked);
  };

  const [isDataComplete, setIsDataComplete] = useState(false);

  const handlePutResolution = (data) => {
    if (custNoShow) {
      if (window.confirm("Were you able to gain access to the unit?")) {
        //do something
        setIsDataComplete(true);
        const formData = {
          ap_mac_address: null,
          status: 1,
          description: `Technician unable to gain access to the unit. ${data.description}`,
          customer_speed_wireless_down: null,
          customer_speed_wireless_up: null,
          customer_speed_wired_up: null,
          customer_speed_wired_down: null,
          resolved_by: decodeToken(authToken).id,
          was_new_ap_installed: null,
          ip_address: "",
          vlan: "",
          was_router_installed: null,
          ssid: "",
          wifi_password: "",
          router_mac_address: "",
          router_type: "",
        };

        API.put(`/appointments/${id}/resolve`, formData)
          .then((res) => {
            toastr.success("Successfully Resolved");
            props.history.push(`/dashboard`);
          })
          .catch((err) => {
            toastr.error("Error trying to save resolution");
          });
      }
    } else {
      if (resolutionValidation(data)) {
        return;
      }

      if (window.confirm("Are you sure you want to submit?")) {
        setIsDataComplete(true);
        data.status === true ? (data.status = 0) : (data.status = 1);

        API.put(`/appointments/${id}/resolve`, data)
          .then((res) => {
            toastr.success("Successfully Resolved");
            props.history.push(`/dashboard`);
          })
          .catch((err) => {
            toastr.error("Error trying to save resolution");
          });
      }
    }
  };

  const handleCheckInClick = () => {
    API.put(`/appointments/${id}/check_in`)
      .then((res) => {
        toastr.success("You have been checked-in");
        getSingleAppointment();
      })
      .catch((err) => {
        toastr.error("Please try to check in again");
      });
  };

  const handleCheckOutClick = () => {
    API.put(`/appointments/${id}/check_out`)
      .then((res) => {
        toastr.success("You have been checked-out");
        getSingleAppointment();
      })
      .catch((err) => {
        toastr.error("Please try to check out again");
      });
  };

  const handleEditResolution = (data) => {
    if (resolutionValidation(data)) {
      return;
    }

    if (window.confirm("Are you sure you want to submit?")) {
      setIsDataComplete(true);
      data.status === true ? (data.status = 0) : (data.status = 1);
      data.was_new_ap_installed === true
        ? (data.was_new_ap_installed = true)
        : (data.was_new_ap_installed = "false");
      data.was_router_installed === true
        ? (data.was_router_installed = true)
        : (data.was_router_installed = "false");

      API.put(`/resolutions/${res.id}`, data)
        .then((res) => {
          toastr.success("Successfully Resolved");
          props.history.push(`/dashboard`);
        })
        .catch((err) => {
          toastr.error("Error trying to save resolution");
        });
    }
  };

  useEffect(() => {
    if (isEdit && res && res.appointment) {
      API.get(`/appointments/${res.appointment.id}`)
        .then((res) => {
          setAppointment(res.data);
        })
        .catch((err) => toastr.error("Error getting the appointment"));
    } else if (!window.location.pathname.includes("resolutions/edit/")) {
      getSingleAppointment();
    }

    getRouterTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEdit, res]);

  const [showRes, setShowRes] = useState(true);

  useEffect(() => {
    if (isEdit) {
      const today = new Date().setHours(0, 0, 0, 0);
      let start_date = new Date().setHours(0, 0, 0, 0);
      let techId;
      if (res && res.appointment && res.appointment.confirmed_reservation) {
        start_date = new Date(
          res.appointment.confirmed_reservation.start_date
        ).setHours(0, 0, 0, 0);
        techId = res.appointment.confirmed_reservation.technician.id;
      }

      if (start_date !== today) {
        setShowRes(false);
        props.history.push("/dashboard");
      }
    }
  }, [isEdit, appointment]);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Appointment Resolution</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/appointments">Appointments</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link
              to={
                isEdit
                  ? `/appointments/view/${appointment.id}`
                  : `/appointments/view/${id}`
              }
            >
              This Appointment
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            Resolve {appointment.ticket_number}
          </BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Card>
        <CardHeader tag="h4">Customer Information</CardHeader>
        <CardBody>
          <AnnotatedText annotation="Customer Name">
            {appointment.customer ? appointment.customer.first_name : null}{" "}
            {appointment.customer ? appointment.customer.last_name : null}
          </AnnotatedText>
          <AnnotatedText annotation="Purchase Speed">
            {appointment.customer ? appointment.customer.purchase_speed : 0}{" "}
            Mbps /{" "}
            {appointment.customer ? appointment.customer.purchase_speed : 0}{" "}
            Mbps
          </AnnotatedText>
          <AnnotatedText annotation="Activation Key">
            {appointment.customer ? appointment.customer.activation_key : null}
          </AnnotatedText>
          <AnnotatedText annotation="Building">
            {appointment.customer
              ? appointment.customer.building.shortdesc
              : null}
          </AnnotatedText>
          <AnnotatedText annotation="Address">
            <p>
              {appointment.customer
                ? appointment.customer.building.street1
                : null}
              , Unit{" "}
              {appointment.customer ? appointment.customer.apartment : null}
            </p>
            <p>
              {appointment.customer
                ? appointment.customer.building.street2
                : null}
            </p>
          </AnnotatedText>
          <AnnotatedText annotation="Created By">
            {appointment &&
              appointment.created_by &&
              appointment.created_by.username}
          </AnnotatedText>
        </CardBody>
      </Card>
      {showRes ? (
        <ResolutionForm
          handlePutResolution={handlePutResolution}
          appointment={appointment}
          handleCheckInClick={handleCheckInClick}
          handleCheckOutClick={handleCheckOutClick}
          authToken={authToken}
          routerTypes={routerTypes}
          handleCustNoShow={handleCustNoShow}
          custNoShow={custNoShow}
          res={res}
          isEdit={isEdit}
          handleEditResolution={handleEditResolution}
        />
      ) : (
        <h1 className="text-center">Can only edit resolution on same day</h1>
      )}
      <Prompt
        when={!isDataComplete}
        message="You have unsaved changes, are you sure you want to leave this page?"
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    authToken: state.auth.authToken,
    refreshToken: state.auth.refreshToken,
  };
};

export default withRouter(connect(mapStateToProps)(FormContainer));
