import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, Redirect } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  CustomInput
} from "reactstrap";

import { authLogin } from "../../redux/actions/authActions";

const Login = props => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = e => {
    e.preventDefault();
    props.performLogin(username, password);
  };

  return props.isAuthenticated ? (
    <Redirect to={"/"} />
  ) : (
      <>
        <div className="text-center mt-4">
          <h2>Appointment System</h2>
          <p className="lead">Please sign in to continue</p>
        </div>

        <Card>
          <CardBody>
            <div className="m-sm-4">
              <div className="text-center">
                <Form>
                  <FormGroup>
                    <Input
                      bsSize="lg"
                      type="text"
                      name="username"
                      placeholder="Username"
                      onChange={e => setUsername(e.target.value)}
                      value={username}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      bsSize="lg"
                      type="password"
                      name="password"
                      placeholder="Password"
                      onChange={e => setPassword(e.target.value)}
                      value={password}
                    />
                    <small>
                      <Link to="/auth/reset-password">Forgot my password</Link>
                    </small>
                  </FormGroup>
                  <div>
                    <CustomInput
                      type="checkbox"
                      id="rememberMe"
                      label="Remember me next time"
                      defaultChecked
                    />
                  </div>
                  <div className="text-center mt-3">
                    <Button
                      type="submit"
                      onClick={handleLogin}
                      color="primary"
                      size="lg"
                    >
                      Sign in
                  </Button>
                  </div>
                </Form>
              </div>
            </div>
          </CardBody>
        </Card>
      </>
    );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    authToken: state.auth.authToken,
    refreshToken: state.auth.refreshToken
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      performLogin: authLogin
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
