import React, { useEffect, useState } from 'react';

import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';
import API from '../../api';
import { toastr } from 'react-redux-toastr';
import { calendarStatusColor } from '../../components/AppointmentStatusIndicator';
import { decodeToken } from '../../helpers/TokenDecode';
import { withRouter } from 'react-router-dom';

const CalendarComponent = ({
  focusAppointment,
  inject,
  centerDate,
  showTechLevels,
  isTech = false,
  authToken,
  handleReservationChange,
  isCreatingAppointment = false,
  history,
  ...props
}) => {
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [freeAvailability, setFreeAvailability] = useState([]);
  const [techs, setTechs] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [visibleDates, setVisibleDates] = useState({
    startDate: null,
    endDate: null,
  })

  let calendarRef = React.createRef();

  const resourceGroupText = (resourceGroupField) =>
    `Tech Level ${resourceGroupField}`;

  const goToDate = (date) => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(date);
  };

  /* TODO: This loads all of the appointments, causing a performance issue */
  /* Need to change this to only load appointments from a desired time window */
  const fetchAppointments = () => {
    if (visibleDates.startDate != null && visibleDates.endDate != null) {
      API.get('/appointments', { params: { start_date: visibleDates.startDate, end_date: visibleDates.endDate } })
        .then((res) => {
          /* if there is no reservation or confirmed reservation, skip adding it to the calendar */
          let appointments = res.data.filter(
            (appt) => appt.reservation || appt.confirmed_reservation
          );
          if (isTech) {
            appointments = appointments.filter(
              (appt) => appt.status == 'confirmed' || 'resolved'
            );
          }

          const mappedAppointments = appointments.map((appt) => {
            const res = appt.reservation || appt.confirmed_reservation;
            const isFocused = focusAppointment == appt.id;

            return {
              id: appt.id,
              appointment_id: appt.id,
              color: isFocused ? '#FA8072' : calendarStatusColor(appt),
              start: res.start_date,
              end: res.end_date,
              title: `${appt.customer.first_name} ${appt.customer.last_name} [${appt.status}]\n${appt.customer.building.shortdesc}`,
              groupId: res.technician.tech_level,
              resourceId: res.technician.id,
              textColor: '#000000',
              classNames: ['bold'],
            };
          });
          setAppointments(mappedAppointments);
        })
        .catch((err) => {
          toastr.error('Error loading appointments');
        });
    }
  };

  const fetchFreeAvailability = () => {
    API.get('availability/free')
      .then((res) => {
        let completeFreeList = [];
        res.data.forEach((tech) => {
          const techFreeList = tech.free.map((availability) => {
            availability.technician_id = tech.id;
            availability.resourceId = tech.id;
            availability.start = availability.start_date;
            availability.end = availability.end_date;
            availability.title = 'Available';
            availability.color = '#90EE90';
            availability.groupId = tech.id;
            availability.textColor = '#000000';
            availability.classNames = ['bold'];
            return availability;
          });
          completeFreeList = completeFreeList.concat(techFreeList);
        });
        setFreeAvailability(completeFreeList);
      })
      .catch((err) => {
        toastr.error('Error loading free availability');
      });
  };

  const fetchTechs = () => {
    API.get('/users')
      .then((res) => {
        let users = res.data.filter(
          (user) => user.permission_level === 0 && user.is_active
        );
        users = users.map((user) => ({
          title: user.name,
          id: user.user_id,
          label: user.name,
          value: user.user_id,
          tech_level: user.tech_level,
        }));
        if (showTechLevels)
          users = users.filter((user) =>
            showTechLevels.includes(user.tech_level)
          );
        setTechs(users);
      })
      .catch((err) => {
        toastr.error('Error loading users');
      });
  };

  const setUsersToSingleTech = () => {
    const token = decodeToken(authToken);
    const user = {
      title: token.name,
      id: token.id,
      label: token.name,
      value: token.id,
      tech_level: token.tech_level,
    };
    setTechs([user]);
  };

  /* initial load */
  useEffect(() => {
    fetchFreeAvailability();
    fetchAppointments();
    if (isTech) {
      return;
    } else {
      fetchTechs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("Fetch new appointments", visibleDates)
    if (visibleDates.startDate && visibleDates.endDate) {
      fetchFreeAvailability();
      fetchAppointments();
      if (isTech) {
        setUsersToSingleTech();
      } else {
        fetchTechs();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusAppointment, showTechLevels, visibleDates]);

  useEffect(() => {
    /* combine freeAvailability and appointments into events */
    if (inject)
      setCalendarEvents([...freeAvailability, ...appointments, ...inject]);
    else setCalendarEvents([...freeAvailability, ...appointments]);
  }, [freeAvailability, appointments, focusAppointment, inject]);

  /* center calendar on focused appointment */
  useEffect(() => {
    if (inject && inject.length > 0) {
      goToDate(inject[0].start);
    } else if (focusAppointment) {
      let foundAppt = calendarEvents.find(
        (appt) => appt.appointment_id == focusAppointment
      );
      if (foundAppt) goToDate(foundAppt.start);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarEvents, inject]);

  const clickingEvent = (e) => {
    if (e.event.title.includes('Available')) {
      const start_date = new Date(e.event.start);
      const end_date = new Date(e.event.start.getTime() + 7200000);
      const tech_id = e.event.groupId;
      const event_end = new Date(e.event.end.getTime());

      if (end_date > event_end) {
        handleReservationChange(event_end, 'end_time');
      } else {
        handleReservationChange(end_date, 'end_time');
      }
      handleReservationChange(start_date, 'solution_date');
      handleReservationChange(start_date, 'start_time');
      handleReservationChange(tech_id, 'technician_id');
    }
  };

  const dateChanged = (dateInfo) => {
    setVisibleDates({
      startDate: dateInfo.view.currentStart,
      endDate: dateInfo.view.currentEnd
    })
  }

  return (
    <FullCalendar
      header={{
        left: 'prev,next today',
        center: 'title',
        right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth',
      }}
      titleFormat={{
        month: 'long',
        year: 'numeric',
        day: 'numeric',
        weekday: 'long',
      }}
      ref={calendarRef}
      events={calendarEvents}
      defaultView={props.defaultView || 'resourceTimeline'}
      plugins={[resourceTimelinePlugin, interactionPlugin]}
      resourceGroupField={props.resourceGroupField || 'tech_level'}
      resourceGroupText={resourceGroupText}
      resources={techs}
      datesRender={dateChanged}
      resourceLabelText={props.resourceLabelText || 'Techs'}
      resourceOrder={props.resourceOrder || 'tech_level'}
      minTime={props.minTime || '06:00:00'}
      scrollTime={'09:00:00'}
      maxTime={props.maxTime || '23:00:00'}
      height={props.height || 600}
      eventClick={(e) => {
        if (isCreatingAppointment) {
          clickingEvent(e);
        } else if (e.event.id !== '') {
          history.push(`/appointments/view/${e.event.id}`);
        } else if (e.event.id == '') {
          const start_date = new Date(e.event.start);
          const end_date = new Date(e.event.start.getTime() + 7200000);
          const tech_id = e.event.groupId;
          const event_end = new Date(e.event.end.getTime());
          history.push({
            pathname: `/appointments/create`,
            state: {
              reservation: {
                start_date,
                end_date: end_date > event_end ? event_end : end_date,
                tech_id,
              },
            },
          });
        }
      }}
    />
  );
};

export default withRouter(CalendarComponent);
